import * as yup from "yup";

export const initialValues_createClub = {
  clubName: "",
  emails: [{ emailId: "" }],
};

export const validationSchema_createClub = yup.object().shape({
  clubName: yup.string().required("Required"),
  emails: yup.array().of(
    yup.object().shape({
      emailId: yup
        .string()
        .email("Invalid email"),
    })
  ),
});
