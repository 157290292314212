import EventBets from "../EventBets/EventBets";
import ParlaysEventBetslip from "../EventsParlays/ParlaysEventBetslip";
import SummaryEventBetslip from "../EventSummary/SummaryEventBetslip";
import { connect } from "react-redux";
import {
  sheduleByYear,
  teamData,
  joinEvent,
  eventParlayTeam,
  summaryData,
  joinParlayEvent,
  userEventByDate,
} from "../../../../../Store/action/Events.action";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import Spinner, { ButtonSpinner } from "../../../../../Common/Spinner/Spinner";
import { addStickyToTHead, getAllEv, getCurrentWin, getUniqueBeters, removeStickyToTHead } from "../../AllEventCalculation/Calculation";
import { problity } from "../EventsParlays/ParlayCalculation/ParlayCalculation";

import Countdown from 'react-countdown';
import { getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import LiveGif from "../../../../../assets/images/live.gif"
import { getApi, postApi } from "../../../../../Store/api-interface/api-interface";


const EventBetSlip = (props?: any) => {
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [betType, setBetType] = useState<string>("1");
  const [teamList, setTeamList] = useState<any>(null);
  const [eventBetTotal , setEventBetTotal] = useState<any>(0)
  const [Loading, setLoading] = useState<boolean>(false);
  const [betData, setBetData] = useState<IBetdata[]>([{
    scheduleId: 0, 
    risk: "", 
    pickedTeam: "",
    status:''
  }]);
  const [Loader, setLoader] = useState<boolean>(false);
  const [IsLocked, setLocked] = useState<boolean>(false);
  const [teamLoader, setTeamLoader] = useState<boolean>(false);
  const [eventParlayTeam, setParlayTeam] = useState<any>(null);
  const [parlayLoader, setParlayLoader] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>(null);
  const [sortingIs , setSorting] = useState<boolean>(false)
  const [activeSortName , setActiveSort] = useState<string>("")
  const [summaryLoader, setSummaryLoader] = useState<boolean>(false);
  const [parlayevents, setParlayEvents] = useState<Iparlayevents[]>([
    {
      scheduleId: 0, 
      pickedTeam: "", 
      riskId: ""
    }
  ]);
  const [risk, setrisk] = useState<number>(0);
  const [parlaybetLoader, setParlaybetLoader] = useState<boolean>(false);
  const [betLoader, setbetLoader] = useState<boolean>(false);
  const [resetLoader, setResetLoader] = useState<boolean>(false)
  const [parlayLock, setParlayLock] = useState<boolean>(false);
  const [totalWinIs , setTotalWin] = useState<any>(0)
  const [selectedBet, setSelectedBet] = useState<any>()
  const [liveDateTIme,setLiveDateTIme] = useState<any>();
  const [liveMainCardTime,setLiveMainCardTime] = useState<any>();
  const [lockAllModal, setLockAllModal] = useState<boolean>(false)
  const [lockLoader, setLockLoader] = useState<boolean>(false)
  const [resetDrafts, setResetDrafts] = useState<boolean>(false)
  const [saveDraftParley, setSaveDraftParley] = useState<boolean>(false)
  const [parlayData,setParlayData] = useState<any>()
  const [isModelPopup,setisModelPopup] = useState<boolean>(false)
  const [isPopup,setIsPopup] = useState<boolean>(false)
  const [lockSpin,setLockSpin] = useState<boolean>(false)
  const [saveDraftSpin,setSaveDraftSpin] = useState<boolean>(false)
  const [uniqueBetters, setUniqueBeters] = useState<any>()
  const [betMessage, setMesage] = useState<boolean>(false)
  const [uniqueParleyMembers, setUniqueParleyMembers] = useState<any>()
  const [parleyMessage, setParleyMessage] = useState<boolean>(false)
 
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "500px",
      width: "100%",
    },
  };


  const closeModal = () => {
    setLockAllModal(false)
    setResetDrafts(false)
    setSaveDraftParley(false)
    addStickyToTHead();
    setIsPopup(false)
  };

  const handelLockAllModal = () => {
    if(isPopup) {
      betData.forEach((e:any)=>e.isModelPopup = isPopup)
      setisModelPopup(true)
    }
    const dataIs = {status:"2", betType, events : betData };
    if(dataIs){
      setbetLoader(true)
      setLockLoader(true)
      props.$joinEvent(dataIs);
     }
  }

  const handleRestDrafts = (betType:any) => {
    
    if(betType == 1) {
      setResetLoader(true)
    const dataIs = { status : "0", betType, events : betData };
      if(dataIs){
          postApi("/event/resetBetDraft", dataIs).then((res) => {
          if(res.status === 200){
            getTeam()
          }
        })
     }
    }
    else if (betType == 2) {
      setResetLoader(true)
      resetgetParlays("0", betType);
    }
  }

  const handleSaveDraftParlay = () => {
    if(isPopup) {
      parlayData.isModelPopup = isPopup
      setisModelPopup(true)
    }
    setLockLoader(true)
    setParlaybetLoader(true); 
    props.$joinParlayEvent(parlayData);  
  }
 
  const getRiskData = () => {
    if (teamList?.length) {
      const a = teamList.map((w: ITeamList) => {  
        const meData = w.events?.find((l: any) => l.isMe === true);
        const moneyLine = meData?.pickedTeam === 1 ? w?.moneyline_home : w?.moneyline_away;
        
        return {
          scheduleId: w.id,
          risk: meData ? meData.risk : "",
          pickedTeam: meData ? meData.pickedTeam : "",
          win : getCurrentWin(meData?.risk , moneyLine),
          moneyLine: (meData?.pickedTeam && !isNaN(meData?.pickedTeam)) ? (meData?.pickedTeam===2 ? w?.moneyline_away : w?.moneyline_home) : "",
          riskId : meData?.riskId,
          status : meData?.status,
          winAmount:meData?.win ? meData?.win : 0,
          away_team:w?.away_team,
          home_team:w?.home_team
        };
      });
      setBetData(a);
      setLockAllModal(false)
      setLockLoader(false)
      setResetDrafts(false)
      setResetLoader(false)
      setLockSpin(false)
    }
  };

  const getEventStatus = () =>{
    const isDafted = betData && betData.some((ele:any) => ele?.status == 1)
    if(isDafted){
      return false
    }
    return true
  }

  const getParlayEventStatus = () =>{
  let flag = true
    eventParlayTeam && eventParlayTeam.forEach((ele:any) => {
      if(ele.events?.length){
        ele.events?.forEach((el:any) => {
          if(el.status == 1 && el.isMe == true)
          flag = false
        })
      }
    })
    
    return flag 
  }

  const checkBetLocked = () => {
    if (teamList?.length) {
      const isLocked = teamList.some((s: ITeamList) => s.isLock === false) ? false : true
      setLocked(isLocked);
    }
  };

  const checkParlayLocked = () => {
    if (eventParlayTeam?.length) {
      const isLocked = eventParlayTeam.some((s: any) => s.isLock === true);
      setParlayLock(isLocked);
    }
  };

  useEffect(() => {
    let time = props?._userEventByDate?.teamDataIs?.data?.slice(-1)[0]?.eventCardTime
    setLiveDateTIme(time);
    time = props?._userEventByDate?.teamDataIs?.data?.[0]?.eventCardTime || props?._userEventByDate?.teamDataIs?.data?.[1]?.eventCardTime
    setLiveMainCardTime(time)
  },[props?._userEventByDate?.teamDataIs?.data])

  useEffect(() => {
    getRiskData();
    checkBetLocked();
  }, [teamList]);
  
  const getParlayRiskData = () => {
    if (eventParlayTeam?.length) {
      const a = eventParlayTeam.map((w: any) => {
        const meData = w.events.find((l: any) => l.isMe === true);
        return {
          scheduleId: w.id,
          away_team: w?.away_team,
          home_team: w?.home_team,
          pickedTeam: meData ? meData.pickedTeam : "",
          riskId: meData ? meData.riskId : "",
          problity : meData?.pickedTeam ? problity(w, meData.pickedTeam) : ""
        };
      });
      setParlayEvents(a);
      setLockLoader(false)
      setSaveDraftParley(false)
      setResetDrafts(false)
      setResetLoader(false)
      setSaveDraftSpin(false)
      setLockSpin(false)
    }
  };


  useEffect(() => {
    checkParlayLocked();
    getParlayRiskData();
  }, [eventParlayTeam]);

  const handleEventType = (type: string) => {
    setBetType(type);
  };

  useEffect(() => {
    getTeam();
  }, [betType, selectedOption]);

  useEffect(() => {
    setLoading(true);
    setTeamLoader(true);
    setParlayLoader(true);
    props.$sheduleByYear();
  }, []);

  useEffect(() => {
    const eventData = props._sheduleByYear.sheduleIs;
    if (eventData?.status === 1 && Loading) {
      setLoading(false);
      const ObjIs = eventData?.data && eventData?.data[0];
      const defaultValueIs = {
        label: ObjIs?.date_event,
        ...ObjIs,
      };
      setSelectedOption(defaultValueIs);
    }
  }, [props._sheduleByYear]);

  const getSummary = (payload: any) => {
    props.$summaryData(payload);
    setSummaryLoader(true);
  };

  const getTeam = () => {
    if(selectedOption?.label){
      const payload = { date: selectedOption?.label, event_name : selectedOption?.event_name, club: props?.clubId, betType };
      if (payload && betType === "1") {
        
        setTimeout(()=>{
        props.$teamData(payload);        
      },1000)      
    } else if (payload && betType === "2") {
      props.$eventParlayTeam(payload);
      getSummary(payload)
     
    } else if (payload && betType === "3") {
      getSummary(payload);
    }
  }
  };
  useEffect(()=>{
    getApi("/user/notification" ).then((res:any) => {
      if(res.status === 200){
        setisModelPopup(res.data?.data?.isModelPopup)
      }
    })
  },[])

  useEffect(() => {
    const data = props._teamData.teamDataIs;
    if (data?.status === "1") {
      setTeamList(data?.data);
      setEventBetTotal(data?.clubUserBetTotal)
      setTeamLoader(false);
    }
  }, [props._teamData]);

  useEffect(() => {
    const parlayData = props._eventParlayTeam.parlayListIs;

    if (parlayData?.status === "1") {
      setParlayTeam(parlayData?.data);
      setParlayLoader(false);
    }
  }, [props._eventParlayTeam]);

  useEffect(() => {
    const summaryIs = props._summaryData.summaryIs;
    if (summaryIs?.status === "1" ) {
      setParlayLoader(false)
      setSummaryLoader(false);
      setSummaryData(summaryIs?.data);
    }
  }, [props._summaryData]);

  //   Save and Lock *************************

  const getbets = (status: string, handleSaveDraft:boolean = true) => {
    const dataIs = { status, betType, events : betData };
    const {events} = dataIs
    const filterData = events.filter((ele:any)=> Number(ele.risk) === 0 && ele.risk != '') 
    if(filterData.length > 0){
      alert("To lock a Bet, Risk must be greater than $0.")
      return
    }
   
    if(dataIs){
    if (dataIs?.events?.filter((b:any)=>(b.risk !== "" && b.risk !== null)).length > 0) {
      const filteredData = dataIs.events.filter((e:any) => e.status == 1)
      if(filteredData.length > 0){
        if(handleSaveDraft){
          
          if(!isModelPopup) {
            setLockAllModal(true)
            return
          } else {
            setbetLoader(true)
            setLockSpin(true)
            props.$joinEvent(dataIs);
          }
        }      
      }else{
        alert("Risk is required to lock bets.");
      }

      
    } else {
      alert("Risk is required to lock bets.");
    }
   }
  };

  useEffect(() => {
    if(selectedBet){
      const dataIs = { status:selectedBet && selectedBet[0]?.status ? selectedBet[0]?.status : "2", betType, events : selectedBet };  
      if(dataIs?.status === "2"){
        setLoader(true)
      }
      props.$joinEvent(dataIs);
    }
  }, [selectedBet])

  const getParlays = (status: string) => {
    const mainCardFightTime = eventParlayTeam?.find((ele:any) => ele?.event_card)?.eventCardTime
    const lastCardDuration = getDiffrenceOfTimeInMinutes(mainCardFightTime)
    let eventsData: any = [];
    parlayevents?.forEach((el: Iparlayevents) => {
      const riskId = el?.riskId ? el.riskId : "";
      const a =
        !el.riskId && el.pickedTeam
          ? { ...el, action: 1, riskId }
          : el?.riskId && el?.pickedTeam
          ? { ...el, action: 2, riskId }
          : el?.riskId && !el?.pickedTeam
          ? { ...el, action: 3, riskId }
          : { ...el, action: "", riskId };
      eventsData.push(a);
    });
    const events = eventsData?.filter((el: any) => el.action);
    const noParlayBetIs = events.some((obj : any) => (obj.action !== 3))
    if(!noParlayBetIs){
      setrisk(0)
    }
    const dataIs = { status, betType, risk: noParlayBetIs ? risk : 0, events, totalWin : totalWinIs };
    if(events.length > 0){
      // setIsActive(false)
    }
    if (lastCardDuration > 0) {
      const riskIs = Number(dataIs.risk)
      if(riskIs === 0){
        alert("To lock a Bet, Risk must be greater than $0.")
        return
      }
      if(dataIs){
        const {events} = dataIs
        const selectedData = eventParlayTeam.filter((ele:any) => events.find((el:any) => el.scheduleId === ele.id))
        if(Boolean(selectedData?.length)){
          for(let i = 0; i < selectedData.length; i++){
            if(getDiffrenceOfTimeInMinutes(selectedData[i].eventCardTime) <= 0 ){
              alert("Some of the fighters you selected are no longer available. Please update your Parlay before locking in")
              getTeam() 
              return
            }
          }
        }
        if (risk || (!noParlayBetIs)) {
          if (dataIs?.events?.filter((e:any)=>e.action !== 3).length > 1) {
            if(!isModelPopup && status == '2') {
              setSaveDraftParley(true)
              setParlayData(dataIs)  
            } else {
              (status == "1" )? setSaveDraftSpin(true):setLockSpin(true)
              setParlaybetLoader(true);
              props.$joinParlayEvent(dataIs);
            }
                
            // setParlaybetLoader(true);        
            // props.$joinParlayEvent(dataIs);
          } else {
            alert("You must select at least 2 fighters for a Parlay");
          }
        } else {
          alert("You must enter your Risk amount to lock in Bets");
        }
      }
    }else{
      alert("Sorry, but the window to add a Parlay has passed")
    }
  };

  const getUniqueBetters = (uniqueBeters:any) => {
    setUniqueBeters(uniqueBeters)
  }

  const getUniqueParleyBetters = (getUniqueParleyBetters:any) => {
    setUniqueParleyMembers(getUniqueParleyBetters)
  } 

  useEffect(() => {
    const betMessage = uniqueBetters && uniqueBetters.some((ele:any) => ele?.showPrediction ==true)
    setMesage(betMessage)
    const parleyMessage = uniqueParleyMembers && uniqueParleyMembers.some((ele:any) => ele?.showPrediction ==true)
    setParleyMessage(parleyMessage)
  })

  const resetgetParlays = (status: string, betType:string) => {
    const mainCardFightTime = eventParlayTeam.find((ele:any) => ele?.event_card)?.eventCardTime
    const lastCardDuration = getDiffrenceOfTimeInMinutes(mainCardFightTime)
    let eventsData: any = [];
    parlayevents?.forEach((el: Iparlayevents) => {
      const riskId = el?.riskId ? el.riskId : "";
      const a =
        !el.riskId && el.pickedTeam
          ? { ...el, action: 1, riskId }
          : el?.riskId && el?.pickedTeam
          ? { ...el, action: 2, riskId }
          : el?.riskId && !el?.pickedTeam
          ? { ...el, action: 3, riskId }
          : { ...el, action: "", riskId };
      eventsData.push(a);
    });
    const events = eventsData?.filter((el: any) => el.action);
    const noParlayBetIs = events.some((obj : any) => (obj.action !== 3))
    if(!noParlayBetIs){
      setrisk(0)
    }
    const dataIs = { status, betType, risk: noParlayBetIs ? risk : 0, events, totalWin : totalWinIs };
    if (lastCardDuration > 0) {
      if(dataIs){
        if (risk || (!noParlayBetIs)) {
          if (dataIs?.events?.filter((e:any)=>e.action !== 3).length > 1) {
            postApi("/event/resetParlayDraft", dataIs).then((res:any) => {
              if(res.status == 200){
                getTeam();
                // setIsActive(true)
                setrisk(0)
              }
            })
          } 
        }
      }
    }
  };

  // const getbetsReset = (status: string, handleSaveDraft:boolean = true) => {
  //   const dataIs = { status, betType, events : betData };
  //   if(dataIs){
  //       postApi("/event/resetBetDraft", dataIs).then((res) => {
  //       if(res.status === 200){
  //         getTeam()
  //       }
  //     })
  //  }
  // }
  const handleSaveDraft = (status: string, toshowLoader:boolean) => {
     if (betType === "1") {
      getbets(status, toshowLoader);
    } else if (betType === "2") {
      getParlays(status);
    }
    // setStatus(status);
  };

  const resethandleSaveDraft = (status: string, toshowLoader:boolean) => {
    // if (betType === "1") {
    //   setResetDrafts(true)
    //   // getbetsReset(status, toshowLoader);
    // } else if (betType === "2") {
    //   setResetDrafts(true)
      
    // }
    // setStatus(status);
    handleRestDrafts(betType)
  };

  useEffect(() => {
    const joinedData = props._joinEvent.joinEventData;
    if (joinedData && Loader) {
      setLoader(false);
      getTeam();
    }
  }, [props._joinEvent]);

  useEffect(() => {
    const joinedData = props._joinEvent.joinEventData;
    if (joinedData && betLoader) {
      getTeam();
      setbetLoader(false)
    }
  }, [props._joinEvent.joinEventData]);


  useEffect(() => {
    const joinedParlayData = props._joinParlayEvent.joinParlayIs;
    if (joinedParlayData?.status === 1 && parlaybetLoader) {
      setParlaybetLoader(false);
      getTeam();
    }
  }, [props._joinParlayEvent]);
  
  const renderer = ({days, hours, minutes, seconds, completed }:any) => {
    if(days===0 && hours===0 && (minutes===14 || minutes===15) && seconds===0){
      getTeam()
    }
    if (completed) {
      return <></>
    } else {
      // Render a countdown
      return <span className="text-danger">{days + " Days " + hours + " Hrs " + minutes + " Min "}</span>
    }
  };
  return (
    <>
    <section className="mb-15">
      <div className="row">
        <div className="col-sm-12">
          <div className="accordion shadow-xs" id="kt_accordion_2">
            <div className="accordion-item">
              <h2 className="accordion-header">
                <button className="accordion-button accordion-button-cu bg-white flex-wrap text-dark fw-bolder fs-2">
                  Event Betslip
                  {
                  getDiffrenceOfTimeInMinutes(liveDateTIme) <= 0
                    ?
                    <span className="mx-2 bold text-danger"><img style={{width: "30px"}} src={LiveGif} />LIVE</span>
                    :
                    ""
                  }
                  <span className="right_side right_side_unset text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                    <span style={{marginRight:10}}>{selectedOption?.event_name}</span>{" "}
                  </span>
                  <span
                    className="accordion-button"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_1_body_2"
                    aria-expanded="true"
                    aria-controls="kt_accordion_1_body_2"
                  ></span>
                  <div className="col-12 mt-3 mb-0">
                  <small>
                     {
                      selectedOption?.date_event && ((!IsLocked && betType === "1") || (!parlayLock && betType === "2")) 
                      ?
                      <div className="row">
                        <div className="col right_side text-muted"> 
                          {getDiffrenceOfTimeInMinutes(selectedOption?.date_event) > 0 ? 
                       liveDateTIme && <p className="event-countdown text-danger">Event starts in: <Countdown date={liveDateTIme} renderer={renderer} /></p>
                        :
                        ""}
                        </div>                        
                      </div>
                      :
                      ""
                    }
                  </small>
                  </div>
                </button>
              </h2>            
              <div
                id="kt_accordion_1_body_2"
                className="accordion-collapse collapse show"
                aria-labelledby="kt_accordion_1_header_2"
                data-bs-parent="#kt_accordion_2"
              >
                <div className="accordion-body pt-3">
                  <div className="card-xl-stretch">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-3 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_1"
                              onClick={() => handleEventType("1")}
                            >
                              Bets
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_2"
                              onClick={() => handleEventType("2")}
                            >
                              Parlays
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                              data-bs-toggle="tab"
                              href="#kt_table_widget_1_tab_3"
                              onClick={() => handleEventType("3")}
                            >
                              Summary
                            </a>
                          </li>
                        </ul>
                        {
                          betMessage && betType == "1"?
                          <div className="text-danger" >Some Users have chosen to hide bets until event section starts.</div>
                          :
                          ''
                        }
                        {
                          parleyMessage && betType == "2"?
                          <div className="text-danger" >Some Users have chosen to hide parley until event section starts.</div>
                          :
                          ''
                        }
                      </div>

                      {/* save and draft button */}
                      {betType !== "3" && getDiffrenceOfTimeInMinutes(liveMainCardTime) > 0 && (
                        <div className="card-title flex-column text-end text-sm-start mob-w-100 mb-4 mb-sm-0">
                          {((!parlayLock && betType === '2')
                          && (
                            <button
                            type="button"
                            className="btn btn-sm btn-light fw-bold"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Resetting Draft will erase out all the saved draft picks."
                            onClick={() => resethandleSaveDraft("0", true)}
                            disabled = {getParlayEventStatus()}
                            >
                            {(resetLoader) ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              "Clear Draft"
                            )}
                            </button>
                          )
                          )}

                          {((!IsLocked && betType === "1")
                          && (
                            <button
                            type="button"
                            className="btn btn-sm btn-light fw-bold"
                            onClick={() => resethandleSaveDraft("0", true)}
                            disabled = {getEventStatus()}
                            >
                              
                            {( resetLoader) ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                             " Clear Drafts"
                            )}
                            </button>
                          )
                          )}

                          {(
                            // (IsLocked && betType === "1") ||
                            (!parlayLock && betType === "2")
                          ) && (
                            <button
                              type="button"
                              className="btn btn-sm btn-light fw-bold ms-4"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              title="Saving as a Draft will store your initial thoughts, but your picks will not be officially recorded and other users will not see them until you Lock In."

                              onClick={() => handleSaveDraft("1", true)}
                            >
                              {(saveDraftSpin)? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                "Save Draft"
                               )}
                            </button>
                          )
                          
                         }
                          <button
                            type="button"
                            className={`btn btn-sm btn-orange fw-bold ms-4 ${
                              (IsLocked && betType === "1") ||
                              (parlayLock && betType === "2")
                                ? "not-allowed"
                                : ""
                            }`}
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title="Once you select Lock In, other users can see your picks and you will not be able to change or add to them"
                            onClick={
                              !(
                                (IsLocked && betType === "1") ||
                                (parlayLock && betType === "2")
                              )
                                ? () => handleSaveDraft("2", true)
                                : () => {}
                            }
                          >
                            {!(
                              (IsLocked && betType === "1") ||
                              (parlayLock && betType === "2")
                            ) ? (
                              (lockSpin) ? (
                                <ButtonSpinner color="text-light  spinner-border-sm " />
                              ) : (
                                betType === "1" ? "Lock All" : "Lock In "
                              )
                            ) : (
                              "Locked"
                            )}
                          </button>
                        </div>
                      )}
                      {/* save and draft button */}
                    </div>
                    <div className="card-body py-3 px-2">
                      <div className="tab-content">
                        <div
                          className="tab-pane fade show active"
                          id="kt_table_widget_1_tab_1"
                        >
                          {teamLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <EventBets
                              teamList={teamList}
                              betType={betType}
                              betData={betData}
                              setBetData={setBetData}
                              IsLocked={IsLocked}
                              eventBetTotal={eventBetTotal}                              
                              handleSaveDraft={handleSaveDraft}    
                              setSelectedBet={setSelectedBet}
                              isModelPopup = {isModelPopup} 
                              setisModelPopup = {setisModelPopup}
                              isPopup = {isPopup}
                              setIsPopup = {setIsPopup}
                              getUniqueBetters  = {getUniqueBetters}

                              // getActiveStatus = {setIsDraftedBet}                        
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_2"
                        >
                          {parlayLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <ParlaysEventBetslip
                              eventParlayTeam={eventParlayTeam}
                              betType={betType}
                              risk={risk}
                              setrisk={setrisk}
                              parlayevents={parlayevents}
                              setParlayEvents={setParlayEvents}
                              parlayLock={parlayLock}
                              setTotalWin={setTotalWin}
                              summaryData={summaryData}
                              setSummaryData ={setSummaryData}
                              getUniqueParleyBetters ={getUniqueParleyBetters}
                            />
                          )}
                        </div>
                        <div
                          className="tab-pane fade"
                          id="kt_table_widget_1_tab_3"
                        >
                          {summaryLoader ? (
                            <Spinner color="text-dark  spinner-border-sm text-center" />
                          ) : (
                            <SummaryEventBetslip summaryData={summaryData}  setSummaryData ={setSummaryData} sortingIs ={sortingIs} 
                            setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort}/>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Modal isOpen={lockAllModal} onRequestClose={closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <p className="text-center p-5" style={{fontSize:'15px'}}>Are you sure you want to lock these Bets? You won't be able to make any changes.</p>
          <label className="form-controlcheck d-flex align-items-center ">
            <input type="checkbox"  onChange={() => {setIsPopup(!isPopup)}} />
            <span className="ms-2" >Don't show popup again</span>
          </label>
          <div className="text-center d-flex">
            <button type="button" id="kt_sign_in_submit" className="btn btn-lg btn-orange m-2 w-100 mb-5" onClick={handelLockAllModal}>
              <span className="indicator-label fw-bolder">{lockLoader ? <ButtonSpinner color="text-black spinner-border-sm " /> : "Yes"}</span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={() => {
                addStickyToTHead();
                setLockAllModal(false);
                setIsPopup(false)
              }}
            >
              <span className="indicator-label fw-bolder">No</span>
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={resetDrafts} onRequestClose={closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <h3 className="text-center text-danger p-5">Clear all saved drafts?</h3>
          <div className="text-center d-flex">
            <button type="button" id="kt_sign_in_submit" className="btn btn-lg btn-orange m-2 w-100 mb-5" onClick={()=>{handleRestDrafts(betType)}}>
              <span className="indicator-label fw-bolder">{lockLoader ? <ButtonSpinner color="text-black spinner-border-sm " /> : "Yes"}</span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={() => {
                addStickyToTHead();
                setResetDrafts(false);
              }}
            >
              <span className="indicator-label fw-bolder">No</span>
            </button>
          </div>
        </div>
      </Modal>

      <Modal isOpen={saveDraftParley} onRequestClose={closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <p className="text-center p-5" style={{fontSize:'15px'}} >{parlayData?.status == "1" ? "Saving as a Draft will store your initial thoughts, but your picks will not be officially recorded and other users will not see them until you Lock In.":"Are you sure you want to lock this Parlay? You won't be able to make any changes."}</p>
          <label className="form-controlcheck d-flex align-items-center ">
            <input type="checkbox"  onChange={() => {setIsPopup(!isPopup)}} />
            <span className="ms-2" >Don't show popup again</span>
          </label>
          <div className="text-center d-flex">
            <button type="button" id="kt_sign_in_submit" className="btn btn-lg btn-orange m-2 w-100 mb-5" onClick={handleSaveDraftParlay}>
              <span className="indicator-label fw-bolder">{lockLoader ? <ButtonSpinner color="text-black spinner-border-sm " /> : "Yes"}</span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
              onClick={() => {
                addStickyToTHead();
                setSaveDraftParley(false)
                setIsPopup(false)
              }}
            >
              <span className="indicator-label fw-bolder">No</span>
            </button>
          </div>
        </div>
      </Modal>
      
    </>
  );
};
const mapState = ({
  sheduleByYear,
  teamData,
  joinEvent,
  eventParlayTeam,
  summaryData,
  joinParlayEvent,
  userEventByDate,

}: any) => {
  return {
    _sheduleByYear: sheduleByYear,
    _teamData: teamData,
    _joinEvent: joinEvent,
    _eventParlayTeam: eventParlayTeam,
    _summaryData: summaryData,
    _joinParlayEvent: joinParlayEvent,
    _userEventByDate: userEventByDate,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $sheduleByYear: () => dispatchEvent(sheduleByYear()),
  $teamData: (values?: IclubForAllbetPayload) => dispatchEvent(teamData(values)),
  $joinEvent: (values?: IDraftForbets) => dispatchEvent(joinEvent(values)),
  $eventParlayTeam: (values?: IclubForAllbetPayload) => dispatchEvent(eventParlayTeam(values)),
  $summaryData: (values?: IclubForAllbetPayload) => dispatchEvent(summaryData(values)),
  $joinParlayEvent: (values?: IDraftForParlay) => dispatchEvent(joinParlayEvent(values)),
  $userEventByDate: (values?: IAllBetForUser) => dispatchEvent(userEventByDate(values)),
});
export default connect(mapState, mapDispatch)(EventBetSlip);
