import {
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  COUNTRY_DETAILS_ERROR,
  COUNTRY_DETAILS_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  LOGOUT_USER,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_ERROR,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_ERROR,
  GET_CLUB_DETAILS_SUCCESS,
  GET_CLUB_DETAILS_ERROR,
  INVITE_FRND_SUCCESS,
  INVITE_FRND_ERROR,
  JOIN_CLUB_SUCCESS,
  JOIN_CLUB_ERROR,
  PROFILE_PIC_SUCCESS,
  PROFILE_PIC_ERROR,
  JOINING_CLUB_DETAILS_SUCCESS,
  CLUB_PIC_SUCCESS,
  CLUB_PIC_ERROR,
  USER_CLICKED_EVENT_DETAIL,
  GET_CLUB_INVITATION_SUCCESS
} from "../type.action";

const initState = {
  userDataIs: "",
  userDataErrIs: "",
  countryErrIs: "",
  countryIs: "",
  updateUserIs: "",
  isAuthenticated: false,
  createClubIs: '',
  createErrIs : '',
  changePwdIs : '',
  changePwdErrIs : "",
  getClubDetailsIs : "",
  getClubDetailsErr : "",
  inviteFrndIs : "",
  inviteFrndErrIs : "",
  joinClubIs: '',
  joinClubErrIs : '',
  profilePicIs : '',
  profilePicErrIs : '',
  joiningClubDetailsIs : "",
  clubPicIs: '',
  userClickedEvent: ""
};

const reducers = (state = initState, { type, values, error }: any) => {
  switch (type) {
    case USER_PROFILE_SUCCESS:
      return {
        ...state,
        userDataIs: values,
        userDataErrIs: "",
        isAuthenticated : true
      };
    case USER_PROFILE_ERROR:
      return {
        ...state,
        userDataErrIs: error,
        userDataIs: "",
        isAuthenticated: false
      };
    case COUNTRY_DETAILS_SUCCESS:
      return {
        ...state,
        countryIs: values
      };
    case COUNTRY_DETAILS_ERROR:
      return {
        ...state,
        countryErrIs: error
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        updateUserIs: values,
        updateUserErr: ''
      };
    case UPDATE_USER_ERROR:
      return {
        ...state,
        updateUserErr: error,
        updateUserIs: ''
      };
    case LOGOUT_USER:
      localStorage.removeItem("token")
      return {
        userDataIs: "",
        userDataErrIs: "",
        countryErrIs: "",
        countryIs: "",
        updateUserIs: "",
        isAuthenticated: false
      }
    case CREATE_CLUB_SUCCESS:
      return{
        ...state,
        createClubIs : values,
        createErrIs : ""
      }  
    case CREATE_CLUB_ERROR:
      return{
        ...state,
        createErrIs : error,
        createClubIs : ""
      } 
    case CHANGE_PWD_SUCCESS:
      return{
        ...state,
        changePwdIs : values,
        changePwdErrIs: ''
      }  
    case CHANGE_PWD_ERROR:
      return{
        ...state,
        changePwdErrIs : error,
        changePwdIs : ''
      } 
    case GET_CLUB_DETAILS_SUCCESS:
      return{
        ...state,
        getClubDetailsIs  :values
      }
    case GET_CLUB_INVITATION_SUCCESS:
      return{
        ...state,
        getClubInvitationIs  :values
      }
    case GET_CLUB_DETAILS_ERROR:
      return{
        ...state,
        getClubDetailsErr : error
      }  
    case INVITE_FRND_SUCCESS:
      return{
        ...state,
        inviteFrndIs : values
      } 
    case INVITE_FRND_ERROR:
      return{
        ...state,
        inviteFrndErrIs : error
      } 
    case JOIN_CLUB_SUCCESS:
      return{
        ...state,
        joinClubIs : values
      } 
    case JOIN_CLUB_ERROR:
      return{
        ...state,
        joinClubErrIs : error
      } 
    case PROFILE_PIC_SUCCESS:
      return{
        ...state,
        profilePicIs : values
      }  
    case PROFILE_PIC_ERROR:
      return{
        ...state,
        profilePicErrIs : error
      }  
    case JOINING_CLUB_DETAILS_SUCCESS:
      return{
        ...state,
        joiningClubDetailsIs : values
      } 
    case CLUB_PIC_SUCCESS:
      return{
        ...state,
        clubPicIs : values
      } 
    case CLUB_PIC_ERROR:
      return{
        ...state,
        clubErrIs : error
      } 
    case USER_CLICKED_EVENT_DETAIL:
      return { ...state, userClickedEvent: values }            
    default:
      return state;
  }
};

export default reducers;
