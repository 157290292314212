import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../../Common/Header";
const Home = () => {


  const flodeskForm = () => {
    return {
      __html:`
      <div id="fd-form-643fe1f8a5edb7e9cf295e3c"></div>
      <script>
        ${window?.fd('form', {
          formId: '643fe1f8a5edb7e9cf295e3c',
          containerEl: '#fd-form-643fe1f8a5edb7e9cf295e3c'
        })}
      </script>`
    }
  }
  useEffect(()=>{
    flodeskForm()
  },[])
  return (
    <>
    <section className="hero_banner ">
      <Header />
      <div className="min-vh-100 d-flex flex-column align-items-center justify-content-center position-relative">
        
        <div className="container position-relative mb-5">
          <div className="row flex-column">
            <div className="col-sm-8 offset-sm-2 col-xl-8 offset-xl-2 text-center">
              <h5 className="text-white fw-bolder headerMargin fs-3tx">
                Welcome to MMA BetClub
              </h5>
              <h3 className="text-white fw-bold fs-1 py-3">
              <i style={{fontSize:"2rem",color:"#fff"}}>Challenge your friends in fantasy MMA, use the data to beat your bookie</i>
              </h3>
              <p className="text-white font-system fs-5 px-10">
              <i style={{fontSize:"2rem",color:"#fff"}}>Who's the REAL expert?</i>
              </p>
              <Link
                to="/registration"
                className="btn btn-orange px-10 mt-5 fw-bolder fs-3"
              >
                Sign Up
              </Link>
              
              <Link
                to="/login"
                className="home-button btn btn-orange px-10 mt-5 fw-bolder fs-3 d-md-none"
              >
                Sign In
              </Link>
            </div>
              {/* flodesk form */}
            <div
            dangerouslySetInnerHTML={flodeskForm()}
            />
          </div>
        </div>
        {/* <div className="cu-image-layout position-relative">
          <div className="row justify-content-center">
            <div className="col-10">
              <div className="row justify-content-center">
                <div className="col-xs-5 col-sm-6 col-lg-4">
                  <img
                    className="img-fluid"
                    src="/assets/images/HTP-10.jpg"
                    alt=""
                  />
                </div>
                <div className="col-xs-5 col-sm-6 col-lg-4">
                  <img
                    className="img-fluid"
                    src="/assets/images/HTP-11.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </section>
    </>
  );
};
export default Home;
