import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, FacebookAuthProvider} from "firebase/auth";
import { BaseUrl } from "./Utils/BaseUrl";
import {  useNavigate } from "react-router-dom";



export const firebaseConfig = {
  apiKey: "AIzaSyDLvCRkCe2A_zUuz0Ey6uZzR8HX6lhnekc",
  authDomain: "mma-2-4eb8d.firebaseapp.com",
  databaseURL: "https://mma-2-4eb8d-default-rtdb.firebaseio.com",
  projectId: "mma-2-4eb8d",
  storageBucket: "mma-2-4eb8d.appspot.com",
  messagingSenderId: "983889931524",
  appId: "1:983889931524:web:fa9841137432d486c1f451",
  measurementId: "G-D6L1Q7PN2N"
};

  const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

// ------------google login ------------------

const provider = new GoogleAuthProvider();
export const signInWithGoogle = () => {
 return signInWithPopup(auth, provider)
    .then((result) => {
     return axios
      .post(`${BaseUrl}/firebase/verify-firebase-token`, {idToken:result.user.accessToken,email:result.user.email,firstName:result._tokenResponse
.firstName, lastName:result._tokenResponse.lastName }, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((data)=>{
        if(data?.data){
          let token = data?.data
            return token; 
        }
      })
    })
    .catch((error) => {
   
    });
};

// ------------facebook login------------------
const facebookProvider = new FacebookAuthProvider();

facebookProvider.addScope('user_birthday','email')
export const signInWithFacebook = async () => {
 try {
  console.log("execution in facebook login")
  let result = await signInWithPopup(auth, facebookProvider)       
      return axios
       .post(`${BaseUrl}/firebase/verify-firebase-token`, {idToken:result.user.accessToken,email:result._tokenResponse.email,firstName:result._tokenResponse
 .firstName, lastName:result._tokenResponse.lastName }, {
         headers: {
           "Content-Type": "application/json",
           Authorization: `Bearer ${localStorage.getItem("token")}`,
         },
       }).then((data)=>{
        console.log(data,"data after verify token");
         if(data?.data){
           let token = data?.data
             return token; 
         }
       })
 } catch (error) {
          console.log(error)
    }
 };

