import Select from 'react-select';
import { connect } from "react-redux";
import {  previousSchedule, previousTeamData, clubPreviousParlay, getClubPreSummary } from "../../../../../Store/action/Events.action";
import { useEffect, useState } from 'react';
import PreviousBets from '../PreviousBets/PreviousBets';
import Spinner from "../../../../../Common/Spinner/Spinner";
import PreviousParlay from "../PreviousParlay/PreviousParlay";
import PreviousSummary from "../PreviousSummary/PreviousSummary";
import { customStyles } from "../../AllEventCalculation/Calculation";

const PreviewEvents = (props?: any) => {
    const [optionsIs, setOptions] = useState<any>(null)
    const [selectedOption, setSelectedOption] = useState<any>(null)
    const [betType , setBetType] = useState<any>("1")
    const [previousteamList, setpreviousTeamList] = useState<any>(null)
    const [previousbetTotal , setPreviousBetTotal] = useState<any>(null)
    const [Loading, setLoading] = useState<boolean>(false)
    const [preteamLoader , setPreTeamLoader] = useState<boolean>(false)
    const [parlayList, setParlayList] = useState<any>(null)
    const [parlayLoader, setParlayLoader] = useState<boolean>(false)
    const [parlayevents, setParlayEvents] = useState<any>([]);
    const [summaryLoader, setSummaryLoader] = useState<boolean>(false)
    const [summaryData , setSummaryData] = useState<any>([])
    const [sortingIs , setSorting] = useState<boolean>(false)
    const [activeSortName , setActiveSort] = useState<string>("")

    const handleEventType = (type: string) =>{
        setBetType(type)             
    }

    useEffect(() => {
        getData()

        // ----- getting summary data ---
        const payload = { date : selectedOption?.value, event_name : selectedOption?.event_name, club: props?.clubId, betType:3 }
        getSummaryData(payload)
      }, [betType, selectedOption])

      const getParlayRiskData = () => {
        if (parlayList?.length) {
          const a = parlayList.map((w: any) => {
            const meData = w?.events?.find((l: any) => l?.isMe === true);
            return {
              scheduleId: w.id,
              pickedTeam: meData ? meData.pickedTeam : "",
              riskId: meData ? meData.riskId : "",
            };
          });
          setParlayEvents(a);
        }
      };
    
      useEffect(() => {
        getParlayRiskData();
      }, [parlayList]);

      useEffect(() => {
        setLoading(true)
        props.$previousSchedule()
      }, [])


      const handleChange = (data : any) =>{
        setSelectedOption(data)
      }

      useEffect(() => {
        const eventData = props._previousSchedule.previousSchedule;
        if(eventData?.status === 1 && Loading){
            setOptions(eventData.data)
            setLoading(false)
            const ObjIs = eventData?.data && eventData?.data[0]
            const defaultValueIs = { value: ObjIs?.date_event,
                                     label: ObjIs?.event_name, ...ObjIs }
            setSelectedOption(defaultValueIs)
        }
      }, [props._previousSchedule])

      const getBetData = (data :any) =>{
        props.$previousTeamData(data)
        setPreTeamLoader(true)
      }

      const getParlayData = (data : any) => {
        props.$clubPreviousParlay(data)
        setParlayLoader(true)
      }

      const getSummaryData = (data : any) =>{
        props.$getClubPreSummary(data)
        setSummaryLoader(true)
      }

      const getData = () =>{
        if(selectedOption?.value){
        const payload = { date : selectedOption?.value, event_name : selectedOption?.event_name, club: props?.clubId, betType }
            if(payload && betType === "1"){            
                getBetData(payload)
            }else if(payload && betType === "2"){
                getParlayData(payload)
            }else if(payload && betType === "3"){
              getSummaryData(payload)
            }     
         }  
       }

      useEffect(() =>{
        const data = props._previousTeamData.previousTeamData;
        if(data?.status === "1"){
            setpreviousTeamList(data?.data)
            setPreviousBetTotal(data?.clubUserBetTotal)
            setPreTeamLoader(false)
        }
      }, [props._previousTeamData])

      useEffect(() =>{
        const data = props._clubPreviousParlay.clubPreviousParlayIs;
        if(data?.status === "1"){
            setParlayList(data?.data)
            setParlayLoader(false)
        }
      }, [props._clubPreviousParlay])

      useEffect(() => {
        const summaryIs = props._getClubPreSummary.clubPreSummary;
        if (summaryIs?.status === "1" ) {
          setSummaryLoader(false);
          setSummaryData(summaryIs?.data);
        }
      }, [props._getClubPreSummary]);


      const options = optionsIs?.map((obj: any) => {
        let data = { value: obj?.date_event,
                     label: obj?.event_name,
                     ...obj 
                   };
        return data;
      });

    return (
        <section className="mb-15">
        <div className="row">
            <div className="col-sm-12"> 
                <div className="accordion shadow-xs" id="kt_accordion_3">
                    <div className="accordion-item">
                        <h2 className="accordion-header" id="kt_accordion_1_header_3">
                            <button
                            className="accordion-button accordion-button-cu bg-white text-dark fw-bolder fs-2"                           
                            >
                            Previous Events
                            <span style={{right: "11px"}} className="right_side flex-wrap text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                                {/* <span>{selectedOption?.event_name?.split(":")[0]}</span>  */}
                                <span style={{minWidth:400}} 
                                     className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3">
                                    <Select options={options} onChange={handleChange} value={selectedOption} menuPortalTarget={document.body}
                                     styles={customStyles}
                                /></span>
                            </span>
                            <span
                            className="accordion-button"
                            data-bs-toggle="collapse"
                            data-bs-target="#kt_accordion_1_body_3"
                            aria-expanded="true"
                            aria-controls="kt_accordion_1_body_3"
                            >
                            </span>
                            </button>

                        </h2>
                        <div
                            id="kt_accordion_1_body_3"
                            className="accordion-collapse collapse show"
                            aria-labelledby="kt_accordion_1_header_3"
                            data-bs-parent="#kt_accordion_3"
                        >
                            <div className="accordion-body py-0">   
                                <div className="card-xl-stretch"> 
                                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 pt-5 px-2"> 
                                        <div className="card-toolbar">
                                            <ul className="nav">
                                            <li className="nav-item">
                                                <a
                                                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bolder px-4 me-1"
                                                data-bs-toggle="tab"
                                                href="#kt_table_widget_4_tab_1"
                                                onClick={() => handleEventType("1")}
                                                >
                                                Bets
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4 me-1"
                                                data-bs-toggle="tab"
                                                href="#kt_table_widget_4_tab_2"
                                                onClick={() => handleEventType("2")}
                                                >
                                                Parlays
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a
                                                className="nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bolder px-4"
                                                data-bs-toggle="tab"
                                                href="#kt_table_widget_4_tab_3"
                                                onClick={() => handleEventType("3")}
                                                >
                                                Summary
                                                </a>
                                            </li>
                                            </ul>
                                        </div> 
                                    </div> 
                                    <div className="card-body py-3 px-2">
                                        <div className="tab-content"> 
                                            <div className="tab-pane fade show active" id="kt_table_widget_4_tab_1"> 
                                                { preteamLoader ?
                                                <Spinner color="text-dark  spinner-border-sm text-center" />:
                                                <PreviousBets summaryData = {summaryData} previousteamList={previousteamList} previousbetTotal={previousbetTotal}/>}
                                            </div> 
                                            <div className="tab-pane fade" id="kt_table_widget_4_tab_2"> 
                                                {parlayLoader ?
                                                <Spinner color="text-dark  spinner-border-sm text-center" />: 
                                                <PreviousParlay parlayList={parlayList}
                                                summaryData = {summaryData}
                                                parlayevents = {parlayevents}
                                                setParlayEvents={setParlayEvents}
                                                />}
                                            </div> 
                                            <div className="tab-pane fade" id="kt_table_widget_4_tab_3"> 
                                                {summaryLoader ? 
                                                <Spinner color="text-dark  spinner-border-sm text-center" />:
                                                <PreviousSummary summaryData = {summaryData} setSummaryData = {setSummaryData} sortingIs ={sortingIs} 
                                                setSorting = {setSorting} activeSortName ={activeSortName} setActiveSort = {setActiveSort} />}
                                            </div> 
                                        </div>
                                    </div> 
                                </div> 

                            </div>
                        </div>
                    </div> 
                </div> 
            </div>
        </div>
    </section>
    )
}

const mapState = ({ previousSchedule, previousTeamData, clubPreviousParlay, getClubPreSummary }: any) => {
    return {
      _previousSchedule : previousSchedule,
      _previousTeamData: previousTeamData,
      _clubPreviousParlay : clubPreviousParlay,
      _getClubPreSummary : getClubPreSummary
    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $previousSchedule: () => dispatchEvent(previousSchedule()),
    $previousTeamData: (values?: IclubForAllbetPayload) => dispatchEvent(previousTeamData(values)),
    $clubPreviousParlay: (values?: IclubForAllbetPayload) => dispatchEvent(clubPreviousParlay(values)),
    $getClubPreSummary: (values?: IclubForAllbetPayload) => dispatchEvent(getClubPreSummary(values)),
  });
  export default connect(
    mapState,
    mapDispatch
  )(PreviewEvents);
