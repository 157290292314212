import React,{ useState, useEffect, useRef } from "react";
import { Formik, Field, Form, ErrorMessage, useFormikContext, useFormik } from "formik";
import { Link, useHistory } from "react-router-dom";
import queryString from 'query-string'
import Layout from "../../../Common/Layout";
import { connect } from "react-redux";
import { signUpData } from "../../../Store/action/Login.action";
import { initielValues_signUp, validationSchema_signUp } from "./Validation";
import { ButtonSpinner } from "../../../Common/Spinner/Spinner";
import Alert from "../../../Common/Alert/Alert";
import FormError from "../../../Common/FormError/FormError";
import { userProfile } from "../../../Store/action/UserInfo.action";
import PasswordStrengthBar from "../../../Common/password-strength-bar";
import ReactGA from 'react-ga'
import { signInWithFacebook, signInWithGoogle } from "../../../firebaseConfig";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { OwnID, ownidReactService } from "@ownid/react";

const Registration = (props?: any) => {

  const { $signUpData, $userProfile, _signUpData }: IsignupProps = props;
  const history = useHistory();
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [isButtonDisable,setIsButtonDisable] = useState<boolean>(true)
  const getFocus = useRef<any>(null);
  const [alertIs, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });
  const [agree, setAgree] = useState(false)

  let user = { email: "", firstName: "", lastName: '', screenName: "", password: "", inviteCode: "", clubCode: "", ownId: false, agree: [] }
  const qs = queryString.parse(props.location.search);

  localStorage.removeItem("token")
  if (Object.keys(qs)?.length) {
    user = { email: qs.email, firstName: qs.firstName, lastName: qs.lastName, screenName: "", password: "", clubCode: qs.clubCode, ownId: qs.ownId, inviteCode: qs.inviteCode, agree: [] };
    initielValues_signUp.email = qs.email || "";
    initielValues_signUp.inviteCode = qs.clubCode ? qs.clubCode : qs.inviteCode || ""
    initielValues_signUp.invitationType = qs.invitationType || ""
  }

  const handleShowPwd = () => {
    setShowPwd(!showPwdIs);
  };

  

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  useEffect(() => {
    getFocus.current?.focus();
  }, []);

  useEffect(() => {
    const response = _signUpData.signUpDataIs;
    const resErr = _signUpData.signUpErrIs;
    if (response?.status === 1 && LoadingIs) {
      localStorage.setItem("token", response.data.accessToken);
      getPageRedirection()
      setLoader(false);
    } else if (resErr?.status === 0 && LoadingIs) {
      getAlert(resErr.message, "alert-danger");
      setLoader(false);
      // setIsButtonDisable(true)
    }
  }, [_signUpData]);


  const getPageRedirection = () => {
    const stateIs = qs?.clubCode && qs.email ? `/clublist/${qs.email}/${qs.clubCode}` : ""
    if (qs?.r) {
      history.push({ pathname: qs.r })
    } else {
      history.push({ pathname: "/personalsettings", state: { signUpIs: true, stateIs } });
    }

    $userProfile();
  }

  useEffect(() => {
    if (alertIs.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 1000);
    }
  }, [alertIs]);

 
  

  const _userProfile = props._userProfile
  const userProfile = _userProfile?.userDataIs?.data?.screenName;

  const googleLogin = async () => {

    const response = await signInWithGoogle();

    if (response?.signUp == 'social-login') {
      localStorage.setItem("token", response?.data?.accessToken); history.push({
        pathname: "/personalsettings",
        state: {
          response: response.signUp,
        },
      })
    } else if (userProfile == "") {

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/personalsettings",
      })
    } else if (response?.data?.accessToken) {

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/createyourclub",
      })
    }
  }

  const faceBookLogin = async () => {

    const response = await signInWithFacebook();

    if (response?.signUp == 'social-login') {
      localStorage.setItem("token", response?.data?.accessToken); history.push({
        pathname: "/personalsettings",
        state: {
          response: response.signUp,
        },
      })
    } else if (userProfile == "") {

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/personalsettings",
      })
    } else if (response?.data?.accessToken) {

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/createyourclub",
      })
    }
  }

  // ------------------biometric----------------
  const emailField = useRef<any>(null);
  const passwordField = useRef<any>(null);

  const onSubmit = async (values: ISignUp) => {
    if(isButtonDisable){
      setIsButtonDisable(false)
        values.password = passwordField?.current?.value
        setLoader(true);
        $signUpData(values);
    }
  }

  // ------------Coverting component formik to hook


  const formik = useFormik<any>({

    initialValues: initielValues_signUp,
    validationSchema: validationSchema_signUp,
    onSubmit: onSubmit
  }
  )

  const handleChange = (e: any) => {
    formik.handleChange(e)
    setAgree(true)
  }
  useEffect(() => {
    formik.setFieldValue("agree", agree)
    }, [agree])

  useEffect(() => {
    setIsButtonDisable(true)
    },[formik.values]);

    // bio metrik

    // const onSubmit = async (values: ISignUp) => {
    //   let newData = "";
    //   if(isButtonDisable){
    //     setIsButtonDisable(false)
     
      // await createUserWithEmailAndPassword(getAuth(), emailField.current.value, passwordField?.current?.value)
      //   .then(async (data: any) => {
      //     await ownidReactService.enrollDevice();
          // values.password = passwordField?.current?.value
          // setLoader(true);
          // $signUpData(values);
  
        // })
        // .catch(err => {
        //   setLoader(true);
        //   values.password = passwordField?.current?.value
  
  
        //   $signUpData(values);
        // })
    //   }
    // }
  
  
  // if (passwordField?.current?.value && !formik.values.password) {
   
  //   formik.setFieldValue("ownId", true)

  //   formik.setFieldValue('password', passwordField?.current?.value)
  // }

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="dark-card-bg w-lg-500px bg-body rounded shadow-sm p-8 p-lg-15 mx-auto">
                <div className="text-center mb-50">
                  <input
                    type="password"
                    style={{
                      position: "fixed",
                      maxHeight: 0,
                      maxWidth: 0,
                      opacity: 0,
                      top: 0,
                      left: 0,
                      zIndex: -1,
                    }}
                  />
                  <h1 className="text-white mb-4 text-capitalize">
                    Join MMA BetClub
                  </h1>
                  <p className="text-gray-400 fw-bolder fs-4">
                    Welcome to MMA BetClub! Use your email to sign up and
                    get started today.
                  </p>

                  <div className="social-login mb-4 d-sm-flex d-block justify-content-center">
                    <a className="btn btn-md btn-social btn-google me-2 mb-3 w-100" onClick={googleLogin}>
                      <img src="https://cdn-icons-png.flaticon.com/512/2991/2991148.png" style={{ width: "20px" }} /> Sign up with Google
                    </a>


                    <a className="btn btn-md btn-social btn-facebook me-2 mb-3 w-100" onClick={faceBookLogin}>
                      <i className="bi bi-facebook"></i> Sign up with Facebook
                    </a>

                  </div>
                  <div className="text-center mb-4">
                    <h4 className="text-white modern-login-or">OR</h4>
                  </div>
                </div>
                {alertIs.activeIs && (
                  <Alert
                    statusIs={`alert ${alertIs.statusIs}`}
                    message={alertIs.msgIs}
                  />
                )}
               

                <form
                  className="form w-100"
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id="kt_sign_up_form"
                  action="#"
                >



                  <div className="fv-row mb-5">
                    {/* <label className="form-label fs-6 fw-bolder text-white">
                      Email
                    </label> */}
                    <input
                      className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.email &&
                        formik.errors.email &&
                        `border border-danger`
                        }`}
                      type="email"

                      ref={emailField}
                      name="email"
                      id="email"
                      placeholder="Email"
                      autoComplete="off"
                      value={formik.values.email}
                      onChange={(e: any) => formik.handleChange(e)}
                      disabled={user.email ? true : false}
                    />
                    <div>
                      {Boolean(formik.touched?.email) && Boolean(formik.errors?.email) && <span className="text-danger  start-0 top-100">
                        {formik.errors?.email}
                      </span>}

                    </div>
                  </div>
                  <div className="fv-row mb-5">
                    {/* <label className="form-label fs-6 fw-bolder text-white">
                      First Name
                    </label> */}
                    <input
                      className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.firstName &&
                        formik.errors.firstName &&
                        `border border-danger`
                        }`}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      value={formik.values.firstName}
                      onChange={(e: any) => formik.handleChange(e)}
                      id="firstName"
                      autoComplete="off"
                      disabled={user.screenName ? true : false}
                    />



                    {Boolean(formik.touched?.firstName) && Boolean(formik.errors?.firstName) && <span className="text-danger  start-0 top-100">
                      {formik.errors?.firstName}
                    </span>}
                  </div>


                  <div className="fv-row mb-5">
                    {/* <label className="form-label fs-6 fw-bolder text-white">
                      Last Name
                    </label> */}
                    <input
                      className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.lastName &&
                        formik.errors.lastName &&
                        `border border-danger`
                        }`}
                      type="text"
                      value={formik.values.lastName}
                      placeholder="Last Name"
                      onChange={(e: any) => formik.handleChange(e)}
                      name="lastName"
                      id="lastName"
                      autoComplete="off"
                      disabled={user.screenName ? true : false}
                    />

                    {Boolean(formik.touched?.lastName) && Boolean(formik.errors?.lastName) && <span className="text-danger  start-0 top-100">
                      {formik.errors?.lastName}
                    </span>}
                  </div>
                  <div className="fv-row mb-5">
                    {/* <label className="form-label fs-6 fw-bolder text-white">
                      Screen Name
                    </label> */}
                    <input
                      className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.screenName &&
                        formik.errors.screenName &&
                        `border border-danger`
                        }`}
                      type="text"
                      name="screenName"
                      value={formik.values.screenName}
                      onChange={(e: any) => formik.handleChange(e)}
                      id="screenName"
                      autoComplete="off"
                      placeholder="Screen Name"
                      disabled={user.screenName ? true : false}
                    />
                    <div className="text-muted">You will not be able to edit your screenname later.</div>
                    {Boolean(formik.touched?.screenName) && Boolean(formik.errors?.screenName) && <span className="text-danger  start-0 top-100">
                      {formik.errors?.screenName}
                    </span>}
                    {/* <ErrorMessage
                          name="screenName"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        /> */}
                  </div>
                  <div
                    className="mb-5 fv-row"
                    data-kt-password-meter="true"
                  >
                    <div className="mb-1">
                      {/* <label className="form-label fw-bolder text-white fs-6">
                        Password
                      </label> */}
                      <div className="position-relative mb-3">
                        <input
                          className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.password &&
                            formik.errors.password &&
                            `border border-danger`
                            }`}
                          type={showPwdIs ? `text` : `password`}
                          placeholder="Password"

                          value={formik.values.password}

                          ref={passwordField}
                          name="password"
                          id="password"
                          autoComplete="off"
                          onChange={(e: any) =>
                            e.target.value.length < 41
                              ? formik.handleChange(e)
                              : null
                          }
                        />

                        {/* <OwnID type='register'

                          options={{ variant: 'button-fingerprint', infoTooltip: true }}
                          loginIdField={emailField}
                          passwordField={passwordField}
                          onError={(error: any) => console.error(error)}

                          // onRegister={onRegister}

                        /> */}
                        <span
                          className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0"
                          data-kt-password-meter-control="visibility"
                          onClick={handleShowPwd}
                          // style={{marginRight:'5rem'}}
                        >
                          <i
                            className={`bi ${showPwdIs ? `bi-eye-slash` : `bi-eye`
                              } fs-2`}
                          />
                        </span>
                      </div>
                      {Boolean(formik.touched?.password) && Boolean(formik.errors?.password) && <span className="text-danger  start-0 top-100">
                        {formik.errors?.password}
                      </span>}
                      <PasswordStrengthBar
                        password={formik.values.password}
                      />
                    </div>
                    <div className="text-muted">
                      Use 8 or more characters with a mix of letters,
                      numbers &amp; symbols.
                    </div>
                  </div>
                  <div className="fv-row mb-5">
                    {/* <label className="form-label fs-6 fw-bolder text-white">
                      Invite code <span className="text-muted">(optional)</span>
                    </label> */}
                    <input
                      className={`modern-login-form-input   form-control form-control-lg form-control-solid ${formik.touched.inviteCode &&
                        formik.errors.inviteCode &&
                        `border border-danger`
                        }`}
                      type="text"
                      value={formik.values.inviteCode}
                      name="inviteCode"
                      id="inviteCode"
                      placeholder="Invite Code(Optional)"
                      autoComplete="off"
                      onChange={(e: any) =>
                        e.target.value.length < 7
                          ? formik.handleChange(e)
                          : null
                      }
                      disabled={user.inviteCode ? true : false}
                    />
                    {Boolean(formik.touched?.inviteCode) && Boolean(formik.errors?.inviteCode) && <span className="text-danger  start-0 top-100">
                      {formik.errors?.inviteCode}
                    </span>}
                  </div>

                      {/* <div className="text-center">
                        <button
                          type="submit"
                          // disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-1" ></button> 
                           </div>                      */}
                  {/* if clubCode exists store & send in api - its a hidden field */}
                  <input className="d-none" type="text" name="clubCode" id="clubCode" />

                  <div className="fv-row mb-5">
                    <div className="form-check form-check-custom form-check-solid form-check-inline align-items-start">
                      <input
                   
                        className={`form-check-input ${formik.touched.agree &&
                          formik.errors.agree &&
                          `border border-danger`
                          } `}
                        type="checkbox"
                        name="agree"
                        id="agree"
                        value={formik.values.agree}

                        onChange={handleChange}
                      />

                      <label
                        className="form-check-label fw-bold text-gray-700 fs-6"
                        htmlFor="agree"
                      >
                        I Agree &amp;
                        <Link to="/" className="ms-1 link-primary">
                          Terms and conditions
                        </Link>{" "}
                        &amp; By signing up, you agree to comply with our
                        terms and conditions .
                      </label>
                    </div>
                  </div>

                  <div className="text-center">
                    <button
                      type="submit"
                      id="kt_sign_in_submit"
                      className="btn btn-lg btn-orange w-100 mb-1"
                      disabled={!isButtonDisable}
                    >
                      <span className="indicator-label fw-bolder" >
                        {LoadingIs ? (
                          <ButtonSpinner color="text-light  spinner-border-sm " />
                        ) : (
                          `Continue`
                        )}
                      </span>
                    </button>
                  </div>
                  <div className="fv-row mb-8">
                    <div className="text-muted">
                      We’ll never share your email with anyone else
                    </div>
                  </div>
                  <div className="fv-row">
                    <label className="form-label fs-6 fw-bolder text-white">
                      Already have an account?
                    </label>
                    {
                      Object.keys(qs)?.length && qs?.email
                        ?
                        <span
                          className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                          onClick={() => history.push(`/login?clubCode=${qs.clubCode}&inviteCode=${qs.inviteCode}&email=${qs.email}`)}
                        >
                          <span className="indicator-label fw-bolder">
                            Sign In
                          </span>
                        </span>
                        :
                        Object.keys(qs)?.length && qs?.r
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => {
                              history.push(`/login?r=${qs.r}`)
                              ReactGA.pageview('/login')
                            }
                          }
                          >
                            <span className="indicator-label fw-bolder">
                              Sign In
                            </span>
                          </span>
                          :
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => {
                              history.push("/login")
                              ReactGA.pageview('/login')
                            }}
                          >
                            <span className="indicator-label fw-bolder">
                              Sign In
                            </span>
                          </span>
                    }
                  </div>
                </form>

                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ signUpData, userProfile }: any) => {
  return {
    _signUpData: signUpData,
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $signUpData: (values?: ISignUp) => dispatchEvent(signUpData(values)),
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(Registration);
