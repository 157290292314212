
import * as yup from "yup";

export const initialValues_changePwd = {
    // password: "",
    newPassword : "",
    confirmNewPwd: ""
}

export const validationSchema_changePwd = yup.object().shape({
    // password: yup
    // .string()
    // .required("Required")
    // .min(8, "Minimum 8 Characters Needed")
    // .matches(
    //   /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
    //   "Need at least one special character"
    // )
    // .matches(/[a-z]/i, "Need at least a character")
    // .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number"),
    newPassword: yup
    .string()
    .required("Required")
    .min(8, "Minimum 8 Characters Needed")
    .matches(
      /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
      "Need at least one special character"
    )
    .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number"),
    confirmNewPwd:yup.string().oneOf([yup.ref('newPassword')], 'Both password need to be the same').required('Required'),
})