import { useEffect, useState, useCallback } from "react";
import debounce from "lodash.debounce"
import {
  getResult,
  getUser,
  getAllEv,
  getUniqueBeters,
  getMe,
  getitsMe,
  getUniqueMe,
  getTotalRiskMe,
  stringWithValidLength,
  getCurrentWin,
  getResultClass,
  getLastName,
  getTotalRiskMePublic,
  getCurrentWinPublic,
  getResultNew,
  addStickyToTHead,
  removeStickyToTHead,
  calculateWinMyProfile
} from "../../../Club/AllEventCalculation/Calculation";
import { externalRedirect, getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import Modal from "react-modal";
import { ButtonSpinner } from "../../../../../Common/Spinner/Spinner";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
const moment = require("moment")
const EventBets = (props?: any) => {
  const { UserteamList, betData, UserbetData, setBetData, IsLocked, setSelectedBet , getActiveStatus,isModelPopup,setisModelPopup,setIsBetloader,isPopup,setIsPopup} = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const [showBetSaved, setShowBetSaved] = useState<any>({})
  const [timeDifference, setTimeDifference] = useState<any>();
  const [mainCard, setMainCard] = useState<any>();
  const [prelimsCard, setprelimsCard] = useState<any>();
  const [earlyPrelimsCard, setEarlyPrelimsCard] = useState<any>()
  const [lockAllModal, setlockAllModal] = useState<boolean>(false);
  const [Loader, setLoader] = useState<boolean>(false);
  const [lockData, setlockData] = useState<any>({})
  // const [isPopup,setIsPopup] = useState<boolean>(false)
  var totalWin: number = 0; 

  const closeModal = () => {
    setlockAllModal(false)
    addStickyToTHead();
    setIsPopup(false)
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "500px",
      width: "100%",
    },
  };



  const handleOnchange = (e: any, pickedTeam: any, scheduleId: any, moneyLine:number, status:any, away_team:any, home_team:any) => {
    const { value } = e.target;
    if(value <= 999999){
      let newScheduleData = {}
      if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {
        newScheduleData = { scheduleId: scheduleId, risk: value, pickedTeam: pickedTeam, win: getCurrentWin(value, moneyLine), moneyLine, status,away_team, home_team}
        const list = [
          ...UserbetData.filter((k: any) => k.scheduleId !== scheduleId),
          newScheduleData,
        ];
        setBetData([...list]);
      }
    debouncedChangeHandler(newScheduleData)
    }
  };
  const handleOnBlur = (newScheduleData: any) => {
    setSelectedBet([newScheduleData]);
    if(newScheduleData?.risk>0){
      setShowBetSaved({msg: `Bet amount $${newScheduleData?.risk} saved.` , status:true})
      setTimeout(() => { setShowBetSaved({status: false})}, 2000)
      // setIsBetActive(false)
    }
  }

  const debouncedChangeHandler=useCallback(
    debounce(handleOnBlur, 1000)
  , []);

  const lockButtonClicked = (e:any, pickedTeam:any, scheduleId:any, moneyLine:any, status:any, home_team:any, away_team:any) => {
  
    const ele:any = document.getElementById(e.target.id)
    if(ele){
      const value:any = ele?.previousElementSibling?.value
      if(value <= 0){
        alert("To lock a Bet, Risk must be greater than $0.")
        return
      }
      if (value <= 999999) {
        if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {
          const singleSaveList = { scheduleId: scheduleId, risk: value, pickedTeam: pickedTeam, win: getCurrentWin(value, moneyLine), moneyLine, status, home_team:home_team, away_team:away_team}
          if(!isModelPopup) {
            setlockData({ele:ele,scheduleId:scheduleId,risk:value,pickedTeam:pickedTeam,win: getCurrentWin(value, moneyLine),moneyLine, status, home_team:home_team, away_team:away_team})
            setlockAllModal(true)
            return
          } else {
            getActiveStatus(true)
            debouncedChangeHandler(singleSaveList)
            ele.innerHTML = ""
            ele.innerHTML = `<div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>`
            setIsBetloader(true)           
          }
        }
      }
    } 
  }
  const lockBetEvent = ()=>{
    // console.log(lockData)
    if(isPopup){
      setisModelPopup(true)
    }
    getActiveStatus(true)
    debouncedChangeHandler({scheduleId: lockData.scheduleId, risk: lockData.risk, pickedTeam: lockData.pickedTeam, win: lockData.win, moneyLine:lockData.moneyLine, status:lockData.status,away_team:lockData.away_team,home_team:lockData.home_team,isModelPopup:isPopup})
          lockData.ele.innerHTML = ""
          lockData.ele.innerHTML = `<div class="spinner-border spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
          </div>`
          
    setlockAllModal(false)
}

  useEffect(() => {
    setUsers(getUser(UserteamList));
    setMeData(getMe(UserteamList));
  }, [UserteamList]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);

  useEffect(()=>{
    const deadline = UserteamList?.slice(-1)[0];
    setTimeDifference(getDiffrenceOfTimeInMinutes(deadline?.date_event));
  },[])

  useEffect(() => {
    const mainCardFighter = UserteamList && UserteamList?.filter((ele:any) => ele?.event_card === 'Main Card')
    const prelimsFighter = UserteamList && UserteamList?.filter((ele:any) => ele?.event_card === 'Prelims')
    const earlyPrelimsFighter = UserteamList && UserteamList?.filter((ele:any) => ele?.event_card === 'Early Prelims')
    setMainCard(mainCardFighter)
    setprelimsCard(prelimsFighter)
    setEarlyPrelimsCard(earlyPrelimsFighter)
  }, [UserteamList]);
  const getIsMe = (teamType: number, scheduleId: string, moneyLine: any, IsLocked: boolean, eventStatus:String, winningStatus: any, isthisEventLocked:any, eventLockTime:string,home_team:String, away_team:String) => {
    const isDisabled = UserbetData?.find((l: any) => l.scheduleId === scheduleId && l.pickedTeam !== teamType)?.risk || getDiffrenceOfTimeInMinutes(eventLockTime) < 0;
    const risk = UserbetData?.find((el: any) => el.scheduleId === scheduleId && el.pickedTeam === teamType)?.risk;
    const win = UserbetData?.find( (l: any) => l.scheduleId === scheduleId && l.pickedTeam === teamType)?.win
    const isThisEventLocked =  UserbetData?.find( (l: any) => l.scheduleId === scheduleId && l.pickedTeam === teamType)?.isThisEventLocked
    const isBetHidden =UserbetData?.find( (l: any) => l.scheduleId === scheduleId && l.pickedTeam === teamType)?.isBetHidden
    // if public profile calculate win only for locked events
    props?.isPublicProfileUser 
    ?
    totalWin =  totalWin +  getCurrentWinPublic(risk, moneyLine, isThisEventLocked) 
    :
    totalWin = totalWin +  getCurrentWin(risk, moneyLine)
    
    return (
      <td className="table-column table_width">
        <div className="d-flex align-items-center justify-content-between">
        <div
            className={`w-50 r ${
              isthisEventLocked &&
              !isNaN(risk) &&
              eventStatus === "STATUS_FINAL"
                ? getResultClass(winningStatus, risk ? risk : "-")
                : ""
            }`}
          >
          
            {isthisEventLocked
            ? 
              (!isNaN(risk) 
              ?
            (  props?.isPublicProfileUser 
              ?
              !isBetHidden ? 
              <>
              {(new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk))}
                 {getDiffrenceOfTimeInMinutes(eventLockTime)  > 0?  <i className="bi bi-lock-fill mx-1"></i> :""}
              </>
                 :
                 '-'
              :
              <>               
                 {(new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk))}
                 {getDiffrenceOfTimeInMinutes(eventLockTime)  > 0?  <i className="bi bi-lock-fill mx-1"></i> :""}
               </> )
                
              : "-")
            :
            (
              props?.isPublicProfileUser
              ?
              "-"
              :
              getDiffrenceOfTimeInMinutes(eventLockTime)  > 0  ? 
              <span className="d-flex position-relative">
                {risk ? <span className="m-2 position-absolute inputBoxDollarsign">$</span> : ""} {/* if value then show $ */}
                <input
                  type="text"
                  name="risk"
                  className="form-control form-control-solid bg-white"
                  placeholder={!isDisabled ? "Enter Risk" : ""}
                  value={ risk || ""}
                  onChange={(e) => {handleOnchange(e, teamType, scheduleId, moneyLine, "1", away_team, home_team)}}
                  disabled={isDisabled}
                />
                {
                  !isDisabled 
                  && 
                  <span 
                    className="btn-orange rounded m-l p-2 d-flex justify-content-center align-items-center" 
                    id={String(Math.ceil(Math.random()*100000000))} 
                    onClick={(e) => lockButtonClicked(e, teamType, scheduleId, moneyLine, "2",home_team, away_team) } 
                  >
                    <i className="bi bi-lock-fill p-0 text-white" style={{ pointerEvents:'none'}}/>
                  </span>
                }
              </span>
              :
              (
                <div
                className="d-flex position-relative justify-content-center align-items-center"
              >
                {
                !isthisEventLocked ?
                (
                  !isNaN(risk) ? (
                    <>
                      {"$" + new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk)}
                    
                    </>
                  ) : (
                    "-"
                  )
                )
                :
                ""
                }
                </div>
              )
            )            
            } 
          </div>
          <div className={`w-50 w ${isthisEventLocked && !isNaN(risk) && eventStatus==="STATUS_FINAL" ? getResultClass(winningStatus, risk ? risk : "-") : ""}`}>
          {
            isthisEventLocked
            ? //if locked checked if number value, then add dollar otherwise "-"
            // (!isNaN(win) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(win) : "-")
            (  props?.isPublicProfileUser 
              ?
              !isBetHidden ? 
              <>
              {(!isNaN(win) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(win) : "-")}
              </>
                 :
                 '-'
              :
              <>               
                 {(!isNaN(win) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(win) : "-") }
               </> )
            :
            props?.isPublicProfileUser ? "-" :  (risk && moneyLine? "$"+getCurrentWin(risk, moneyLine): "-")
          }
          </div>
        </div>
      </td>
    );
  };

  return (
    <>
    
    <div className="custom_table position-relative table-responsive h-500">
    {
        showBetSaved?.status
        ?
        <div className="alert alert-success position-absolute alert-dismissible fade show d-flex align-items-center justify-content-center w-100" style={{zIndex:999999999}} role="alert">
          {showBetSaved?.msg}
        </div>
        :
        ""
      }
      <table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        id="kt_subscriptions_table_2"
        role="grid"
      >
        <thead className="sticky-top" style={{background:"white"}}>
          <tr
            className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
            role="row"
          >
            <th
              className="min-w-250px cu-12 sorting text-start ps-4"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
            >
              Fighters
            </th>
            {/* <th
              className="min-w-100px sorting"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 100 }}
              // aria-sort="ascending"
            >
              Moneyline
            </th>
            <th
              className="min-w-100px sorting"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Billing: activate to sort column ascending"
              style={{ width: 100 }}
            >
              Result
            </th> */}
            <th
              className="min-w-175px sorting"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
              style={{ width: 175 }}
            >
              Bet
              <div className="d-flex align-items-center justify-content-between pt-2">
                <div className="w-50">Risk</div>
                <div className="w-50">Win</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold cu-table-01">
          { mainCard && mainCard?.length > 0 ? (
            <>
            <div className="badge badge-pill btn-orange" style = {{fontSize:'14px',  marginTop:'2px',left:0 ,position: "sticky"}}>Main Card: {moment(mainCard[0]?.eventCardTime).format("hh:mm a")}</div>
            {mainCard.map((obj: any, index: number) => (
              <>     
                <tr className="bg-table-row-transparent" key={index}>
                  <td
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {!obj?.awayTeamPic?
                      <span className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}                
                  {obj?.moneyline_away ? (
                    getIsMe(2, obj?.id, obj.moneyline_away, IsLocked, obj?.event_status, obj?.result?.winner_away, obj?.isLock, obj?.eventCardTime,obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>           
                <tr className="bg-table-row-2">
                <td
                // role={obj?.homeTeamUrl && "button"} 
                className="text-gray-800 text-start ps-4 table-column " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                   
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {obj?.moneyline_home ? (
                    getIsMe(1, obj?.id, obj.moneyline_home, IsLocked, obj?.event_status, obj?.result?.winner_home, obj?.isLock, obj?.eventCardTime, obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>              
              </>
            ))}</>
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 w-100 text-start ps-4">
                {
                  props?.isPublicProfileUser
                  ?
                  "No picks are locked yet"
                  :
                  "Data Not found..."
                }
              </td>
            </tr>
          )}
          { prelimsCard && prelimsCard?.length > 0 ? (
            <>
           <div className="badge badge-pill btn-orange" style = {{fontSize:"14px",  marginTop:'2px',left:0 ,position: "sticky"}}>Prelims: {moment(prelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
            {prelimsCard.map((obj: any, index: number) => (
              <>     
                <tr className="bg-table-row-transparent" key={index}>
                  <td
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {!obj?.awayTeamPic?
                      <span className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {obj?.moneyline_away ? (
                    getIsMe(2, obj?.id, obj.moneyline_away, IsLocked, obj?.event_status, obj?.result?.winner_away, obj?.isLock, obj?.eventCardTime, obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column ">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>           
                <tr className="bg-table-row-2"> 
                <td
                // role={obj?.homeTeamUrl && "button"} 
                className="text-gray-800 text-start ps-4 table-column " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                   
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {obj?.moneyline_home ? (
                    getIsMe(1, obj?.id, obj.moneyline_home, IsLocked, obj?.event_status, obj?.result?.winner_home, obj?.isLock, obj?.eventCardTime, obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>              
              </>
            ))}</>
          ) : (
            ""
            // <tr className="bg-table-row-transparent">
            //   <td className="text-gray-800 w-100 text-start ps-4">
            //     {
            //       props?.isPublicProfileUser
            //       ?
            //       "No picks are locked yet"
            //       :
            //       "Data Not found..."
            //     }
            //   </td>
            // </tr>
          )}

          {earlyPrelimsCard && earlyPrelimsCard?.length > 0 ? (
            <>
            <div className="badge badge-pill btn-orange" style = {{fontSize:"14px",  marginTop:'2px',left:0 ,position: "sticky"}}>Early Prelims: {moment(earlyPrelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
            {earlyPrelimsCard.map((obj: any, index: number) => (
              <>     
                <tr className="bg-table-row-transparent" key={index}>
                  <td
                  // role={obj?.awayTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {!obj?.awayTeamPic?
                      <span className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className="d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {obj?.moneyline_away ? (
                    getIsMe(2, obj?.id, obj.moneyline_away, IsLocked, obj?.event_status, obj?.result?.winner_away, obj?.isLock, obj?.eventCardTime, obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>           
                <tr className="bg-table-row-2"> 
                <td
                // role={obj?.homeTeamUrl && "button"} 
                className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                   
                  </td>
                  {/* <td className="table-column">{obj?.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {obj?.moneyline_home ? (
                    getIsMe(1, obj?.id, obj.moneyline_home, IsLocked, obj?.event_status, obj?.result?.winner_home, obj?.isLock, obj?.eventCardTime, obj?.home_team, obj?.away_team )
                  ) : (
                    <td className="table-column">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-50">-</div>
                        <div className="w-50">-</div>
                      </div>
                    </td>
                  )}
                </tr>              
              </>
            ))}</>
          ) : (
            ""
            // <tr className="bg-table-row-transparent">
            //   <td className="text-gray-800 w-100 text-start ps-4">
            //     {
            //       props?.isPublicProfileUser
            //       ?
            //       "No picks are locked yet"
            //       :
            //       "Data Not found..."
            //     }
            //   </td>
            // </tr>
          )}
          {/* Total Number Row  */}
          {UserteamList?.length > 0 && (
            <tr className="total_number">
              <td className="ps-5 text-start" style={{ width: 175, position: "sticky", left: 0, background: "black", zIndex: 1}}>Bet Amount and Margin</td>
              {/* <td></td>
              <td></td> */}
              <td>
                <div className="d-flex align-items-center justify-content-between">
                  {
                    props?.isPublicProfileUser
                    ?
                    <>
                      <div className="w-50">{getTotalRiskMePublic(UserbetData) ? getTotalRiskMePublic(UserbetData) : "$ 0.00"}</div>
                      <div className="w-50">{calculateWinMyProfile(UserbetData) ? calculateWinMyProfile(UserbetData) : "$0.00"}</div>
                    </>
                    :
                    <>
                      <div className="w-50">{(getTotalRiskMe(UserbetData)) ? getTotalRiskMe(UserbetData) : "$ 0.00"}</div>
                      <div className="w-50">{!isNaN(totalWin) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(totalWin) : "$ 0.00"}</div>
                      <Modal isOpen={lockAllModal} onRequestClose={closeModal} onAfterOpen={removeStickyToTHead} style={customStyles} contentLabel="Example Modal">
                        <div>
                          <p className="text-center p-5" style={{fontSize:'15px'}}>Are you sure you want to lock this Bet? You won't be able to make any changes.</p>
                          
                          <label className="form-controlcheck d-flex align-items-center ">
                            <input type="checkbox"  onChange={() => {setIsPopup(!isPopup)}} />
                            <span className="ms-2" >Don't show popup again</span>
                          </label>

                          <div className="text-center d-flex mt-5">
                            <button type="button" id="kt_sign_in_submit" className="btn btn-lg btn-orange m-2 w-100 mb-5" onClick={(e:any)=>{
                              lockBetEvent()
                            }} disabled={Loader}>
                              <span className="indicator-label fw-bolder">{Loader ? <ButtonSpinner color="text-black spinner-border-sm " /> : "Save"}</span>
                            </button>
                            <button
                              type="button"
                              id="kt_sign_in_submit"
                              className="btn btn-flex flex-center m-2 btn-light btn-lg w-100 mb-5"
                              onClick={() => {
                                addStickyToTHead();
                                setlockAllModal(false);
                                setIsPopup(false)
                              }}
                            >
                              <span className="indicator-label fw-bolder">Cancel</span>
                            </button>
                          </div>
                        </div>
                    </Modal>
                    </>
                  }
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
    </>
  );
};
export default EventBets;
