import { Link } from 'react-router-dom'
import AboutImage from '../../../assets/images/mmaBetclub.jpg';
// import AboutOne from '../../../assets/images/about/img-15.jpg';
// import AboutTwo from '../../../assets/images/about/img-72.jpg';
// import AboutThree from '../../../assets/images/about/img-73.jpg';
// import DummyImageOne from '../../../assets/images/avatars/150-11.jpg';
// import DummyImageTwo from '../../../assets/images/avatars/150-12.jpg';
// import DummyImageThree from '../../../assets/images/avatars/150-13.jpg';
// import DummyImageFour from '../../../assets/images/avatars/150-14.jpg';
import Facebook from '../../../assets/images/Icons/facebook.svg';
import Instagram from '../../../assets/images/Icons/instagram.svg';
import Twitter from '../../../assets/images/Icons/twitter.svg';
import Linkedin from '../../../assets/images/Icons/linkedin.png';
import Header from '../../../Common/Header';
import Tiktok from '../../../assets/images/Icons/tiktok.png';
import { useEffect } from 'react';

const AboutUs = () => {
    const flodeskForm = () => {
        return {
            __html:`
            <div id="fd-form-643fe1f8a5edb7e9cf295e3c"></div>
            <script>
              ${window?.fd('form', {
                formId: '643fe1f8a5edb7e9cf295e3c',
                containerEl: '#fd-form-643fe1f8a5edb7e9cf295e3c'
              })}
            </script>`
          }
      }
      useEffect(()=>{
        flodeskForm()
      },[])
    
    return (
        <section>
            <Header options="fill_Header" />
            <div className="main-content">
                    <div className="container py-15">
                        <div className="row">
                            <div className="col-sm-12">
                                <div id="kt_content_container" className="container"> 
                                    <div className="card dark-card-bg"> 
                                        <div className="card-body p-lg-17"> 
                                        <div className="mb-18"> 
                                            <div className="mb-10"> 
                                            <div className="text-center mb-15"> 
                                                <h3 className="fs-2hx text-white mb-5">About Us</h3> 
                                                <div className="w-75 mob-w-100 mx-auto fs-5 text-muted fw-bold">
                                                MMA BetClub was designed to give friends that love to compete a place to come together to share their fight predictions, talk through their strategies as a private group, and see historical data to prove who the real experts are.
                                                </div> 
                                            </div> 
                                            <div className="overlay"> 
                                                <img
                                                className="w-100 card-rounded"
                                                src={AboutImage}
                                                alt="..."
                                                /> 
                                            </div> 
                                            </div> 
                                            <div className="fs-5 fw-bold text-gray-600"> 
                                            <p className="mb-8">
                                            Although MMA BetClub is a Fantasy MMA/Social site and not a casino for actual wagers, players are able to enter bet amounts to track or simulate their betting scenarios and confidence by entering dollar amounts. Global Leaderboards provide visibility into the top performers on the site regardless of the Clubs they’re in, and their picks can help influence your decisions to maximize points earned, or profits on the betting site of your choice.
                                            </p> 
                                            </div> 
                                        </div> 
                                        {/* flodesk form */}
                                        <div
                                        dangerouslySetInnerHTML={flodeskForm()}
                                        /> <br />
                                        <div className="text-center mb-5">
                                        {/* <h3 className="fs-2hx text-dark mb-5">DISCLAIMER</h3> */}
                                        <div className="w-75 mob-w-100 mx-auto fs-4 text-gray-600 fw-bold">Questions or feedback for MMA BetClub? Contact us at <a href="mailto:support@mmabetclub.com">support@mmabetclub.com</a></div>
                                    </div> 
                                        <div className="card mb-4 bg-light text-center"> 
                                            <div className="card-body py-12"> 
                                            <a href="https://www.facebook.com/MMABetClub" target="_blank" className="mx-4">
                                                <img src={Facebook} className="h-30px my-2" alt="MMA Betclub Facebook" />
                                            </a> 
                                            <a href="https://www.instagram.com/mma_betclub/" target="_blank" className="mx-4">
                                                <img src={Instagram} className="h-30px my-2" alt="MMA Betclub Instagram" />
                                            </a> 
                                            <a href="https://twitter.com/MMA_Betclub" target="_blank" className="mx-4">
                                                <img src={Twitter} className="h-30px my-2" alt="MMA Betclub Twitter" />
                                            </a> 
                                            <a href="https://www.linkedin.com/company/mma-betclub" target="_blank" className="mx-4">
                                                <img src={Linkedin} className="h-30px my-2" alt="MMA Betclub Linkedin" />
                                            </a> 
                                            <a href="https://www.tiktok.com/@big_joe_a" target="_blank" className="mx-4">
                                                <img src={Tiktok} className="h-30px my-2" alt="MMA Betclub Tiktok" />
                                            </a>
                                            </div> 
                                        </div> 
                                        </div> 
                                    </div> 
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

        </section>
    )
}
export default AboutUs