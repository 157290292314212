import {
  USER_PROFILE,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  COUNTRY_DETAILS,
  COUNTRY_DETAILS_SUCCESS,
  COUNTRY_DETAILS_ERROR,
  UPDATE_USER,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ERROR,
  LOGOUT_USER,
  CREATE_CLUB,
  CREATE_CLUB_SUCCESS,
  CREATE_CLUB_ERROR,
  CHANGE_PWD,
  CHANGE_PWD_SUCCESS,
  CHANGE_PWD_ERROR,
  GET_CLUB_DETAILS,
  GET_CLUB_DETAILS_SUCCESS,
  GET_CLUB_DETAILS_ERROR,
  INVITE_FRND,
  INVITE_FRND_SUCCESS,
  INVITE_FRND_ERROR,
  JOIN_CLUB,
  JOIN_CLUB_SUCCESS,
  JOIN_CLUB_ERROR,
  PROFILE_PIC,
  PROFILE_PIC_SUCCESS,
  PROFILE_PIC_ERROR,
  JOINING_CLUB_DETAILS,
  JOINING_CLUB_DETAILS_SUCCESS,
  CLUB_PIC,
  CLUB_PIC_SUCCESS,
  CLUB_PIC_ERROR,
  USER_CLICKED_EVENT_DETAIL,
  GET_CLUB_INVITATION_SUCCESS,
} from "../type.action";

// user Profile
export const userProfile = () => ({
  type: USER_PROFILE,
});
export const userProfileSuccess = (values?: IUserProfileRes) => ({
  type: USER_PROFILE_SUCCESS,
  values
});
export const userProfileError = (error?: IData) => ({
  type: USER_PROFILE_ERROR,
  error
});

// get Country
export const getCountry = () => ({
  type: COUNTRY_DETAILS
});
export const getCountrySuccess = (values?: IcountryRes) => ({
  type: COUNTRY_DETAILS_SUCCESS,
  values
});
export const getCountryErr = (error?: IData) => ({
  type: COUNTRY_DETAILS_ERROR,
  error
});

// update user Profile *****************
export const updateUser = (values?: IPersonalSettingArgu) => ({
  type: UPDATE_USER,
  values
});
export const updateUserSuccess = (values?: IData) => ({
  type: UPDATE_USER_SUCCESS,
  values
});
export const updateUserErr = (error?: IData) => ({
  type: UPDATE_USER_ERROR,
  error
});

// LOGOUT 
export const logout = () => ({
  type: LOGOUT_USER
})

// CREATE CLUB
export const createClub = (values?: ICreateClubDetails) => ({
  type: CREATE_CLUB,
  values
})
export const createClubSuccess = (values? : IData) =>({
  type: CREATE_CLUB_SUCCESS,
  values
})
export const createClubError = (error?: IData) => ({
  type: CREATE_CLUB_ERROR,
  error
})

// change Pwd
export const changePwd = (values: {password : string}) => ({
  type: CHANGE_PWD,
  values
})
export const changePwdSuccess = (values?: IData) => ({
  type: CHANGE_PWD_SUCCESS,
  values
})
export const changePwdErr = (error?: IData) => ({
  type: CHANGE_PWD_ERROR,
  error
})

// get my club *******

export const getClubDetails = () => ({
  type: GET_CLUB_DETAILS
})
export const getClubDetailsSuccess = (values ?: IClubDetailsRes) => ({
  type: GET_CLUB_DETAILS_SUCCESS,
  values
})
export const getClubInvitationSuccess = (values ?: any) => ({
  type: GET_CLUB_INVITATION_SUCCESS,
  values
})
export const getClubDetailsErr = (error ?: IData) => ({
  type: GET_CLUB_DETAILS_ERROR,
  error
})

// send invite

export const inviteFrnds = (values ?: IinviteFrnd) => ({
  type: INVITE_FRND ,
  values
})
export const inviteFrndsSuccess = (values ?: IData) => ({
  type : INVITE_FRND_SUCCESS,
  values
})
export const inviteFrndError = (error ?: IData) => ({
  type: INVITE_FRND_ERROR,
  error
})

// join club
export const joinClub = (values ?: IJoinClub) => ({
  type: JOIN_CLUB,
  values
})
export const joinClubSuccess = (values ?: IData) => ({
  type : JOIN_CLUB_SUCCESS,
  values
})
export const joinClubErr = (error ?: IData) => ({
  type: JOIN_CLUB_ERROR,
  error
})

// profile Pic
export const profilePic = (values?: any) => ({
  type: PROFILE_PIC,
  values
})
export const profilePicSuccess = (values ?: any) =>({
  type: PROFILE_PIC_SUCCESS,
  values
})
export const profilePicErr = (error?: any) => ({
  type: PROFILE_PIC_ERROR,
  error
})

// invited club details
export const joiningClubDetails = (values ?: IJoinArg) => ({
  type: JOINING_CLUB_DETAILS,
  values
})
export const joiningClubDetailsSuccess = (values ?: IjoiningDetailsRes) =>({
  type: JOINING_CLUB_DETAILS_SUCCESS,
  values
})

// club pic 
export const clubPic = (values?: any)=>({
  type: CLUB_PIC,
  values
})
export const clubPicSuccess = (values?: any) => ({
  type: CLUB_PIC_SUCCESS,
  values
})
export const clubPicErr = (error ?: any) => ({
  type: CLUB_PIC_ERROR,
  error
})

// user clicked event details
export const userClickedEventDetail = (values ?: any) => ({
  type: USER_CLICKED_EVENT_DETAIL,
  values
})