import GamePlanner from "./AllTime/GamePlanner/GamePlanner";
import PoundForPound from "./AllTime/PoundForPound/PoundForPound";
import MakingChampionship from "./AllTime/Championship/MakingChampionship";
import GamePlannerEvent from "../Events/GamePlanner";
import PoundForPoundEvent from "../Events/PoundForPound";
import CorrectBets from "../Events/CorrectBets";
import Layout from "../../../Common/Layout";
import { customStyles } from "../../Authenticated/Club/AllEventCalculation/Calculation";
import Select from "react-select";
import { useEffect, useState } from "react";
import { getApi } from "../../../Store/api-interface/api-interface";
import { BaseUrl } from "../../../Utils/BaseUrl";
import { useParams } from "react-router-dom";
import axios from "axios";

const Leaderboard = (props?: any) => {
  const [optionsIs, setOptions] = useState<any>(null);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [allCompletedEvents, setAllCompletedEvents] = useState<any>([]);
  const [eventDate, seteventDate] = useState<any>([]);
  let newDate = new Date().getFullYear();
  const [quarter, setQuarter] = useState<any>({ quarter: 0, newYear: newDate });

  const [active, setActive] = useState(null);
  const { screenName } = useParams<any>();

  const handleChange = (data: any) => {
    setSelectedOption(data);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${BaseUrl}/user/getMyLeaderboardQuarter`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        let newData = response.data.data?.event_quarter;

        // newData = newData?.filter((e:any)=>e.event_year<=allCompletedEvents?.[0]?.date_event?.split('-')?.[0])
        // console.log(newData)

        // To set default as Q1(2023)
        // setQuarter({ quarter: 0, newYear: newData[0]?.event_year})
         
        // To set default as All Time
        setQuarter({ quarter: 0, newYear: 0})
        
        // newData.map((item: any) => {
        //   if (quarter.newYear !== item.event_year) {
        //     setQuarter({ quarter: 0, newYear: quarter.newYear -1 });
        //   }
        // });
        seteventDate(response.data.data?.event_quarter);
      });
  }, []);

  // console.log(eventDate,"DDDDDDD");

  useEffect(() => {
    getApi(`/leaderboardChart/getEventswithAllFightsCompleted`).then((res) => {
      if (res?.data?.status === 1) {
        setAllCompletedEvents(res?.data?.data?.reverse());
      }
    });
  }, []);

  useEffect(() => {
    if (allCompletedEvents && allCompletedEvents?.length) {
      setOptions(allCompletedEvents);
      const ObjIs = allCompletedEvents[0];
      const defaultValueIs = { value: ObjIs?.date_event, label: ObjIs?.event_name, ...ObjIs };
      setSelectedOption(defaultValueIs);
    }
  }, [allCompletedEvents]);

  const optionNew = optionsIs?.map((obj: any) => {
    let data = { value: obj?.date_event, label: obj?.event_name, ...obj };
    return data;
  });

  return (
    <Layout options="fill_Header" uservisible="visible">
      <section className="main-content">
        <div className="container py-10 leaderboard_table_wrapper">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="d-flex align-items-center justify-content-between pt-10">
                <div>
                  <h2 className="text-white fs-2x fw-bolder mb-0">Leaderboard</h2>
                </div>
                <div className="leaderboard_tabs">
                  <div className="card-xl-stretch right_tabs">
                    <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                      <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                        <ul className="nav">
                          <li className="nav-item">
                            <a className="nav-link btn btn-color-muted btn-active btn-active-light-primary active fw-bold fs-6 px-4" style={{marginLeft:"4px"}} data-bs-toggle="tab" href="#kt_table_widget_3_tab_1">
                              All Time
                            </a>
                          </li>
                          <li className="nav-item">
                            <a className="nav-link btn btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1 fs-6" data-bs-toggle="tab" href="#kt_table_widget_3_tab_2">
                              Events
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/*end::Header*/}
                  </div>
                  {/*end::Tables Widget 4*/}
                </div>
              </div>
            </div>
            <div className="col-sm-12 px-0">
              {/*begin::Body*/}
              <div className="card-body py-3 px-2">
                <div className="tab-content">
                  {/*begin::Tap pane*/}
                  <div className="tab-pane fade show active" id="kt_table_widget_3_tab_1">
                    {/*begin::Tables Widget 4*/}
                    <div className="card card-xl-stretch p-5">
                      {/*begin::Header*/}
                      <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                        <div className="card-toolbar order-2 order-sm-0 mob-w-100">
                          <ul className="nav">
                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary active fw-bold mb-2 px-4 me-1 mr-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_1"
                              >
                                ROI
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary fw-bold mb-2 px-4 me-1 ml-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_2"
                              >
                                Pick Win %
                              </a>
                            </li>

                            <li className="nav-item">
                              <a
                                className="nav-link buttonMarginPadding btn btn-light btn-color-muted btn-active btn-active-light-primary fw-bold mb-2 px-4 me-1 ml-3"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_4_tab_3"
                              >
                                Win Streak
                              </a>
                            </li>
                            <div className="dropdown" style={{ marginLeft: "9px" }}>
                              <button type="button" className="btn dropdown-toggle btn-quater" style={{ width: "10.5rem" }} data-toggle="dropdown">
                                {/* Quaterly */}
                                {quarter && quarter.quarter >= 1
                                  ? `Q${quarter.quarter} ${quarter.newYear}`
                                  : quarter.newYear >= 2022
                                  ? `${quarter.newYear} ${quarter?.newYear == 2023 ? "(YTD)" : "(All)"}`
                                  : "All Time"}
                              </button>
                              <div className="dropdown-menu leader_custom custm_scrollbar">
                                <div
                                  className="dropdown-item vv"
                                  style={{ fontWeight: 600, width: "10rem" }}
                                  onClick={() => {
                                    setQuarter({ quarter: 0, newYear: 0 });
                                  }}
                                >
                                  All Time
                                </div>
                                {eventDate?.map((el: any) => {
                                  return (
                                    <>
                                      <div
                                        className={`dropdown-item ${active == el && "active"}`}
                                        style={{ fontWeight: "bold" }}
                                        onClick={() => {
                                          setQuarter({ quarter: 0, newYear: el?.event_year });
                                          setActive(el);
                                        }}
                                      >
                                        {el?.event_year} {el?.event_year == 2023 ? "(YTD)" : "(All)"}
                                      </div>

                                      {el?.event_quarter?.map((x: any) => {
                                        return (
                                          <>
                                            {" "}
                                            <div
                                              className={`dropdown-item ${active == x && "active"}`}
                                              onClick={() => {
                                                setQuarter({ quarter: x, newYear: el?.event_year });
                                                setActive(x);
                                              }}
                                            >
                                              Q{x} {el?.event_year}
                                            </div>
                                          </>
                                        );
                                      })}
                                    </>
                                  );
                                })}
                              </div>
                            </div>
                            <li className="text-muted p-3">* Minimum of 15 points to qualify</li>
                          </ul>
                        </div>
                      </div>

                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body py-3 px-2">
                        <div className="tab-content table_wrapper">
                          {/*begin::Tap pane*/}
                          <div className="tab-pane fade show active" id="kt_table_widget_4_tab_1">
                            <GamePlanner quarter={quarter} />
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div className="tab-pane fade" id="kt_table_widget_4_tab_2">
                            <PoundForPound quarter={quarter} />
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div className="tab-pane fade" id="kt_table_widget_4_tab_3">
                            <MakingChampionship quarter={quarter} />
                          </div>
                          {/*end::Tap pane*/}
                        </div>
                      </div>
                      {/*end::Body*/}
                    </div>
                    {/*end::Tables Widget 4*/}
                  </div>
                  {/*end::Tap pane*/}
                  {/*begin::Tap pane*/}
                  <div className="tab-pane fade" id="kt_table_widget_3_tab_2">
                    {/*begin::Tables Widget 4*/}
                    <div className="card card-xl-stretch p-5">
                      {/*begin::Header*/}
                      <div className="d-flex align-items-center justify-content-between flex-wrap border-0 px-2">
                        <div className="card-toolbar order-2 order-sm-0 mob-w-100 d-flex w-100 flex-wrap justify-space-between">
                          <ul className="nav">
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_1"
                              >
                                ROI
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_2"
                              >
                                Pick Win %
                              </a>
                            </li>
                            <li className="nav-item">
                              <a
                                className="nav-link btn buttonMarginPadding btn-light btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1"
                                data-bs-toggle="tab"
                                href="#kt_table_widget_5_tab_3"
                              >
                                Correct Bets
                              </a>
                            </li>
                            <li className="text-muted p-3">* Minimum of 3 points to qualify</li>
                          </ul>
                          <span style={{ right: "11px", marginLeft: "auto" }} className="flex-wrap text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
                            {/* <span>{selectedOption?.event_name?.split(":")[0]}</span>  */}
                            <span style={{ minWidth: 400 }} className="table_input_wrapper_2 text-muted cu-react-select-size-small ms-3">
                              <Select options={optionNew} onChange={handleChange} value={selectedOption} menuPortalTarget={document.body} styles={customStyles} />
                            </span>
                          </span>
                        </div>
                      </div>
                      {/*end::Header*/}
                      {/*begin::Body*/}
                      <div className="card-body py-3 px-2">
                        <div className="tab-content table_wrapper">
                          {/*begin::Tap pane*/}
                          <div className="tab-pane fade show active" id="kt_table_widget_5_tab_1">
                            <GamePlannerEvent selectedOption={selectedOption} />
                          </div>
                          {/*end::Tap pane*/}
                          {/*begin::Tap pane*/}
                          <div className="tab-pane fade" id="kt_table_widget_5_tab_2">
                            <PoundForPoundEvent selectedOption={selectedOption} />
                          </div>

                          <div className="tab-pane fade" id="kt_table_widget_5_tab_3">
                            <CorrectBets selectedOption={selectedOption} />
                          </div>
                          {/*end::Tap pane*/}
                        </div>
                      </div>
                      {/*end::Body*/}
                    </div>
                    {/*end::Tables Widget 4*/}
                  </div>
                  {/*end::Tap pane*/}
                </div>
              </div>
              {/*end::Body*/}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Leaderboard;
