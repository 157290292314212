import * as yup from "yup";

export const initialValues_inviteFrnd = {
  invitationType : 2,
  emails: [{ emailId: "" }],
};

export const validationSchema_inviteFrnd = yup.object().shape({
  emails: yup.array().of(
    yup.object().shape({
      emailId: yup
        .string()
        .required("Required")
        .email("Invalid email"),
    })
  ),
});
