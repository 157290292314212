import React from "react"
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loginData } from "../Store/action/Login.action";
const invitedPageRe = /^\/clublist\/(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\/+/

const PrivateRoute = ({component, ...rest}: any) => {
    const token = localStorage.getItem("token")
    const routeComponent = (props: any) => (
        // (token && rest?._loginData?.isAuthenticated)
        (token)
            ? React.createElement(component, props)
            : <Redirect to={{pathname: '/login', state:props.location.pathname}}/>
            
    );  
    return <Route {...rest} render={routeComponent}/>;
};
const mapState = ({ loginData }: any) => {
    return {
      _loginData: loginData
    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $loginData: (values?: ILogin) => dispatchEvent(loginData(values))
  });
  export default connect(
    mapState,
    mapDispatch
  )(PrivateRoute);