import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import reducers from "./reducer/index.reducer";
import sagas from "./saga/index.saga";
// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const REDUCERS = combineReducers(reducers);

const sagaMiddleware = createSagaMiddleware();

declare global {
    interface Window {
      __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function configureStore(initialState = {}) {
    const STORE : any= createStore(
        REDUCERS,
        initialState,
        composeEnhancers(applyMiddleware(sagaMiddleware)),
    );

    STORE.runSaga = sagaMiddleware.run
    STORE.runSaga(sagas)

    // sagaMiddleware.run(sagas)

    // if (module.hot && process.env.NODE_ENV === "development") {
    //     // Enable Webpack hot module replacement for reducers
    //     module.hot.accept("./reducer/index.reducer", () => {
    //         const nextRootReducer = require("./reducer/index.reducer");
    //         STORE.replaceReducer(nextRootReducer);
    //     });
    // }

    return STORE;
}
