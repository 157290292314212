import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect, getDiffrenceOfTimeInMinutes } from "../../../../../Utils/helperFunctions";
import {
  getUser,
  getAllEv,
  getUniqueBeters,
  getMe,
  getitsMe,
  getUniqueMe,
  getTotalWin,
  stringWithValidLength,
  getResult,
  getLastName,
  getResultNew
} from "../../AllEventCalculation/Calculation";
import LeftRightScroll from "../../LeftRightScroll";
import {
  getParlayHomeBeters,
  getParlayAwayBeters,
  getParlayHomeMe,
  getBlankParlayHtml,
  getParlayAwayMe,
  getParlayWin,
  getParlayTotalRisk,
  getParlayTotalWin
} from "./ParlayCalculation/ParlayCalculation";


const ParlaysEventBetslip = (props?: any) => {
  const { eventParlayTeam, risk, setrisk, parlayevents, setParlayEvents, parlayLock, setTotalWin, isAlertActive,getUniqueParleyBetters} = props;
  const [makeLive, setMakeLive] = useState<any>(null);
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const [timeDifference, setTimeDifference] = useState<any>();
  const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>
  const [mainCard, setMainCard] = useState<any>();
  const [prelimsCard, setprelimsCard] = useState<any>();
  const [earlyPrelimsCard, setEarlyPrelimsCard] = useState<any>()
  const [myData, setMyData] = useState<any>([])

  // useEffect(() => {
  //  setTimeout(()=>{
  //   setMakeLive(Math.random());
  //  },900)
  // }, [makeLive]);
 getUniqueParleyBetters(uniqueBeters)
  useEffect(() => {
    const data = props?.summaryData
    let myDataIs = []
    const myDatas = data && data.find((el:any) => el?.isMe == true)
    myDataIs.push(myDatas)
    setMyData(myDataIs)
  }, [eventParlayTeam])

  const handleOnChange = (e: any) => {
    const { value } = e.target;
    if(value <= 999999){
      if (/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/.test(value) || value === "") {
        setrisk(value);
      }
    }
  };

  const getrisk = () => {
    const unique = uniqueMe && uniqueMe[0];
    const a = eventParlayTeam?.map((item: any) => {
      const x = item?.risks?.find((ob: any) => ob?.riskId === unique?.riskId);
      return x;
    });

    const risk = a?.find((el: any) => el?.risk)?.risk;
    setrisk(risk);
  };

  useEffect(() => {
    const deadline = eventParlayTeam?.slice(-1)[0];
    setTimeDifference(getDiffrenceOfTimeInMinutes(deadline?.date_event));
  }, [eventParlayTeam]);


  useEffect(() => {
    getrisk();
  }, [uniqueMe]);

  useEffect(() => {
    setUsers(getUser(eventParlayTeam));
    setMeData(getMe(eventParlayTeam));
  }, [eventParlayTeam]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);

  useEffect(() => {
    const mainCardFighter = eventParlayTeam && eventParlayTeam?.filter((ele:any) => ele?.event_card === 'Main Card')
    const prelimsFighter = eventParlayTeam && eventParlayTeam?.filter((ele:any) => ele?.event_card === 'Prelims')
    const earlyPrelimsFighter = eventParlayTeam && eventParlayTeam?.filter((ele:any) => ele?.event_card === 'Early Prelims')
    setMainCard(mainCardFighter)
    setprelimsCard(prelimsFighter)
    setEarlyPrelimsCard(earlyPrelimsFighter)
  }, [eventParlayTeam]);

  return (
    <>
    <div ref={slideRef} className="table-responsive h-500">
      <table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        id="kt_subscriptions_table_3"
        role="grid"
      >
        {/*begin::Table head*/}
        <thead className="sticky-top" style={{background:"white"}}>
          {/*begin::Table row*/}
          <tr
            className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
            role="row"
          >
            <th
              className="min-w-250px cu-12 sorting text-start ps-4"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
            >
              Fighters
            </th>
            {/* <th
              className="min-w-65px sorting position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 70, position: "sticky", left: 200, background: "white", zIndex: 1}}
              // aria-sort="ascending"
            >
              Moneyline
            </th>
            <th
              className="min-w-70px position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Billing: activate to sort column ascending"
              style={{ width: 70, position: "sticky", left: 295, background: "white", zIndex: 1 }}
            >
              Result
            </th> */}
            <th
              className="sorting"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
            >
              <Link to = {`/myprofile`}  className="text-muted">
                Me
              </Link>
            </th>
            {uniqueBeters?.length > 0 &&
              uniqueBeters?.map((obj: any, index: any) => (
                <th
                  className="sorting"
                  tabIndex={0}
                  aria-controls="kt_subscriptions_table"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Created Date: activate to sort column ascending"
                  style = {{minWidth:'150px'}}
                >
                  {
                    obj?.screenName 
                    ?
                    <Link to = {`/player/${obj?.screenName}`}  className="text-muted">
                      {obj?.screenName ? stringWithValidLength(obj?.screenName, 15, 15) : "-"}
                    </Link>
                    :
                    obj?.screenName ? stringWithValidLength(obj?.screenName, 15, 15) : "-"
                  }
                </th>
              ))}
          </tr>
          {/*end::Table row*/}
        </thead>
        <tbody className="text-gray-600 fw-bold cu-table-01">
          {/* {console.log("SSSSSSSSS", mainCard)} */}
          {mainCard?.length > 0 ? (
            <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px', position:'sticky', left:0}}>Main Card: {moment(mainCard[0]?.eventCardTime).format("hh:mm a")}</div>
            {mainCard?.map((obj: any, index: any) => (
              <>        
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1 }} className="text-gray-800 text-start ps-4 scroll_sticky_white table-column ">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                      <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                     

                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column ">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column ">{getResult(obj?.result?.winner_away)}</td> */}
                  {/* ME */}
                  {
                    
                    obj.moneyline_away
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, parlayLock, false, obj.moneyline_away) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* AWAY BETERS */}
                  {uniqueBeters?.map((Ob: any) =>{                    
                    return getParlayAwayBeters(Ob, obj, allEv, getResult(obj?.result?.winner_away))
                  }
                    
                  )}
                </tr>
                <tr className="bg-table-row-2">
                  <td 
                  // role={obj?.homeTeamUrl && "button"} 
                  style={{position: "sticky", left: 0, background: "white", zIndex: 1 }} className="table-column text-gray-800 text-start ps-4 scroll_sticky_white">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                  
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                        }

                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_home)}</td> */}
                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      (uniqueMe.map((ob:any, ind:number) => getParlayHomeMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))) 
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, parlayLock, false, obj?.moneyline_home) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* HOME BETERS   */}
                  {uniqueBeters?.map((Ob: any) =>
                    getParlayHomeBeters(Ob, obj, allEv,  getResult(obj?.result?.winner_home))
                  )}
                </tr>
              </>
            ))}</>
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4 table-column">
                Data Not found...
              </td>
            </tr>
          )}

         {/* Prelims */}
          
          {prelimsCard?.length > 0 ? (
            <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px', position:'sticky', left:0}}>Prelims: {moment(prelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
           { prelimsCard.map((obj: any, index: any) => (
              <>    
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1 }} className="text-gray-800 text-start ps-4 scroll_sticky_white table-column ">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                      <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                     

                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column ">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column ">{getResult(obj?.result?.winner_away)}</td> */}
                  {/* ME */}
                  {
                    obj.moneyline_away
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))
                      : 
                      getDiffrenceOfTimeInMinutes(obj?.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, parlayLock, false, obj.moneyline_away) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* AWAY BETERS */}
                  {uniqueBeters?.map((Ob: any) =>{                    
                    return getParlayAwayBeters(Ob, obj, allEv, getResult(obj?.result?.winner_away))
                  }
                    
                  )}
                </tr>
                <tr className="bg-table-row-2">
                  <td 
                  // role={obj?.homeTeamUrl && "button"} 
                  style={{position: "sticky", left: 0, background: "white", zIndex: 1 }} className="table-column text-gray-800 text-start ps-4 scroll_sticky_white">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                  
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                        }

                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_home)}</td> */}
                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      (uniqueMe.map((ob:any, ind:number) => getParlayHomeMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))) 
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, parlayLock, false, obj?.moneyline_home) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* HOME BETERS   */}
                  {uniqueBeters?.map((Ob: any) =>
                    getParlayHomeBeters(Ob, obj, allEv,  getResult(obj?.result?.winner_home))
                  )}
                </tr>
              </>
            ))}</>
          ) : (
            ''
            // <tr className="bg-table-row-transparent">
            //   <td className="text-gray-800 text-start ps-4 table-column">
            //     Data Not found...
            //   </td>
            // </tr>
          )}

          {/* Early Prelims */}
          {earlyPrelimsCard?.length > 0 ? (
            <>
             <div className="badge badge-pill btn-orange" style = {{fontSize:'14px', marginTop:'2px', position:'sticky', left:0}}>Early Prelims: {moment(earlyPrelimsCard[0]?.eventCardTime).format("hh:mm a")}</div>
           { earlyPrelimsCard.map((obj: any, index: any) => (
              <>    
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1 }} className="text-gray-800 text-start ps-4 scroll_sticky_white table-column ">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                      <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                     

                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column ">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column ">{getResult(obj?.result?.winner_away)}</td> */}
                  {/* ME */}
                  {
                    obj.moneyline_away
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      uniqueMe.map((ob: any, ind: number) => getParlayAwayMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 2, parlayevents, setParlayEvents, parlayLock, false, obj.moneyline_away) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* AWAY BETERS */}
                  {uniqueBeters?.map((Ob: any) =>{                    
                    return getParlayAwayBeters(Ob, obj, allEv, getResult(obj?.result?.winner_away))
                  }
                    
                  )}
                </tr>
                <tr className="bg-table-row-2">
                  <td 
                  // role={obj?.homeTeamUrl && "button"} 
                  style={{position: "sticky", left: 0, background: "white", zIndex: 1 }} className="table-column text-gray-800 text-start ps-4 scroll_sticky_white">
                   <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                  
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                        }

                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_second table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_home)}</td> */}
                  {/* ME */}
                  {
                    obj?.moneyline_home 
                    ? 
                    (
                      uniqueMe?.length > 0 
                      ? 
                      (uniqueMe.map((ob:any, ind:number) => getParlayHomeMe(ob,obj,itsMe,parlayevents,setParlayEvents,parlayLock))) 
                      : 
                      getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0 ? getBlankParlayHtml(obj, 1, parlayevents, setParlayEvents, parlayLock, false, obj?.moneyline_home) : <td className="table-column">-</td>
                    ) 
                    :
                    <td className="table-column">-</td>
                  }

                  {/* HOME BETERS   */}
                  {uniqueBeters?.map((Ob: any) =>
                    getParlayHomeBeters(Ob, obj, allEv,  getResult(obj?.result?.winner_home))
                  )}
                </tr>
              </>
            ))}</>
          ) : (
            ''
            // <tr className="bg-table-row-transparent">
            //   <td className="text-gray-800 text-start ps-4 table-column">
            //     Data Not found...
            //   </td>
            // </tr>
          )}
          

          {/* Total Number Row  */}
          {eventParlayTeam?.length > 0 && (
            <tr className="total_number">
              <td style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} className="ps-5 text-start scroll_sticky_black">
                 Parlay Bet Amount and ROI
              </td>
              {/* <td className="scroll_sticky_black_fourth"></td>
              <td className="scroll_sticky_black_second"></td> */}
              <td>
                {myData && myData.map((ele:any) => {
                  return <div className="d-flex align-items-center justify-content-between">
                  <div className={`w-50 ${ele == undefined || ele?.parleyRisk == 0 ? '':ele?.isParleyWin ? "" : "r Lose"}`}>
                    {
                      parlayLock 
                      ? 
                      <div>{!isNaN(risk) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk) : "$ 0.00"}{timeDifference>2 ? <i className="bi bi-lock-fill mx-1"/> : ""}</div>
                       :
                      <span className="d-flex position-relative">
                        {risk ? <span className="m-2 position-absolute inputBoxDollarsign">$</span> : ""}
                        {
                        mainCard?.length > 0 ? 
                        getDiffrenceOfTimeInMinutes(mainCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk||""} onChange={handleOnChange} disabled={parlayLock} /> : <span className="mx-6">$0.00</span>
                        :
                        prelimsCard?.length > 0 ?
                        getDiffrenceOfTimeInMinutes(prelimsCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk||""} onChange={handleOnChange} disabled={parlayLock} /> : <span className="mx-6">$0.00</span>
                        :
                        earlyPrelimsCard?.length > 0 ?
                        getDiffrenceOfTimeInMinutes(earlyPrelimsCard[0]?.eventCardTime) > 0 ? <input type="text" className="form-control form-control-solid ms-1" placeholder=" " name="risk" value={risk||""} onChange={handleOnChange} disabled={parlayLock} /> : <span className="mx-6">$0.00</span>
                        :
                        ''
                        }
                      </span> 
                    }
                  </div>
                  <div className={`w-50 ${ele?.isParleyWin ? "w Win" : ""}`}>
                      {parlayLock ? '$'+ele?.parlayWin :(getParlayWin(parlayevents, risk, setTotalWin)) ? getParlayWin(parlayevents, risk, setTotalWin) : "$ 0.00"} 
                      
                  </div>
                </div>
                  
                 
                })}
               
              </td>
              {uniqueBeters?.length > 0 &&
                uniqueBeters?.map((ob: any, index: any) => {
                  const parlayRisk = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parleyRisk
                  const parlayWinAmount = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parlayWin
                  const isParlayWin = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.isParleyWin
                  return <td key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={`w-50 ${isParlayWin ? " " : "r Lose"} btn-sm p-1`}>
                        {(getParlayTotalRisk(ob, eventParlayTeam)) ? getParlayTotalRisk(ob, eventParlayTeam) : "$ 0.00"}
                      </div>
                      <div className="w-50">
                        {/* {getParlayTotalWin(ob, parlayList)} */}
                        {/* -------getting summary value in parlays total--------- */}
                        <div className={`w-100 ${isParlayWin  ? "w Win" : ""} btn-sm p-1`}>
                          {!isNaN(parlayWinAmount) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(parlayWinAmount) : "$ 0.00"}
                        </div>
                      </div>
                    </div>
                  </td>
                })}
            </tr>
          )}
        </tbody>
        {/*end::Table body*/}
      </table>
    </div>
      <LeftRightScroll slideRef={slideRef}/>
    </>
  );
};
export default ParlaysEventBetslip;

