import { put, call } from "redux-saga/effects";
import { getApi, postApi } from "../api-interface/api-interface";
import { 
    allTimeGamePlannerSuccess, 
    allTimePoundForPoundSuccess, 
    allTimeMakingChampionshipRunSuccess, 
    eventGamePlannerSuccess,
    eventPoundForPoundSuccess,
    eventCorrectBetsSuccess
} from "../action/Leaderboard.action"



export function* onGetAllTimeGamePlanner(payload: any): any {
    try {
        if(payload.values){
            const {quarter,newYear} = payload.values 
        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/alltime/gamePlanner?quarter=${quarter}&newYear=${newYear}`
        )
        if(response?.status === 200) {
            yield put(allTimeGamePlannerSuccess(response.data.results))
        }
    }
    } catch(error: any){}
}

export function* onGetAllTimePoundForPound(payload:any): any {
    try {
        if(payload.values){
            
            const {quarter,newYear} = payload.values
        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/alltime/poundForPound?quarter=${quarter}&newYear=${newYear}`
        )
        if(response?.status === 200) {
            yield put( allTimePoundForPoundSuccess(response.data.results))
        }
    }
    } catch(error: any){}
}

export function* onGetAllTimeMakingChampionshipRun(payload:any): any {
    try {
        if(payload.values){
            const {quarter,newYear} = payload.values
        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/alltime/makingChampionshipRun?quarter=${quarter}&newYear=${newYear}`
        )
        if(response?.status === 200) {
            yield put( allTimeMakingChampionshipRunSuccess(response.data.results))
        }
    }
    } catch(error: any){}
}

export function* onGetEventGamePlanner(payload:any): any {
    try {
        const eventName = payload?.values?.eventName
        const eventDate = payload?.values?.eventDate
        // const {quarter,newYear} = payload.values

        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/event/gamePlanner?eventName=${eventName}&eventDate=${eventDate}`
        )
        if(response?.status === 200) {
            const { results, latestEvent } = response.data
            yield put(eventGamePlannerSuccess({results, latestEvent}))
        }
    } catch(error: any){}
}

export function* onGetEventPoundForPound(payload:any): any {
    try {
        const eventName = payload?.values?.eventName
        const eventDate = payload?.values?.eventDate
        // const {quarter,newYear} = payload.values

        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/event/poundForPound?eventName=${eventName}&eventDate=${eventDate}`
        )
        if(response?.status === 200) {
            const { results, latestEvent } = response.data
            yield put( eventPoundForPoundSuccess({results, latestEvent}))
        }
    } catch(error: any){}
}

export function* onGetEventCorrectBets(payload:any): any {
    try {
        const eventName = payload?.values?.eventName
        const eventDate = payload?.values?.eventDate
        // const {quarter,newYear} = payload.values

        const response: any | {} = yield call(
            getApi,
            `/leaderboardChart/event/correctBets?eventName=${eventName}&eventDate=${eventDate}`
        )
        if(response?.status === 200) {
            const { results, latestEvent } = response.data
            yield put( eventCorrectBetsSuccess({results, latestEvent}))
        }
    } catch(error: any){}
}