
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Spinner from "../../../../Common/Spinner/Spinner";

const PublicProfileBets = (props?: any) => {
  const [Bets, setBets] = useState<any>([]);
  const [Loading, setLoading] = useState<boolean>(false);
  const [totalPages, setToatalPages] = useState<number>(0);
  const [From, setFrom] = useState<number>(4);
  const [To, setTo] = useState<number>(0);
  const Limit = 4;
  const bets = props?.bets;

  const getBetsData = () => {
    setLoading(true);
    setBets([])
  }

  useEffect(() => {
    getBetsData()
  },[]);

  useEffect(() => {
    const bets = props.bets;
    if (bets && Loading) {
        setToatalPages(Math.ceil(bets.length / Limit));
        setBets(bets);
        setLoading(false);
    }
  }, [props.bets]);

  const handlePageClick = (data: any) => {
    const selectedData = data?.selected + 1;
    const from = selectedData * Limit;
    const to = from - Limit;
    setFrom(from);
    setTo(to);
  };
  
  return (
    <div className="card mb-10">
      <div className="card-header">
        <div className="card-title flex-column m-0">
          <h3 className="fw-bolder mb-0">Player Bets</h3>
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {Loading ? (
          <Spinner color="text-black spinner-border-sm " />
        ) : (bets?.length > 0 ) ? (
          props?.bets.slice(To, From).map((obj: any, index: number) => (
            <div className="d-flex align-items-center mb-5" key={index}>
              <div className="fw-bold">
                <span                  
                  className="fs-6 fw-bold text-gray-900 text-hover-dark"
                >
                  {obj?.event_name}
                </span>
              </div>
            </div>
          ))
        ) : (
          <div className="d-flex align-items-center mb-5">
            <div className="fw-bold">Bets not Found..</div>
          </div>
        )}
        {totalPages > 0 && (
          <div className="d-flex justify-content-end">
          <nav aria-label="Page navigation example">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={totalPages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={(data) => handlePageClick(data)}
              containerClassName={"pagination"}
              // subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </nav>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicProfileBets