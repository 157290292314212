import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import DummyUser from '../../../assets/images/avatars/150-1.jpg';
import { allMessages, newMessage, newMessageSuccess } from '../../../Store/action/Chat.action';
import { ImageUrl } from '../../../Utils/BaseUrl';
import Moment from 'react-moment';
import { BaseUrl } from "../../../Utils/BaseUrl";
import { io } from "socket.io-client";
import { addStickyToTHead } from './AllEventCalculation/Calculation';
import { Link } from 'react-router-dom';
const token = localStorage.getItem("token");

const GroupChat = (props:any) => {
    const {_chatMessages} = props
    

    const [ messageList, setMessageList ] = useState<any>([])
    const [ newMsg, setNewMsg ] = useState("")
    const [ socket, setSocket ] = useState<ISocket>({emit:() => {}})
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const scrollChat = useRef<any>(null)
    const scrollUnread = useRef<any>(null)

    useEffect(() => {
        props.$getAllMessages(props.clubId)
        const socket = io(BaseUrl,{query: {token}})
        setSocket(socket)
        socket.on("connect", () => {
            // console.log("socket Connected")
            socket.emit("joinClub", {clubId:props.clubId, userId:props._userId})
        })
        socket.on("welcomeMessage", (messages) => {
            if(messages && messages.length && messages[0] && String(messages[0].clubId) === String(props.clubId)){
                props.$updateReceivedMessage(messages)
            }
        })
        socket.on("getUpdatedMessage", (messages) => {
            if(messages && messages.length && messages[0] && String(messages[0].clubId) === String(props.clubId)){
                props.$updateReceivedMessage(messages)
                setIsLoading(false)
            }
            socket.emit("UserSeenId",{
                message: newMsg,
                clubId: props?.clubId,
                userId: props?._userId
            })
        })
    }, [])

    const submit = () => {
        if(newMsg){
            
          //  console.log("newMessage", newMessage);
            setIsLoading(true)
            socket.emit("newMessage",{
                message: newMsg,
                clubId: props.clubId,
                userId: props._userId
            })
        }
    }
    const handleEnter = (e:any) => {
        if(e.keyCode === 13){
            submit()
        }
    }

    useEffect(() => {
        setMessageList(_chatMessages)
        setNewMsg("")
        // for scrolling chat
        setTimeout(() => {
            scrollChat?.current?.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"})
          }, 20)
          
          if(props?._chatMessages?.slice(-1)[0]?.id > props?._lastSeenMessages){
            setTimeout(() => {
                scrollUnread?.current?.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
              }, 20)
            
        }
           
        
    }, [_chatMessages]);
    return (
        <div className="chat_height flex-lg-row-fluid ms-lg-7 ms-xl-10 mt-15 mt-lg-0" style={{width: "100%"}}> 
        <div className="card shadow-xs h-100" id="kt_chat_messenger"> 
        <div className="card-header" id="kt_chat_messenger_header"> 
            <div className="card-title"> 
            <div className="symbol-group symbol-hover"> 
            {
                props._clubMembers && props._clubMembers.length
                ?
                props._clubMembers.map((member:any, index:number) => {
                   return  index < 3 
                    ?
                        member.profilePic && !member.profilePic.includes("-blob")
                        ?
                        <div className="symbol symbol-35px symbol-circle">
                            <img alt="Pic" src={ImageUrl+ member.profilePic} title={member?.fullName ? member.fullName : ""}/>
                        </div> 
                        :
                        <div className="symbol symbol-35px symbol-circle">
                            <span className="symbol-label bg-light-warning text-warning 40px" title={member?.fullName ? member.fullName : ""}>
                                {member.fullName ? member.fullName[0].toUpperCase() : ""}
                            </span>
                        </div>
                    :
                    ""                    
                })
                :
                ""
            }
            {
                props._clubMembers && props._clubMembers.length > 3
                ?
                <span className="symbol symbol-35px symbol-circle" >
                    <span className="symbol-label fs-8 fw-bolder" >
                        +{props._clubMembers.length - 3}
                    </span>
                </span> 
                :
                ""
            }
            </div> 
            </div>  
            <div className="card-toolbar"> 
            <div className="me-n3">
            { props?.isChatModal ? <span className="btn d-flex p-3 px-7 bd-dark mx-3 bg-light rounded" onClick={() => {props.setChatModal(false); addStickyToTHead();}}>Close</span> : ''}
                {/* <button
                className="btn btn-sm btn-icon btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
                data-kt-menu-flip="top-end"
                >
                <i className="bi bi-three-dots fs-2" />
                </button>  */}
                <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-200px py-3"
                data-kt-menu="true"
                > 
                <div className="menu-item px-3">
                    <div className="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
                    Contacts
                    </div>
                </div> 
                <div className="menu-item px-3">
                    <a
                    href="#!"
                    className="menu-link px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_users_search"
                    >
                    Add Contact
                    </a>
                </div> 
                <div className="menu-item px-3">
                    <a
                    href="#!"
                    className="menu-link flex-stack px-3"
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_invite_friends"
                    >
                    Invite Contacts 
                    </a>
                </div> 
                <div
                    className="menu-item px-3"
                    data-kt-menu-trigger="hover"
                    data-kt-menu-placement="right-start"
                    data-kt-menu-flip="left, center, top"
                >
                    <a href="#!" className="menu-link px-3">
                    <span className="menu-title">Groups</span>
                    <span className="menu-arrow" />
                    </a> 
                    <div className="menu-sub menu-sub-dropdown w-175px py-4"> 
                    <div className="menu-item px-3">
                        <a
                        href="#!"
                        className="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >
                        Create Group
                        </a>
                    </div> 
                    <div className="menu-item px-3">
                        <a
                        href="#!"
                        className="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >
                        Invite Members
                        </a>
                    </div> 
                    <div className="menu-item px-3">
                        <a
                        href="#!"
                        className="menu-link px-3"
                        data-bs-toggle="tooltip"
                        title="Coming soon"
                        >
                        Settings
                        </a>
                    </div> 
                    </div> 
                </div> 
                </div> 
            </div> 
            </div> 
        </div> 
        <div className="card-body w-100" style={{overflowY: 'auto'}} id="kt_chat_messenger_body"> 
            <div
            className="scroll-y me-n5 pe-5 h-600px h-lg-auto"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
            data-kt-scroll-offset="-2px"
            > 
            {
                messageList && messageList.length > 0
                ?
                messageList.map((msg: any, index: number) => (
                    <>
                        {
                        msg.userId === props._userId
                        ?
                        <div className="d-flex justify-content-end mb-10"> 
                            <div className="d-flex flex-column align-items-end"> 
                                <div className="d-flex align-items-center mb-2"> 
                                <Link to={`/player/${props?._clubMembers?.find((element:any)=> element.userId === msg?.userId)?.screenName}`}>
                                    <div className="me-3">
                                        <span className="text-muted fs-7 mb-1"><Moment fromNow>{msg.createdAt}</Moment></span>
                                        <span className="fs-5 fw-bolder text-gray-900 text-hover-primary ms-1" > You </span>
                                    </div> 
                                </Link>
                                    <Link to={`/player/${props?._clubMembers?.find((element:any)=> element.userId===msg?.userId)?.screenName}`}>
                                    <div className="symbol symbol-35px symbol-circle">
                                    {
                                        msg.profilePic && !msg.profilePic.includes("-blob")
                                        ?
                                        <div className="symbol symbol-35px symbol-circle">
                                        <img  alt="Pic" src={ImageUrl+ msg.profilePic} title={msg?.firstName || "" + " " + msg?.lastName || ""}/>
                                        </div> 
                                        :
                                        
                                        <div className="symbol symbol-35px symbol-circle">
                                            <span className="symbol-label bg-light-warning text-warning 40px" title={msg?.firstName || "" + " " + msg?.lastName || ""}>
                                                {msg.firstName && msg.firstName[0].toUpperCase()}
                                            </span>
                                        </div>
                                    }
                                    </div>
                                    </Link>
                                </div>
                                <div className="p-5 rounded bg-light-primary text-dark fw-bold mw-lg-400px text-end" data-kt-element="message-text" >
                                
                                {msg.message}
                                
                                </div> 
                            </div> 
                        </div> 
                        :
                        <>
                        <div className="d-flex justify-content-start mb-10"> 
                            <div className="d-flex flex-column align-items-start"> 
                                <div className="d-flex align-items-center mb-2"> 
                                {
                                    props?._clubMembers?.find((element:any)=> element.userId===msg?.userId)?.screenName
                                    ?
                                    <>
                                        <Link to={`/player/${props?._clubMembers?.find((element:any)=> element.userId===msg?.userId)?.screenName}`}>
                                            <div className="symbol symbol-35px symbol-circle">
                                            {
                                                msg.profilePic && !msg.profilePic.includes("-blob")
                                                ?
                                                <div className="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src={ImageUrl+ msg?.profilePic} title={msg?.firstName || "" + " " + msg?.lastName || ""}/>
                                                </div> 
                                                :
                                                <div className="symbol symbol-35px symbol-circle">
                                                    <span className="symbol-label bg-light-warning text-warning 40px" title={msg?.firstName || "" + " " + msg?.lastName || ""}>
                                                        {msg?.firstName ? msg?.firstName[0].toUpperCase() : ""}
                                                    </span>
                                                </div>
                                            }
                                            </div> 
                                        </Link>
                                        <Link to={`/player/${props?._clubMembers?.find((element:any)=> element.userId === msg?.userId)?.screenName}`}>
                                            <div className="ms-3">
                                                <span className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1" >{msg?.screenName}</span>
                                                <span className="text-muted fs-7 mb-1"><Moment fromNow>{msg?.createdAt}</Moment></span>
                                            </div> 
                                        </Link>
                                    </>
                                    :
                                    <>
                                        <div className="symbol symbol-35px symbol-circle">
                                        {
                                            msg.profilePic && !msg.profilePic.includes("-blob")
                                            ?
                                            <div className="symbol symbol-35px symbol-circle">
                                                <img alt="Pic" src={ImageUrl+ msg?.profilePic} title={msg?.firstName || "" + " " + msg?.lastName || ""}/>
                                            </div> 
                                            :
                                            <div className="symbol symbol-35px symbol-circle">
                                                <span className="symbol-label bg-light-warning text-warning 40px" title={msg?.firstName || "" + " " + msg?.lastName || ""}>
                                                    {msg?.firstName ? msg?.firstName[0].toUpperCase() : ""}
                                                </span>
                                            </div>
                                        }
                                        </div> 
                                        <div className="ms-3">
                                            <a href="#!" className="fs-5 fw-bolder text-gray-900 text-hover-primary me-1" >{msg?.screenName}</a>
                                            <span className="text-muted fs-7 mb-1"><Moment fromNow>{msg?.createdAt}</Moment></span>
                                        </div> 
                                    </>

                                }
                                    
                                </div> 
                                <div className="p-5 rounded bg-light-info text-dark fw-bold mw-lg-400px text-start" data-kt-element="message-text">
                                    {msg?.message}
                                    </div> 
                            </div> 
                        </div>
                        {
                             msg.id === props?._lastSeenMessages && props?._chatMessages?.slice(-1)[0]?.id > props?._lastSeenMessages
                            //msg.id === 374 && messageList?.slice(-1)[0]?.id>374
                            ?
                            <div ref={scrollUnread} style={{display:"flex",justifyContent:"center", marginBottom:"1rem"}}>
                                <span style={{padding: "0.5rem 1rem", borderRadius: "81px", background: "#c0fbff"}}>Unread Messages</span>
                            </div>
                            :
                            ""
                        }
                        </>
                        }
                        
                    </>
                    )
                )
                :
                ""
            }
            <div ref={scrollChat} />
            </div> 
        </div> 
        <div className="card-footer p-4 d-flex" id="kt_chat_messenger_footer"> 
            <input className="form-control form-control-flush mb-3" onKeyDown={handleEnter} data-kt-element="input" placeholder="Type a message" value={newMsg} onChange={(e)=>setNewMsg(e.target.value)} /> 
            <div className="d-flex flex-stack justify-content-end">
                <button className="btn btn-orange fw-bolder" type="button" disabled={isLoading} data-kt-element="send" onClick={submit}>
                    {
                        isLoading 
                        ? 
                        <span className="spinner-border spinner-border-sm" role="status">
                            <span className="sr-only"></span>
                        </span> 
                        :
                        "Send"
                    }
                </button> 
            </div> 
        </div> 
        </div> 
    </div>
    )
}

const mapState = ({ chatReducer, userProfile, clubStandings }: any) => {
    return {
        _chatMessages: chatReducer?.clubChatMessages,
        _lastSeenMessages: chatReducer?.lastSeenMessage,
        _userId: userProfile?.userDataIs.data?.id,
        _clubMembers: clubStandings?.standingsDataIs?.data
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $getAllMessages: (value: any) => dispatchEvent(allMessages(value)),
    $postNewMessage: (value: any) => dispatchEvent(newMessage(value)),
    $updateReceivedMessage: (value: any) => dispatchEvent(newMessageSuccess(value)),
});

export default connect(mapState, mapDispatch)(GroupChat)
