import { useEffect } from 'react';

import {getAuth, getIdToken, signInWithCustomToken} from 'firebase/auth';

import { OwnIDInit } from '@ownid/react';

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import  {firebaseConfig}  from "./firebaseConfig"
import { BrowserRouter as Router } from 'react-router-dom';
import RootRouting from "./Routing/RootRouting";
import {Provider} from 'react-redux';
import makeStore from "./Store/index.store";
import ReactGA from 'react-ga'
const store = makeStore();
declare global {
  interface Window {
    fd?: any;
  }
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const App = () => {
  useEffect(()=>{
    ReactGA.initialize("UA-222718068-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])
  return (
    <Provider store={store}>
      <Router>
      <OwnIDInit config={{ appId: 'bsn5p4rd587d2w', sdk: 'firebase',
                firebaseAuth: {
                    getAuth,
                    getIdToken,
                    signInWithCustomToken
                }}}/>
      <RootRouting />
      </Router>
    </Provider>
  );
}

export default App;
