import React, { useEffect, useRef, useState } from "react";
import Spinner from "../../../../Common/Spinner/Spinner";
import fbImg from "../../../../../src/assets/images/Icons/facebook.svg";
import fbShareImg from "../../../../../src/assets/images/Icons/facebook-share-button.svg";
import twitterImg from "../../../../../src/assets/images/Icons/twitter.svg";
import whatsappImg from "../../../../../src/assets/images/Icons/whatsapp.svg";
import { getApi } from "../../../../Store/api-interface/api-interface";
import Modal from "react-modal";
import { addStickyToTHead, removeStickyToTHead } from "../../Club/AllEventCalculation/Calculation";
// import { date } from "yup";
// import moment from 'moment';
import Select from "react-select/src/Select";
import axios from "axios";
import { BaseUrl } from "../../../../Utils/BaseUrl";
import { useParams } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlays: {
    background: "rgba(0, 0, 0, 0.7)",
  },
};

const MyStats = (props?: any) => {
  const { statsData, myStateLoader, setMyStateLoader, quarter, setQuarter } = props;

  const [noDataFound, setNoDataFound] = useState(false);
  const [loader, setLoader] = useState<boolean>();
  const [modal, setModal] = useState<IShareModal>({ show: false, imgUrl: "", caption: "" });
  const [eventDate, seteventDate] = useState<any>([]);

  const { screenName } = useParams<any>();
  const [active, setActive] = useState(null);

  // let dd = [
  //     { event_quarter: [ '2', '3', '4' ], event_year: '2022' },
  //     { event_quarter: [ '1', '2' ], event_year: '2023' },
  //     { event_quarter: [ '1', '2' ], event_year: '2024' },

  //     { event_quarter: [ '1', '2' ], event_year: '2025' },

  //   ]

  useEffect(() => {
    setMyStateLoader(true);
    const token = localStorage.getItem("token");
    if (token) {
      axios
        .get(`${BaseUrl}/user/getMyStatQuarter?screenName=${screenName}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setTimeout(() => {
            let newData = response.data.data?.event_quarter;
           
            setQuarter({ quarter: 0, newYear: newData[0]?.event_year,screenName:screenName });
            // newData.map((item: any) => {
            //   if (quarter.newYear !== item.event_year) {
            //     setQuarter({ quarter: 0, newYear: quarter.newYear - 1,screenName:screenName });
            //   }
            // });
            seteventDate(response.data.data?.event_quarter);
          }, 10);
          setMyStateLoader(false);
        });
    } else {
      axios.get(`${BaseUrl}/user/getMyStatQuarterPublic?screenName=${screenName}`).then((response) => {
        setTimeout(() => {
            let newData = response.data.data?.event_quarter;
            setQuarter({ quarter: 0, newYear: newData[0].event_year,screenName:screenName });
            // newData.map((item: any) => {
            //   if (quarter.newYear !== item.event_year) {
            //     setQuarter({ quarter: 0, newYear: quarter.newYear - 1 ,screenName:screenName});
            //   }
            // });
          seteventDate(response.data.data?.event_quarter);
        }, 10);
        setMyStateLoader(false);
      });
    }
  }, [screenName]);

  useEffect(() => {
    setTimeout(() => setNoDataFound(true), 10000);
  }, []);
  const handleClick = async (type: string) => {
    setLoader(true);
    await getApi("/user/getUserStatsImage").then((res) => {
      if (res.data.status) {
        setModal({
          show: true,
          imgUrl: res.data.imgUrl,
          caption: "Check out how I'm doing on MMA BetClub! - https://mmabetclub.com/",
        });
        removeStickyToTHead();
      }
      setLoader(false);
    });

    // const fbNavUrl = 'https://www.facebook.com/sharer/sharer.php?display=popup&u=' + url + "&quote=" +`\n Check out how I'm doing on MMA BetClub!`
    // const TwNavUrl = 'https://twitter.com/intent/tweet?text=' + url + `\n Check out how I'm doing on MMA BetClub!`
    // const WpNavUrl = "whatsapp://send?text=Check out how I'm doing on MMA BetClub! " + url
    // window.open( type === "fb" ? fbNavUrl : (type==="tw" ? TwNavUrl : WpNavUrl), '_blank');
  };

  const postToFB = () => {
    if (modal?.imgUrl) {
      const fbNavUrl = "https://www.facebook.com/sharer/sharer.php?display=popup&u=" + modal.imgUrl + "&quote=" + `\n ${modal.caption}`;
      window.open(fbNavUrl, "_blank");
      setModal({ show: false, imgUrl: "", caption: "" });
    }
  };

  const closeModal = () => {
    setModal({ show: false, imgUrl: "", caption: "" });
    addStickyToTHead();
  };
  // console.log(quarter.newYear,"sdfsdfsss11df");

  return (
    <div className="card mb-10 dark-card-bg">
      <div className="card-header dark-card-bg w-100" style={{ paddingRight: "0" }}>
        <div className="card-title w-100 flex-column m-0">
          <h3 className="fw-bolder w-100 mb-0 flex-row row">
            <span className="d-flex align-items-center justify-content-between text-orange">
              {props?.isPublicProfile ? "Player Stats" : "My Stats"}
              {loader ? (
                <>
                  <span>
                    <div className="m-1 spinner-border-sm spinner-border text-white" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    {/* <div className="m-1 spinner-border-sm spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    <div className="m-1 spinner-border-sm spinner-border text-success" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> */}
                  </span>
                </>
              ) : props?.isPublicProfile ? (
                <div className="dropdown">
                  <button type="button" className="btn  dropdown-toggle btn-quater" data-toggle="dropdown">
                    {quarter && quarter.quarter >= 1
                      ? `Q${quarter.quarter} ${quarter.newYear}`
                      : quarter.newYear >= 2022
                      ? `${quarter.newYear} ${quarter?.newYear == 2023 ? "(YTD)" : "(All)"}`
                      : "All Time"}
                  </button>
                  <div className="dropdown-menu custm_drop custm_scrollbar">
                    <div
                      className="dropdown-item"
                      style={{ fontWeight: 600 }}
                      onClick={() => {
                        setQuarter({ quarter: 0, newYear: 0, screenName });
                        setMyStateLoader(true);
                      }}
                    >
                      All Time
                    </div>
                    {eventDate?.map((el: any) => {
                      return (
                        <>
                          <div
                            className={`dropdown-item dd ${active == el && "active"}`}
                            style={{ fontWeight: "bold" }}
                            onClick={() => {
                              setQuarter({ quarter: 0, newYear: el?.event_year, screenName });
                              setActive(el);
                              setMyStateLoader(true);
                            }}
                          >
                            {el?.event_year} {el?.event_year == 2023 ? "(YTD)" : "(All)"}
                          </div>

                          {el?.event_quarter?.map((x: any) => {
                            return (
                              <>
                                <div
                                  className={`dropdown-item ${active == x && "active"}`}
                                  onClick={() => {
                                    setQuarter({ quarter: x, newYear: el?.event_year, screenName });
                                    setMyStateLoader(true);
                                    setActive(x);
                                  }}
                                >
                                  Q{x} {el?.event_year}
                                </div>
                              </>
                            );
                          })}
                        </>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <>
                  <div className="dropdown">
                    <button type="button" className="btn  dropdown-toggle btn-quater" data-toggle="dropdown">
                      {quarter && quarter.quarter >= 1
                        ? `Q${quarter.quarter} ${quarter.newYear}`
                        : quarter.newYear >= 2022
                        ? `${quarter.newYear} ${quarter?.newYear == 2023 ? "(YTD)" : "(All)"}`
                        : "All Time"}
                    </button>
                    <div className="dropdown-menu custm_drop custm_scrollbar">
                      <div
                        className="dropdown-item"
                        style={{ fontWeight: 600 }}
                        onClick={() => {
                          setQuarter({ quarter: 0, newYear: 0 });
                          setMyStateLoader(true);
                        }}
                      >
                        All Time
                      </div>
                      {eventDate?.map((el: any) => {
                        return (
                          <>
                            <div
                              className={`dropdown-item dd ${active == el && "active"}`}
                              style={{ fontWeight: "bold" }}
                              onClick={() => {
                                setQuarter({ quarter: 0, newYear: el?.event_year });
                                setActive(el);
                                setMyStateLoader(true);
                              }}
                            >
                              {el?.event_year} {el?.event_year == 2023 ? "(YTD)" : "(All)"}
                            </div>

                            {el?.event_quarter?.map((x: any) => {
                              return (
                                <>
                                  {" "}
                                  <div
                                    className={`dropdown-item ${active == x && "active"}`}
                                    onClick={() => {
                                      setQuarter({ quarter: x, newYear: el?.event_year });
                                      setMyStateLoader(true);
                                      setActive(x);
                                    }}
                                  >
                                    Q{x} {el?.event_year}
                                  </div>
                                </>
                              );
                            })}
                          </>
                        );
                      })}
                    </div>
                  </div>

                  <img className="mx-1" width="90" onClick={() => handleClick("fb")} src={fbShareImg} />
                  {/* <span className="">
                                        Share to Facebook
                                        <img className="mx-1" onClick={() => handleClick("fb")} src={fbImg} /> 
                                        <img className="mx-1" onClick={() => handleClick("tw")} src={twitterImg} width = "25px" />
                                        <img className="mx-1" onClick={() => handleClick("wa")} src={whatsappImg} width = "30px" /> 
                                    </span>*/}
                </>
              )}
            </span>
          </h3>
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {myStateLoader ? (
          <Spinner color="text-black spinner-border-sm " />
        ) : (
          <React.Fragment>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">Weight Class</span>
              </div>
              <div className="ms-auto text-white">{statsData?.weightClass}</div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">Total Points</span>
              </div>
              <div className="badge badge-light ms-auto">{statsData?.totalPoints ? statsData?.totalPoints : "-"}</div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">Global Ranking</span>
              </div>
              <div className="badge badge-light ms-auto">{statsData?.global_ranking ? statsData?.global_ranking : "-"}</div>
            </div>
            {quarter && quarter.quarter < 1 && quarter.newYear < 2000 ? (
              <div className="d-flex align-items-center mb-5">
                <div className="fw-bold">
                  <span className="fs-6 fw-bold text-white text-hover-muted">Change since last event</span>
                </div>
                <div
                  className={`badge 
                                    ${
                                      statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0
                                        ? "badge-light"
                                        : statsData?.change_since_last_event > 0
                                        ? "badge-success"
                                        : "badge-orange"
                                    }
                                     ms-auto`}
                >
                  {/* {statsData?.change_since_last_event ? (statsData?.change_since_last_event > 0 ? "+ " : "") + statsData?.change_since_last_event : '-'} */}
                  {statsData?.change_since_last_event === "-" || statsData?.change_since_last_event === 0
                    ? statsData?.change_since_last_event
                    : statsData?.change_since_last_event > 0
                    ? "+" + statsData?.change_since_last_event
                    : statsData?.change_since_last_event}
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">Bet Win %</span>
              </div>
              <div className="badge badge-light ms-auto">{statsData?.betWinPercent !== "-" ? statsData?.betWinPercent ? statsData?.betWinPercent + "%" : '-' : "-"}</div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">Parlay Win %</span>
              </div>
              <div className="badge badge-light ms-auto">{statsData?.parleyWinPercent !== "-" ? statsData?.parleyWinPercent ? statsData?.parleyWinPercent + "%" : '-'  : "-"}</div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className={`fs-6 fw-bold text-white text-hover-muted`}>Margin</span>
              </div>
              <div className={`badge ms-auto  ${!isNaN(statsData?.margin) ? (statsData?.margin > 0 ? "badge-success" : statsData?.margin < 0 ? "badge-orange" : "badge-light") : "badge-light"}`}>
                {!isNaN(statsData?.margin) ? new Intl.NumberFormat(undefined, { style: "currency", currency: "USD" }).format(statsData?.margin) : "-"}
              </div>
            </div>
            <div className="d-flex align-items-center mb-5">
              <div className="fw-bold">
                <span className="fs-6 fw-bold text-white text-hover-muted">ROI</span>
              </div>
              {parseFloat(statsData?.ROI) ? (
                <div className={`badge ${parseFloat(statsData?.ROI) >= 0 ? `badge-success` : `badge-orange`} ms-auto`}>
                  {parseFloat(statsData?.ROI) >= 0 ? parseFloat(statsData?.ROI)?.toFixed(2) + "%" : parseFloat(statsData?.ROI)?.toFixed(2) + "%"}
                </div>
              ) : (
                <div className={`badge badge-light ms-auto`}>{statsData?.ROI}</div>
              )}
            </div>
          </React.Fragment>
        )}
      </div>

      <Modal isOpen={modal.show} onRequestClose={closeModal} style={customStyles}>
        <div className="d-flex flex-column align-items-center shareModalWidth">
          <h2 className="d-flex align-items-center">Share to Facebook</h2>
          <div>
            <img className="shareModalWidth m-2" src={modal.imgUrl} />
          </div>
          <h5 className="m-2 w-100">Your Caption</h5>
          <input className="w-100 m-2 p-2" type="text" value={modal.caption} onChange={(e) => setModal({ ...modal, caption: e.target.value })} />
          <button className="w-100 m-2 rounded shadow h5 p-2 fbButton" onClick={postToFB}>
            Share to Facebook
          </button>
        </div>
      </Modal>
    </div>
  );
};
export default MyStats;
