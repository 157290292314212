import { put, call } from "redux-saga/effects";
import { getApi, postApi } from "../api-interface/api-interface";
import { allMessagesSuccess, newMessageSuccess } from "../action/Chat.action"


export function* onGetAllMessages(payload:any): any {
    try {
        const response: any | {} = yield call(
            getApi, `/chat/getAllMessages?clubId=${payload.values}`,
        )
        if(response?.status === 200) {
            yield put(allMessagesSuccess({messages: response.data.messages, lastSeenMessage: response?.data?.getLastSeenMessage}))
        }
    } catch(error: any){}
}

export function* onPostNewMessage(payload:any): any {
    const { message, clubId, userId } = payload.values
    try {
        const response: any | {} = yield call(
            postApi,
            `/chat/newMessage?userId=${userId}&clubId=${clubId}&message=${message}`
        )
        if(response?.status === 200) {
            const newMessage = response.data.message
            yield put( newMessageSuccess(newMessage))
        }
    } catch(error: any){}
}

