import React, { useEffect } from "react";
import Header from "../../Common/Header";
import "./ToParticipate.css";
// import a1img from "./a1-Story.png";
import a1img from "./a2.jpg";
import { Link, useHistory } from "react-router-dom";
const isAuthenticated = localStorage.getItem("token");


const ToParticipate = () => {
 
  return (
    <>
      <section>
        {/* Header Component */}
        <Header options="fill_Header" />

        {/* About Us */}
        <div className="main-content">
          <div className="container-fliud">
            <div className="col-lg-12">
              <div className="card card-xl-stretch">
                <img src={a1img} alt="" className="img-fluid mmabetimage" srcset="" />
                <div className="main-cntnt">
                  <h1>WANT A CHANCE AT WINNING $100?</h1>
                  
                  <h2 className="mt-8">
                    To Participate For Your Chance To Win $100:
                  </h2>
                  <p>Step 1: Sign Up</p>
                  <p>
                    Step 2: Lock In Your Bets/Parlay (Not Real Money) Before UFC
                    282 Starts
                  </p>
                  <p>
                    Step 3: Invite Your Friends To Prove That You’re The REAL
                    Expert
                  </p>
                  <p> Step 4: Follow Us On Social Media</p>

                  <div className="text-center">
                    {isAuthenticated ? 
                     ''
                     : 
                     <Link
                     class="btn btn-red btn-lg px-20 mt-5 fw-bolder fs-2"
                     to="/registration"
                   >
                     Sign Up
                   </Link> }
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ToParticipate;
