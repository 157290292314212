import React, { useEffect, useState } from "react";
import moment from "moment";
import Header from "../../../Common/Header";
import Spinner from "../../../Common/Spinner/Spinner";
import { getApi } from "../../../Store/api-interface/api-interface";
import ReactPaginate from "react-paginate";
import ReactDOM from "react-dom";

const News = () => {
  const [newsList, setNewsList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage=12

  useEffect(() => {
    setLoader(true);

        getApi("/rss/getAllNews").then((res) => {
            if (res?.data?.status) {
                
                setNewsList(res.data?.allNews);
                setLoader(false); 
                
            }
        });
    
  }, []);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(newsList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(newsList.length / itemsPerPage));
  }, [itemOffset,newsList]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % newsList.length;
    setItemOffset(newOffset);
  };

  return (
      
    <React.Fragment>
        <section style={{marginBottom: "3rem"}}>
        <Header options="fill_Header" />
        {/* News  */}
        <div className="main-content">
          <div className="news_feeds_banner">
            <h3 className="fs-4hx text-white">News</h3>
          </div>
          <div className="container py-15 ">
            <div className="row justify-content-center">
              {loader ? (
                <Spinner color="text-black spinner-border-sm " />
              ) : (
                currentItems.map((news: any) => (
                  <div
                    className="newsCard shadow card m-4 p-1"
                    style={{ width: "24rem" }}
                  >
                    <a
                      href={news.link}
                      target="_blank"
                      style={{ textDecoration: "none" }}
                    >
                      <img
                        className="card-img-top"
                        src={news.content}
                        alt={news.title}
                      />
                      <div className="card-body p-6">
                        <p className="card-text text-dark h6">{news.title}</p>
                        <div className="text-dark">
                          {news?.published}
                        </div>
                      </div>
                    </a>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        <ReactPaginate
        nextLabel="Next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< Previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        //marginPagesDisplayed={0}
      />
      </section>
    
     
     
    </React.Fragment>
  );
};

export default News;
