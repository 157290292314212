import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig, signInWithFacebook, signInWithGoogle } from "../../../firebaseConfig"
import { Link, useHistory } from "react-router-dom";
import Layout from "../../../Common/Layout";
import { Formik, ErrorMessage, Form, Field } from "formik";
import { connect } from "react-redux";
import queryString from 'query-string'
import { loginData } from "../../../Store/action/Login.action";
import { userProfile } from "../../../Store/action/UserInfo.action";
import { initialValues_signIn, validationSchema_signIn } from "./Validation";
import { useEffect, useRef, useState } from "react";
import { ButtonSpinner } from "../../../Common/Spinner/Spinner";
import Alert from "../../../Common/Alert/Alert";
import FormError from "../../../Common/FormError/FormError";
import ReactGA from 'react-ga'
import "./login.css"

import { signInWithEmailAndPassword, getAuth } from 'firebase/auth';
import { OwnID } from "@ownid/react";
import axios from "axios";
import { BaseUrl } from "../../../Utils/BaseUrl";


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const Login = (props?: any) => {

  var validator = require("email-validator");
  const { $loginData, $userProfile, _loginData, _userProfile }: ILoginProps =
    props;
  const history = useHistory();
  // const invitedEmail = props.location.state?.split("/")[2];
  // const invitedcode = props.location.state?.split("/")[3];
  // const checkEmailIs = validator.validate(invitedEmail);
  // const user = { email: checkEmailIs ? invitedEmail : "", password: "" };
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });
  const [user, setUser] = useState({ email: "", password: "", invitedEmail: "", invitedcode: "" })

  const qs = queryString.parse(props.location.search);

  useEffect(() => {
    if (qs?.email && qs?.clubCode) {
      localStorage.removeItem("token")
      const checkEmailIs = validator.validate(qs?.email);
      setUser({
        email: checkEmailIs ? qs.email : "",
        password: "",
        invitedEmail: qs.email,
        invitedcode: qs.clubCode
      })
    }
  }, [])




  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  const getUserProfile = () => {
    $userProfile();
  };

  const getUserResponse = (accessToken: string) => {
    localStorage.setItem("token", accessToken);
    accessToken && getUserProfile();
  };

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        alert.statusIs === "alert-danger" && localStorage.removeItem("token");
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  useEffect(() => {
    const userData = _loginData?.loginDataIs;
    const userDataErr = _loginData?.loginDataErrIs;
    if (userData?.status === 1 && LoadingIs) {
      getUserResponse(userData?.data?.accessToken);
    } else if (userDataErr?.status === 0 && LoadingIs) {
      getAlert(userDataErr?.message, "alert-danger");
      setLoader(false);
    }
  }, [_loginData]);

  const getUserProfileRes = (clubCreatedIs?: boolean) => {
    if (user?.invitedEmail && user?.invitedcode) {
      history.push(`/clublist/${user?.invitedEmail}/${user?.invitedcode}`);
    } else if (qs?.r && qs?.r?.length) {
      history.push(qs.r)
    } else if (clubCreatedIs) {
      history.push({
        pathname: "/myprofile",
        state: {
          signUpIs: false,
        },
      });
    } else {
      history.push({
        pathname: "/createyourclub",
        state: {
          signUpIs: false,

        },
      });
    }
    setLoader(false);
  };

  const getUserProfileErr = () => {
    history.push({
      pathname: "/login",
    });
    localStorage.removeItem("token");
    setLoader(false);
  };

  useEffect(() => {
    const userProfile = _userProfile.userDataIs;
    const userProfileErr = _userProfile.userDataErrIs;
    if (userProfile.status === 1 && LoadingIs) {
      getUserProfileRes(userProfile?.data?.hasClub);
    } else if (userProfileErr && LoadingIs) {
      getUserProfileErr();
    }
  }, [_userProfile]);

  const setEmail = () => {
    history?.push({
      pathname: "/registration",
      state: props.location.state,
    });
  };

  const handleShowPwd = () => {
    setShowPwd(!showPwdIs);
  };

  const onSubmit = (values: ILogin) => {

    setLoader(true);
    // console.log({ email: emailField?.current?.value, password: passwordField?.current?.value });
    $loginData(values);
  }

  const userProfile = _userProfile?.userDataIs?.data?.screenName;
  // console.log(userProf ile, "asdfasdfasdfasdfas");

  const googleLogin = async () => {
    // console.log("we are in the function");

    const response = await signInWithGoogle();
    // console.log(response,"responseeeeeeeeeeeeee");

    if (response?.signUp == 'social-login') {
      localStorage.setItem("token", response?.data?.accessToken); history.push({
        pathname: "/personalsettings",
        state: {
          response: response.signUp,
        },
      })
    } else if (userProfile == "") {
      //  console.log(userProfile, "we are in the userProfile");

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/personalsettings",
      })
    } else if (response?.data?.accessToken) {
      //  console.log(userProfile, "we are in the create your club");

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/createyourclub",
      })
    }
  }

  const faceBookLogin = async () => {
    // console.log("we are in the function");

    const response = await signInWithFacebook();
    console.log(response)
    // console.log(response, "responseeeeeeeeeeeeee");

    if (response?.signUp == 'social-login') {
      localStorage.setItem("token", response?.data?.accessToken); history.push({
        pathname: "/personalsettings",
        state: {
          response: response.signUp,
        },
      })
    } else if (userProfile == "") {
      //  console.log(userProfile, "we are in the userProfile");

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/personalsettings",
      })
    } else if (response?.data?.accessToken) {
      // console.log(userProfile, "we are in the create your club");

      localStorage.setItem("token", response?.data?.accessToken);
      history.push({
        pathname: "/createyourclub",
      })
    }
  }

  //  ---------------biometric--------------------
  const emailField = useRef<any>(null);
  const passwordField = useRef<any>(null);

  function onLogin(data: any) {

    if (data) {
      axios
        .post(`${BaseUrl}/firebase/verify-login-token`, { email: emailField.current.value }, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }).then((data) => {
          if (data?.data) {

            let token = data?.data
            
            if (token?.data?.accessToken) {

              localStorage.setItem("token", token?.data?.accessToken);
              history.push({
                pathname: "/createyourclub",
              })
            }
            // return token; 
          }
        })
    }

  }

  return (
    <Layout options="fill_Header">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              {/* {console.log(emailField.current, 'emailField.current')} */}
              <div className="w-lg-500px dark-card-bg rounded shadow-sm p-10 p-lg-15 mx-auto">
                <div className="text-center mb-5">
                  <h1 className="text-white mb-4 text-capitalize">
                    Welcome to MMA BetClub. 
                  </h1>
                  <h4 className="text-white mb-4">
                  Let's get back in the cage..
                  </h4>




                </div>
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={user.email ? user : initialValues_signIn}
                  validationSchema={validationSchema_signIn}
                  onSubmit={onSubmit}
                  validateOnMount
                  enableReinitialize

                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      <div className="fv-row mb-10">
                        {/* <label className="form-label fs-6 fw-bolder text-white">
                          Email
                        </label> */}
                        <input
                          className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.email &&
                            formik.errors.email &&
                            `border border-danger modern-login-form-input`
                            }`}
                          type="email"
                          name="email"
                          id="email"
                          value={formik.values.email}
                          ref={emailField}
                          onChange={formik.handleChange}
                          autoComplete="off"
                          placeholder="Email"
                          disabled={
                            user.email && user.email !== ":" ? true : false
                          }
                        />

                        <ErrorMessage
                          name="email"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />
                      </div>
                      <div className="fv-row mb-10">
                        {/* <div className="d-flex flex-stack mb-2">
                          <label className="form-label fw-bolder text-white fs-6 mb-0">
                            Password
                          </label>

                        </div> */}
                        <div className="position-relative mb-3">
                          <input
                            className={`modern-login-form-input form-control form-control-lg form-control-solid ${formik.touched.password &&
                              formik.errors.password &&
                              `border border-danger`
                              }`}
                            type={showPwdIs ? `text` : `password`}
                            placeholder="Password"
                            name="password"
                            id="password"
                            value={formik.values.password}
                            ref={passwordField}
                            autoComplete="off"
                            onChange={(e: any) =>
                              e.target.value.length < 41
                                ? formik.handleChange(e)
                                : null
                            }
                          />

                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0"
                            data-kt-password-meter-control="visibility"
                            onClick={handleShowPwd}
                            // style={{marginRight:'5rem'}}
                          >
                            <i
                              className={`bi ${showPwdIs ? `bi-eye-slash` : `bi-eye`
                                } fs-2`}
                            />
                          </span>

                        </div>
                        <ErrorMessage
                          name="password"
                          render={(msg) => (
                            <FormError message={msg}></FormError>
                          )}
                        />

                      </div>
                      <div className='col-sm-12 position-relative'>
                      {/* <OwnID type='login'
                        options={{ variant: 'button-fingerprint', infoTooltip: true }}
                        passwordField={passwordField}
                        loginIdField={emailField}
                        onError={(error) => console.error(error)}
                        onLogin={onLogin} /> */}
                        </div>
                        <div className="text-center mb-4">
                      <span className="text-white">OR</span>
                    </div>
                        <div className="social-login mb-4 d-sm-flex d-block justify-content-center">
                    <a className="btn btn-md btn-social btn-google me-2 mb-3 w-100" onClick={googleLogin}>
                      <img src="https://cdn-icons-png.flaticon.com/512/2991/2991148.png" style={{ width: "20px" }} /> Sign in with Google
                    </a>


                    <a className="btn btn-md btn-social btn-facebook me-2 mb-3 w-100" onClick={faceBookLogin}>
                      <i className="bi bi-facebook"></i> Sign in with Facebook
                    </a>

                  </div>

                      <div className="text-center">
                        <button
                          disabled={!formik.isValid || alert.activeIs}
                          type="submit"
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label fw-bolder">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Continue`
                            )}
                          </span>
                        </button>
                      </div>

                      <div className="fv-row mb-10">
                        <div className="text-muted text-center">
                          By signing up, you agree with our policies.
                        </div>
                      </div>
                      
                      <div className="fv-row text-center">
                        <label className="form-label fs-6 fw-bolder text-white">
                          New to MMA BetClub?
                        </label>

                        {
                          Object.keys(qs)?.length && qs?.email
                            ?
                            <span
                              className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                              onClick={() => history.push(`/registration?clubCode=${qs.clubCode}&inviteCode=${qs.inviteCode}&email=${qs.email}`)}
                            >
                              <span className="indicator-label fw-bolder">
                                Create An Account
                              </span>
                            </span>
                         
                          :
                          Object.keys(qs)?.length && qs?.r
                          ?
                          <span
                            className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                            onClick={() => {
                              history.push(`/registration?r=${qs.r}`)
                              ReactGA.pageview('/registration')
                            }}
                          >
                            <span className="indicator-label fw-bolder">
                              Create An Account
                            </span>
                          </span>
                          :
                          // <span
                          //   className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                          //   onClick={() => {
                          //     history.push(`/registration`)
                          //     ReactGA.pageview('/registration')
                          //   }}
                          // >
                          //   <span className="indicator-label fw-bolder">
                          //     Create An Account
                          //   </span>
                          // </span>
                          //   :
                            Object.keys(qs)?.length && qs?.r
                              ?
                              <span
                                className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                                onClick={() => history.push(`/registration?r=${qs.r}`)}
                              >
                                <span className="indicator-label fw-bolder">
                                  Create An Account
                                </span>
                              </span>
                              :
                              <span
                                className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5"
                                onClick={() => history.push(`/registration`)}
                              >
                                <span className="indicator-label fw-bolder">
                                  Create An Account
                                </span>
                              </span>
                        }
                      </div>
                      <div className="text-center">
                        <Link
                            to="/resetpassword"
                            className="link-primary fs-6 fw-bolder"
                          >
                            Forgot Password
                          </Link>
                        </div>
                    </Form>
                  )}
                </Formik>

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ loginData, userProfile }: any) => {
  return {
    _loginData: loginData,
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $loginData: (values?: ILogin) => dispatchEvent(loginData(values)),
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(Login);
