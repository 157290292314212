import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { allTimeGamePlanner } from "../../../../../Store/action/Leaderboard.action";
import Spinner from "../../../../../Common/Spinner/Spinner"
import { getSorting, stringNameLength } from "../../../Club/AllEventCalculation/Calculation";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import LeftRightScroll from "../../../Club/LeftRightScroll";

const GamePlanner = (props?: any) => {
    const{quarter} = props
    const [data, setData] = useState([])
    const [activeSortName , setActiveSort] = useState<string>("averageROI")
    const [sortingIs , setSorting] = useState<boolean>(false)
    const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>

    useEffect(() =>{
        setData([])
        const data = quarter
        
        props?.$allTimeGamePlanner(data)
    },[quarter])

    useEffect(() =>{
        if(props._allTimeGamePlanner && props._allTimeGamePlanner.length){
            setData(props._allTimeGamePlanner)
        }
    }, [props._allTimeGamePlanner])

    return (
        <>
        <div ref={slideRef} className="table-responsive h-500">
        <table
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            id="kt_subscriptions_table_one"
            role="grid"
        >
            {/*begin::Table head*/}
            <thead className="sticky-top" style={{background:"white"}}>
            {/*begin::Table row*/}
            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-1" role="row" > 
                <th 
                    className={`textLeft min-w-150px  sorting  ${activeSortName === "fullName" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
                    onClick={() => getSorting("fullName", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Player Name
                </th>
                
                <th 
                    className={`sorting ${activeSortName === "averageROI" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("averageROI", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    ROI %
                </th>
                <th 
                    className={`sorting ${activeSortName === "totalMargin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("totalMargin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Margin
                </th>
                <th 
                    className={`sorting ${activeSortName === "totalPoints" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("totalPoints", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Point Total
                </th>
                <th 
                    className={`sorting ${activeSortName === "totalBetWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("totalBetWinPercentage", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Bet Win %
                </th>
                <th 
                    className={`sorting ${activeSortName === "totalParleyWinPercentage" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("totalParleyWinPercentage", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Parlay win %
                </th>
                <th 
                    className={`sorting ${activeSortName === "lastEventBetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("lastEventBetWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Last Event win %
                </th>
                <th 
                    className={`min-w-100px sorting ${activeSortName === "lastEventParlayWin" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    // onClick={() => getSorting("lastEventParlayWin", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Last Event Parlay Win
                </th>
                <th 
                    className={`min-w-100px sorting ${activeSortName === "last5BetWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("last5BetWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Last 5 Events Win %
                </th>
                <th 
                    className={`min-w-100px sorting ${activeSortName === "last5ParleyWinPercent" && (sortingIs ? "sorting_asc" : "sorting_desc")}`} 
                    onClick={() => getSorting("last5ParleyWinPercent", data, setData, sortingIs, setSorting, activeSortName, setActiveSort)}
                >
                    Last 5 Events Parlay %
                </th>
            </tr>
            {/*end::Table row*/}
            </thead>
            {/*end::Table head*/}
            {/*begin::Table body*/}
            {
                data && data.length > 0
                ?
                data.map((obj: any, index: number) => (
                    <tbody className="text-gray-600 fw-bold" key={obj.id}>
                        <tr className="bg-table-row-1">  
                            <td className="text-gray-800 text-start d-flex " style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                                {
                                    obj.screenName
                                    ?
                                    <> 
                                    <span className="me-3">{index + 1}. </span>
                                    <Link to={`/player/${obj.screenName}`} className="text-dark">
                                        <div className="row">
                                            { 
                                                obj?.profilePic && !obj.profilePic.includes("-blob")
                                                ?
                                                <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                    <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.screenName ? obj.screenName : ""}/>
                                                </div> 
                                                :
                                                <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                    <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.screenName ? obj.screenName : ""}>
                                                        {obj?.screenName ? obj.screenName[0].toUpperCase() : ""}
                                                    </span>
                                                </div>
                                            }
                                            <div className="col-9">
                                                {obj.screenName}
                                                {
                                                    obj?.status 
                                                    ? 
                                                    <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                        {stringNameLength(obj?.status, "", 25, 25)}
                                                    </span> 
                                                    :
                                                    "" 
                                                }
                                                <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                            </div>
                                        </div>
                                    </Link>
                                    </>
                                    :
                                    <> 
                                    <span className="me-3">{index + 1}. </span>
                                    <div className="row">
                                        { 
                                            obj?.profilePic && !obj.profilePic.includes("-blob")
                                            ?
                                            <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                <img alt="Pic" src={ImageUrl+ obj?.profilePic} title={obj?.screenName ? obj.screenName : ""}/>
                                            </div> 
                                            :
                                            <div className="col-2 symbol symbol-35px symbol-circle me-3">
                                                <span className="symbol-label bg-light-warning text-warning 40px" title={obj?.fullName ? obj.fullName : ""}>
                                                    {obj?.screenName ? obj.screenName[0].toUpperCase() : ""}
                                                </span>
                                            </div>
                                        }
                                        <div className="col-9">
                                            {obj.screenName}
                                            {
                                                obj?.status 
                                                ? 
                                                <span className="text-muted fw-bold d-block fs-8" data-toggle="tooltip" data-placement="bottom" title={obj?.status?.length > 25 ? obj?.status : ""}>
                                                    {stringNameLength(obj?.status, "", 25, 25)}
                                                </span> 
                                                :
                                                "" 
                                            }
                                            <span className="text-muted fw-bold d-block fs-8">{obj.weightClass}</span>
                                        </div>
                                    </div>
                                    </>
                                }
                            </td>
                            <td>{obj.averageROI + (!isNaN(obj.averageROI) ? "%" : "")}</td>
                            <td>{ !isNaN(obj.totalMargin) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(obj.totalMargin)) : obj.totalMargin}</td>
                            <td>{obj.totalPoints}</td> 
                            <td>{obj.totalBetWinPercentage + "%"}</td>
                            <td>{obj.totalParleyWinPercentage + "%"}</td> 
                            <td>{obj.lastEventBetWinPercent + "%"}</td> 
                            <td>{obj.lastEventParlayWin === "false" ? "No": obj.lastEventParlayWin === "true" ? "Yes" : obj.lastEventParlayWin}</td> 
                            <td>{obj.last5BetWinPercent}%</td>
                            <td>{obj.last5ParleyWinPercent==="-" ? "-" : obj.last5ParleyWinPercent+"%"}</td>
                        </tr>  
                    </tbody>
                )) 
                :
                <Spinner />
                
            }            
            {/*end::Table body*/}
        </table>
    </div> 
    <LeftRightScroll slideRef={slideRef}/>
    </>
    )
}

// const mapState = ({ leaderboardReducer: {allTimeGamePlanner} }: any) => {
//     return { allTimeGamePlanner };
// };
const mapState = ({ leaderboardReducer }: any) => {
    return {
        _allTimeGamePlanner: leaderboardReducer.allTimeGamePlanner
    };
};

const mapDispatch = (dispatchEvent?: any) => ({
    $allTimeGamePlanner: (values?:IQuarter) => dispatchEvent(allTimeGamePlanner(values)),
});

export default connect(mapState, mapDispatch)(GamePlanner)