import React, { useEffect, useState } from "react";
import {
  getResult,
  getTotalRisk,
  getTotalWin,
  getTotalBlankHtml,
  getBlankHtmlBeters,
  getHomeBeters,
  getAwayBeters,
  getMehome,
  getMeAway,
  stringWithValidLength,
  getUser,
  getAllEv,
  getUniqueBeters,
  getMe,
  getitsMe,
  getUniqueMe,
  getLastName,
  getResultNew,
} from "../../AllEventCalculation/Calculation";
import LeftRightScroll from "../../LeftRightScroll";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect } from "../../../../../Utils/helperFunctions";

const PreviousBets = (props?: any) => {
  const { previousteamList, previousbetTotal, isAlertActive } = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>
   
  useEffect(() => {
    setUsers(getUser(previousteamList));
    setMeData(getMe(previousteamList));
  }, [previousteamList]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);
 
 
  return (
    <>
    <div ref={slideRef} className="custom_table table-responsive h-500">
       
      <table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        id="kt_subscriptions_table_2"
        role="grid"
      >
        <thead className="sticky-top" style={{background:"white"}}>
          <tr
            className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
            role="row"
          >
            <th
              className="min-w-250px cu-12 sorting text-start ps-4"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ width: 250, position: "sticky", left: 0, background: "white", zIndex: 1}}
            > 
              Fighters
            </th>
            {/* <th
              className="min-w-70px sorting position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 70, position: "sticky", left: 200, background: "white", zIndex: 1}}
              // aria-sort="ascending"
            >
              Moneyline
            </th>
            <th
              className="min-w-70px position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Billing: activate to sort column ascending"
              style={{ width: 70, position: "sticky", left: 295, background: "white", zIndex: 1 }}
            >
              Result
            </th> */}
            
            <th
              className="min-w-150px sorting "
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
              style={{width:150 }}
            >
              <Link to = {`/myprofile`}  className="text-muted">
                Me
              </Link>
            </th>
            {uniqueBeters?.length > 0 &&
              uniqueBeters.map((obj: any, index: any) => (
                <th
                  className="min-w-150px sorting"
                  tabIndex={0}
                  aria-controls="kt_subscriptions_table"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Created Date: activate to sort column ascending"
                  style={{ width: 150 }}
                  key={index}
                >
                  {
                    obj?.screenName
                    ?
                    <Link to = {`/player/${obj?.screenName}`}  className="text-muted">
                      {obj?.screenName ? stringWithValidLength(obj?.screenName ,20, 15) : "-"}
                    </Link>
                    :
                    obj?.screenName ? stringWithValidLength(obj?.screenName ,20, 15) : "-"
                  }
                  <div className="d-flex align-items-center justify-content-between pt-2">
                    <div className="w-50">Risk</div>
                    <div className="w-50">Win</div>
                  </div>
                </th>

                
              ))}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold cu-table-01">
          {previousteamList?.length > 0 ? (
            previousteamList?.map((obj: any, index: number) => (
              <>     
                <tr className="bg-table-row-transparent" key={index}>
                  <td
                  // role={obj?.awayTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} className="text-gray-800 text-start ps-4 scroll_sticky_white table-column ">
                  <span className="image-avatar-wrapper" role={obj?.awayTeamUrl && "button"} onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                      <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)} >
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                        {getLastName(obj?.away_team)}
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_third table-column">{obj?.moneyline_away ? obj?.moneyline_away : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* Me */}
                  {uniqueMe?.length > 0
                    ? uniqueMe.map((objIs: any) =>
                        getMeAway(objIs, obj, itsMe)
                      )
                    : getBlankHtmlBeters()}

                  {/* AWAY TEAM BETERS */}
                  {uniqueBeters?.map((Ob: any) =>
                    getAwayBeters(Ob, obj, allEv)
                  )}
                </tr>           
                <tr className="bg-table-row-2">
                  <td
                  // role={obj?.homeTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} className="text-gray-800 text-start ps-4 scroll_sticky_white table-column">
                  <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px" ,verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_third table-column">{obj?.moneyline_home ? obj?.moneyline_home : "-"}</td>
                  <td className="scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_home)}</td> */}
                  {/* Me */}
                  {uniqueMe?.length > 0
                    ? uniqueMe.map((objIs: any) =>
                        getMehome(objIs, obj, itsMe)
                      )
                    : getBlankHtmlBeters()}

                  {/* HOME TEAM BETERS */}
                  {uniqueBeters?.map((Ob: any) =>
                    getHomeBeters(Ob, obj, allEv)
                  )}
                </tr>                
              </>
            ))
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4 table-column">
                Data Not found...
              </td>
            </tr>
          )}
          {/* Total      */}
          {previousteamList?.length > 0 && (
            <tr className="total_number">
              <td style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} className="ps-5 text-start scroll_sticky_black">Bet Amount and Margin</td>
              {/* <td className="scroll_sticky_black_third"></td>
              <td className="scroll_sticky_black_fourth"></td> */}
              {uniqueMe?.length > 0
                ? uniqueMe.map((ob: any, index: number) => (
                    <td  key={index}>
                      <div className="d-flex align-items-center justify-content-between ">
                        <div className="w-50">
                          {(getTotalRisk(ob, previousbetTotal)) ? getTotalRisk(ob, previousbetTotal) : "$ 0.00"}
                        </div>
                        <div className="w-50">
                          {/* {getTotalWin(ob, previousbetTotal)} */}
                          {/* --------getting summary value in bets total--------- */}
                          <div className={`w-100 ${props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk > 0 ? "w Win" : "r Lose"} btn-sm p-1`}>
                            {!isNaN(props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk))  : "$ 0.00"}
                          </div>
                        </div>
                      </div>
                    </td>
                  ))
                : getTotalBlankHtml()}
              {uniqueBeters?.length > 0 &&
                uniqueBeters.map((ob: any, index: any) => (
                  <td key={index}>
                    <div className="d-flex align-items-center justify-content-between ">
                      <div className="w-50">
                        {(getTotalRisk(ob, previousbetTotal)) ? getTotalRisk(ob, previousbetTotal) : "$ 0.00"}
                      </div>
                      <div className="w-50">
                        {/* --------getting summary value in bets total--------- */}
                        <div className={`w-100 ${props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk > 0 ? "w Win" : "r Lose"} btn-sm p-1 `}>
                          {!isNaN(props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(props?.summaryData?.find((el: any) => el.userId === ob.userId)?.betRisk)) : "$ 0.00"}
                        </div>
                        {/* {getTotalWin(ob, previousbetTotal)} */}
                      </div>
                    </div>
                  </td>
                ))}
            </tr>
          )}
        </tbody>
       
      </table>
      
     
    </div>
    
        {/* <div className="mt-3">
          <span  style={{ position: "sticky"}} className="left_right d-flex text-dark fw-bolder fs-5 d-flex justify-content-end align-items-center">
          
             <i onClick={()=>slider("left")} className={`${slideRef?.current?.scrollLeft>0?"left_right_buttons cursor-pointer":"text-muted"} bi bi-arrow-left-circle-fill fa-lg`}></i>
            <i onClick={()=>slider("right")} className={`${slideRef?.current?.scrollWidth-slideRef?.current?.scrollLeft>313?"left_right_buttons cursor-pointer":"text-muted"} bi bi-arrow-right-circle-fill fa-lg mx-2`}></i>
             
          </span>
        </div> */}
        
        <LeftRightScroll slideRef={slideRef}/>
        
     
       </>
  );
};

export default PreviousBets;
