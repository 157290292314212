import  { useEffect, useState } from "react";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect } from "../../../../../Utils/helperFunctions";
import {
    getUser,
    getAllEv,
    getUniqueBeters,
    getMe,
    getitsMe,
    getUniqueMe,
    stringWithValidLength,
    getResult,
    getLastName,
    getResultNew,
    getTotalBlankHtml
  } from "../../../Club/AllEventCalculation/Calculation";
  import {
    getParlayTotalRisk,
    getParlayTotalWin
  } from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
  import { getPreParlayHomeMe, getPreParlayAwayMe, getUserPreBlankHtml } from "../../../MyProfile/ProfilePreviousEvents/PreviousBets/CalulationPreUser";
const PreviousEventParlay = (props?:any) => {
  const { previousParlayList, risk, setrisk, parlayevents, setParlayEvents, parlayLock } = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const [myData, setMyData] = useState<any>([])

  useEffect(() => {
    const data =  props?.userSummaryData
    setMyData(data)
  }, [previousParlayList])

  useEffect(() => {
    setUsers(getUser(previousParlayList));
    setMeData(getMe(previousParlayList));
  }, [previousParlayList]);


  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);

    return(
        <div className="table-responsive h-500">
        <table
            className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
            id="kt_subscriptions_table_3"
            role="grid"
        >
            {/*begin::Table head*/}
            <thead className="sticky-top" style={{background:"white"}}>
            {/*begin::Table row*/}
            <tr
                className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
                role="row"
            > 
                <th
                className="min-w-250px cu-12 sorting text-start"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Customer: activate to sort column ascending"
                style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}
                >
                Fighters
                </th>
                {/* <th
                className="min-w-100px sorting"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Status: activate to sort column descending"
                style={{ width: 100 }}
                // aria-sort="ascending"
                >
                Moneyline
                </th> 
                <th
                  className="min-w-100px sorting"
                  tabIndex={0}
                  aria-controls="kt_subscriptions_table"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Billing: activate to sort column ascending"
                  style={{ width: 100 }}
                >
                  Result
                </th> */}
                <th
                className="min-w-175px sorting"
                tabIndex={0}
                aria-controls="kt_subscriptions_table"
                rowSpan={1}
                colSpan={1}
                aria-label="Product: activate to sort column ascending"
                >
                Me 
                </th>                
            </tr>
            </thead>
            <tbody className="text-gray-600 fw-bold cu-table-01">
            {previousParlayList?.length > 0 ? (
            previousParlayList.map((obj: any, index: any) => (
              <>     
               <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"}
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  <span className="image-avatar-wrapper"  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px", verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span  onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                        ?
                        <div style = {{fontSize:'10px'}}>{  ((obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked")}</div>
                        :
                        ''
                        }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                     {
                    uniqueMe?.length > 0 ? (
                      uniqueMe.map((ob: any, ind: number) =>
                      getPreParlayAwayMe(
                          ob,
                          obj,
                          itsMe,
                          parlayevents,
                          setParlayEvents,
                          parlayLock,
                          obj?.result ? getResult(obj?.result?.winner_away) : ''
                        )
                      )
                    ) : (
                     <td className="table-column">-</td>
                    )
                  }
                </tr>           
                <tr className="bg-table-row-2">
                  <td 
                  // role={obj?.homeTeamUrl && "button"} 
                  className="text-gray-800 text-start ps-4 table-column" style={{ position: "sticky", left: 0, background: "white", zIndex: 1}}>
                  <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)}>
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                        !isNaN(obj?.userPicksPercentage?.pickPercentageHome)
                        ?
                        <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div>
                        :
                        ''
                      }
                    </span>
                  </td>
                  {/* <td className="table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className="table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {
                    uniqueMe?.length > 0 ? (
                      uniqueMe.map((ob: any, ind: number) =>
                        getPreParlayHomeMe(
                          ob,
                          obj,
                          itsMe,
                          parlayevents,
                          setParlayEvents,
                          parlayLock,
                          obj?.result ? getResult(obj?.result?.winner_home) :""
                        )
                      )
                    ) : (
                      <td className="table-column">-</td>
                    )
                  }
                   
                </tr>                
              </>
            ))
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4">
                Data Not found...
              </td>
            </tr>
          )}

          {/* Total Number Row  */}
          {previousParlayList?.length > 0 && (
            <tr className="total_number">
              <td className="ps-5 text-start" style={{ position: "sticky", left: 0, background: "black", zIndex: 1}}>
                Parlay Bet Amount and ROI
              </td>
              {/* <td></td>
              <td></td> */}
              <>
                {myData.length > 0 ? myData && myData.map((ele:any) => {
                  const parlayRisk = ele?.parleyRisk
                  return  <td><div className="d-flex align-items-center justify-content-between">
                  <div className={`w-50 ${parlayRisk < 0 ? "r Lose" : ""} btn-sm p-1`}>                  
                    {uniqueMe?.length > 0
                      ? 
                   (uniqueMe?.map((ob: any) => getParlayTotalRisk(ob, previousParlayList)))
                    : "$ 0.00"
                  }
                  </div>
                  <div className="w-50 ms-5">
                    {uniqueMe?.length > 0
                      ? uniqueMe?.map((ob: any) =>{
                       
                        return <div className={`w-100 ${props?.userSummaryData?.find((el: any) => el.userId === ob.userId)?.parleyRisk > 0 ? "w Win" : ""} btn-sm p-1`}>
                                {!isNaN(props?.userSummaryData?.find((el: any) => el.userId === ob.userId)?.parleyRisk) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format((props?.userSummaryData?.find((el: any) => el.userId === ob.userId)?.parlayWin))  : "$ 0.00"}
                              </div>
                      })
                      : "$ 0.00"}
                  </div>
                </div>
                </td>
                }):
                getTotalBlankHtml()
                }
                
              </>
              {uniqueBeters?.length > 0 &&
                uniqueBeters?.map((ob: any, index: any) => (
                  <td key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-50">
                         {getParlayTotalRisk(ob, previousParlayList)}
                      </div>
                      <div className="w-50">
                        {getParlayTotalWin(ob, previousParlayList)}
                      </div>
                    </div>
                  </td>
                ))}
            </tr>
          )}
            </tbody>
            {/*end::Table body*/}
        </table>
    </div>
    )
}
export default PreviousEventParlay;