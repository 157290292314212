import {
  SHEDULE_BY_YEAR,
  SHEDULE_BY_YEAR_SUCCESS,
  TEAM_DATA_BY_DATE,
  TEAM_DATA_SUCCESS_BY_DATE,
  JOIN_EVENT,
  JOIN_EVENT_SUCCESS,
  JOIN_EVENT_ERROR,
  CLUB_BY_ID,
  CLUB_BY_ID_SUCCESS,
  PREVIOUS_SHEDULE_BY_YEAR,
  PREVIOUS_SHEDULE_BY_YEAR_SUCCESS,
  PREVOIUS_TEAM_DATA,
  PREVOIUS_TEAM_DATA_SUCCESS,
  CLUB_MEMBER_LIST,
  CLUB_MEMBER_LIST_SUCCESS,
  REMOVE_CLUB_MEMBER,
  REMOVE_CLUB_MEMBER_SUCCESS,
  LEAVE_CLUB,
  LEAVE_CLUB_SUCCESS,
  EVENT_PARLAY_TEAM_BY_DATE,
  EVENT_PARLAY_TEAM_BY_DATE_SUCCESS,
  JOIN_PARLAY_EVENT,
  JOIN_PARLAY_EVENT_SUCCESS,
  SUMMARY_DATA,
  SUMMARY_DATA_SUCCESSS,
  PROFILE_BETS,
  PROFILE_BETS_SUCCESS,
  USER_BET_BY_DATE,
  USER_BET_BY_DATE_SUCCESS,
  USER_PARLAY_BY_DATE,
  USER_PARLAY_BY_DATE_SUCCESS,
  USER_SUMMARY_BY_DATE,
  USER_SUMMARY_BY_DATE_SUCCESS,
  USER_PREVIOUS_BET_BY_DATE,
  USER_PREVIOUS_BET_BY_DATE_SUCCESS,
  USER_PREVIOUS_PARLAY_BY_DATE,
  USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  CLUB_PREVIOUS_PARLAY_BY_DATE,
  CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  GET_INVITE_CODE,
  GET_INVITE_CODE_SUCCESS,
  CLUB_PRE_SUMMARY,
  CLUB_PRE_SUMMARY_SUCCESS,
  PROFILE_PRE_SUMMARY,
  PROFILE_PRE_SUMMARY_SUCCESS,
  STATS_EVENTS_SCORE,
  STATS_EVENTS_SCORE_SUCCESS,
  ONLY_GET_USER_STAT_SUCCESS,
  ONLY_GET_USER_STAT,
  CLUB_STANDINGS,
  CLUB_STANDINGS_SUCCESS,
  PUBLIC_STATS_EVENTS_SCORE,
  PUBLIC_STATS_EVENTS_SCORE_SUCCESS,
  TEAM_LIST
} from "../type.action";

// SCHEDULE BY YEAR
export const sheduleByYear = () => ({
  type: SHEDULE_BY_YEAR,
});

export const sheduleByYearSuccess = (values?: IscheduleByYearRes) => ({
  type: SHEDULE_BY_YEAR_SUCCESS,
  values,
});

export const teamList = (values ?: any) => ({
  type: TEAM_LIST,
  values,
});

// TEAM DATA BY DATE
export const teamData = (values?: IclubForAllbetPayload) => ({
  type: TEAM_DATA_BY_DATE,
  values,
});
export const teamDataSuccess = (values ?: any) => ({
  type: TEAM_DATA_SUCCESS_BY_DATE,
  values
});

// join event
export const joinEvent = (values?: IDraftForbets) => ({
  type: JOIN_EVENT,
  values
})
export const joinEventSuccess = (values ?: any) => ({
  type: JOIN_EVENT_SUCCESS,
  values
})
export const joinEventError = (error ?: any) => ({
  type: JOIN_EVENT_ERROR,
  error
})

// get club details by id
export const clubById = (values ?: any) => ({
  type : CLUB_BY_ID,
  values
})
export const clubByIdSuccess = (values ?: any) => ({
  type : CLUB_BY_ID_SUCCESS,
  values
})

// previous Schedule by year
export const previousSchedule = () => ({
  type : PREVIOUS_SHEDULE_BY_YEAR
})

export const previousScheduleSuccess = (values ?: any) => ({
  type : PREVIOUS_SHEDULE_BY_YEAR_SUCCESS,
  values
})

// team date by date (for previous)
export const previousTeamData = (values ?: IclubForAllbetPayload) => ({
  type: PREVOIUS_TEAM_DATA,
  values
})
export const previousTeamDataSuccess = (values? : any) => ({
  type : PREVOIUS_TEAM_DATA_SUCCESS,
  values
})

// club Member List
export const clubMemberList = (values ?: any) => ({
  type: CLUB_MEMBER_LIST,
  values
})
export const clubMemberListSuccess = (values ?: any) => ({
  type: CLUB_MEMBER_LIST_SUCCESS,
  values
})

// Remove Club Member 
export const removeClubMember = (values ?: any) =>({
  type : REMOVE_CLUB_MEMBER,
  values
})
export const removeClubMemberSuccess = (values ?: any) => ({
  type: REMOVE_CLUB_MEMBER_SUCCESS,
  values
})

// LEAVE CLUB
export const leaveClub = (values ?: any) => ({
  type: LEAVE_CLUB,
  values
})
export const leaveClubSuccess = (values ?: any) => ({
  type: LEAVE_CLUB_SUCCESS,
  values
})

// GET EVENT PARLAY TEAM BY DATE
export const eventParlayTeam = (values ?: IclubForAllbetPayload) => ({
  type: EVENT_PARLAY_TEAM_BY_DATE,
  values
})
export const eventParlayTeamSuccess = (values ?: any) => ({
  type : EVENT_PARLAY_TEAM_BY_DATE_SUCCESS,
  values
})

// JOIN PARLAY EVENT
export const joinParlayEvent = (values ?: IDraftForParlay) => ({
  type: JOIN_PARLAY_EVENT,
  values
})
export const joinParlayEventSuccess = (values ?: any) => ({
  type: JOIN_PARLAY_EVENT_SUCCESS,
  values
})

// get summary data 

export const summaryData = (values ?: IclubForAllbetPayload) => ({
  type: SUMMARY_DATA,
  values
})
export const summaryDataSuccess = (values ? : any) => ({
  type: SUMMARY_DATA_SUCCESSS,
  values
})

// GET PROFILE BETS
export const profileBets = (values ?: any) => ({
  type: PROFILE_BETS,
  values
})
export const profileBetsSuccess = (values ?: any) => ({
  type: PROFILE_BETS_SUCCESS,
  values
})

// user bet by date 
export const userEventByDate = (values ?: IAllBetForUser) => ({
  type: USER_BET_BY_DATE,
  values
})
export const userBetBydateSuccess = (values ?: any) => ({
  type: USER_BET_BY_DATE_SUCCESS,
  values
})

// user parlay by date 
export const userParlayBydate = (values ?: IAllBetForUser) => ({
  type: USER_PARLAY_BY_DATE,
  values
})
export const userParlayBydateSuccess = (values?: any) => ({
  type : USER_PARLAY_BY_DATE_SUCCESS,
  values
})

// user summary

export const userSummary = (values ?: IAllBetForUser) => ({
  type: USER_SUMMARY_BY_DATE,
  values
})
export const userSummarySuccess = (values ?:any) => ({
  type : USER_SUMMARY_BY_DATE_SUCCESS,
  values
})

// user previous bet
export const userPreviousBet = (values ?: IAllBetForUser) => ({
  type : USER_PREVIOUS_BET_BY_DATE,
  values
})
export const userPreviousBetSuccess = (values ?: any) => ({
  type: USER_PREVIOUS_BET_BY_DATE_SUCCESS,
  values
})

// user parlay bet
export const userPreviousParlay = (values ?: IAllBetForUser) => ({
  type : USER_PREVIOUS_PARLAY_BY_DATE,
  values
})
export const userPreviousParlaySuccess = (values ?:any) => ({
  type: USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  values
})

// previous parlay for club
export const clubPreviousParlay = (values ?: IclubForAllbetPayload) => ({
  type: CLUB_PREVIOUS_PARLAY_BY_DATE,
  values
})
export const clubPreviousParlaySuccess = (values ?: any) => ({
  type: CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  values
})

// get invite code
export const getInviteCode = () => ({
  type : GET_INVITE_CODE
})
export const getInviteCodeSuccess = (values ?: any) => ({
  type: GET_INVITE_CODE_SUCCESS,
  values
})

// club previous summary

export const getClubPreSummary = (values ?: any) => ({
  type: CLUB_PRE_SUMMARY,
  values
})
export const getClubPreSummarySuccess = (values ?:any) => ({
  type: CLUB_PRE_SUMMARY_SUCCESS,
  values
})

// profile previous summary
export const getProfilePreSummary = (values ?:any) => ({
  type : PROFILE_PRE_SUMMARY,
  values
})
export const getProfilePresummarySuccess = (values ?: any) => ({
  type : PROFILE_PRE_SUMMARY_SUCCESS,
  values
})

// stats and events score
export const statsEventsScore = (values : any) => ({
  type: STATS_EVENTS_SCORE,
  values
})
export const statsEventsScoreSuccess = (values ?: any) => ({
  type: STATS_EVENTS_SCORE_SUCCESS,
  values
})
export const onlyGetUserStatSuccess = (values ?: any) => ({
  type: ONLY_GET_USER_STAT_SUCCESS,
  values
})

export const onlyGetUserStat = (values ?: any) => ({
  
  type: ONLY_GET_USER_STAT,
  values
   
})



// public stats and events score
export const publicStatsEventsScore = (values ?: any) => ({
  type: PUBLIC_STATS_EVENTS_SCORE,
  values
})
export const publicStatsEventsScoreSuccess = (values ?: any) => ({
  type: PUBLIC_STATS_EVENTS_SCORE_SUCCESS,
  values
})

// CLUB STANDINGS
export const clubStandings = (values ?:any) => ({
  type: CLUB_STANDINGS,
  values
})
export const clubStandingsSuccess = (values ?: any) => ({
  type: CLUB_STANDINGS_SUCCESS,
  values
})