import { 
    CHAT_GET_ALL_MESSAGE,
    CHAT_GET_ALL_MESSAGE_SUCCESS,
    CHAT_NEW_MESSAGE,
    CHAT_NEW_MESSAGE_SUCCESS,
} from "../type.action"

export const allMessages = (values:any) => ({
    type: CHAT_GET_ALL_MESSAGE,
    values
})
export const allMessagesSuccess = (values ?: any) => ({
    type: CHAT_GET_ALL_MESSAGE_SUCCESS,
    values
})

export const newMessage = (values:any) => ({
    type: CHAT_NEW_MESSAGE,
    values
})
export const newMessageSuccess = (values:any) => ({
    type: CHAT_NEW_MESSAGE_SUCCESS,
    values
})