import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getClubDetails } from "../../../../Store/action/UserInfo.action";
import Spinner from "../../../../Common/Spinner/Spinner";
import ReactPaginate from 'react-paginate';
import { stringWithValidLength } from "../../Club/AllEventCalculation/Calculation"

const PublicProfileClubs = (props?: any) => {
  const userProfile = props?._userProfile?.userDataIs.data;
  const [Loading, setLoading] = useState<boolean>(false);
  const [ListIs, setListIs] = useState<any>(null);
  const [totalPages, setToatalPages] =  useState<number>(0);
  const [From , setFrom] = useState<number>(4);
  const [To, setTo] = useState<number>(0)
  const Limit = 4;

  const getClubData = () => {
    setLoading(true);
    setListIs([])
  };

  useEffect(() => {
    getClubData();
  }, []);

//   const getclubList = (data: any) => {
//     setListIs(data);
//     setLoading(false);
//   };

  useEffect(() => {
    const clubListIs = props.clubs;
    if (clubListIs && Loading) {
        setToatalPages(Math.ceil((clubListIs?.length)/Limit))
        setListIs(clubListIs)
        setLoading(false);
    }
  }, [props.clubs]);

  const handlePageClick = (data : any) => {
    const selectedData = data?.selected + 1;
    const from = selectedData * Limit;
    const to =  from - Limit;
    setFrom(from)
    setTo(to)
  }

  useEffect(() => {
    
  }, [ListIs])

  return (
    <div className="card mb-10">
      <div className="card-header">
        <div className="card-title w-100 d-flex align-items-center justify-content-between m-0">
          <div>
            <h3 className="fw-bolder mb-0">Player Clubs</h3>
          </div>
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        {Loading ? (
          <Spinner color="text-black spinner-border-sm " />
        ) : ListIs?.length > 0 ? (
          ListIs?.slice(To , From).map((obj: any, index: number) => (
            <div className="d-flex align-items-center mb-5" key={index}>
              <div className="fw-bold">
                {/* <Link
                  to={`/clubdetails/${obj?.clubId}`}
                  className="fs-6 fw-bold text-gray-900 text-hover-dark"
                > */}
                  {obj?.name && stringWithValidLength(obj?.name ,30, 30)}
                {/* </Link> */}
              </div>
            </div>
          ))       
        ) : (
          <div className="d-flex align-items-center mb-5">
            <div className="fw-bold">No Clubs yet.</div>
          </div>
        )}
         {totalPages > 0 && 
         <div className="d-flex justify-content-end">
         <nav aria-label="Page navigation example">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={2}
                onPageChange={(data) => handlePageClick(data)}
                containerClassName={"pagination"}
                // subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
          </nav> 
          </div>} 
      </div>
    </div>
  );
};

export default PublicProfileClubs
