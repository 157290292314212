import {
  LOGIN_DATA,
  LOGIN_DATA_SUCCESS,
  SIGNUP_DATA,
  SIGNUP_DATA_SUCCESS,
  SIGNUP_DATA_ERROR,
  LOGIN_DATA_ERROR,
  FORGOT_PWD,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_ERROR,
  RESET_PWD,
  RESET_PWD_SUCCESS,
  RESET_PWD_ERROR,
} from "../type.action";
// LOGIN **********
export const loginData = (values?: ILogin) => ({
  type: LOGIN_DATA,
  values
});

export const loginDataSuccess = (values?: ILoginRes) => ({
  type: LOGIN_DATA_SUCCESS,
  values,
});
export const loginDataErr = (error?: IData) => ({
  type: LOGIN_DATA_ERROR,
  error
});

// SIGNUP ***************
export const signUpData = (values?: ISignUp) => ({
  type: SIGNUP_DATA,
  values
});
export const signupDataSuccess = (values?: ILoginRes) => ({
  type: SIGNUP_DATA_SUCCESS,
  values
});
export const signUpDataErr = (error?: IData) => ({
  type: SIGNUP_DATA_ERROR,
  error
});

// FORGOT PWD **********
export const forgotPwd = (values?: IforgetPwd) => ({
  type: FORGOT_PWD,
  values
});
export const forgotPwdSuccess = (values?: IData) => ({
  type: FORGOT_PWD_SUCCESS,
  values
});
export const forgotPwdErr = (error?: IData) => ({
  type: FORGOT_PWD_ERROR,
  error
});

// Reset Pwd *************
export const resetPwd = (values?: IResetPwdArgu) =>({
  type: RESET_PWD,
  values
})
export const resetPwdSuccess = (values?: IData) =>({
  type : RESET_PWD_SUCCESS,
  values
})
export const resetPwdError = (error? : IData) => ({
  type : RESET_PWD_ERROR,
  error
})
