import { getDiffrenceOfTimeInMinutes } from "../../../../../../Utils/helperFunctions";
import { getResult } from "../../../AllEventCalculation/Calculation";

export const getParlayHomeBeters = (uniqueUser: any, obj: any, allEv: any , result : any) => {
  if(!uniqueUser.showPrediction) {
  if (obj?.events?.length > 0) {
    const a = allEv?.find((l: any) => l.home_team_id === obj.home_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === uniqueUser.userId && j.pickedTeam === 1
    );
    const c = b?.length > 0 && b[0];
    return (
      <td className="table-column">
        {c ? (
          <button
            type="button"
            className={`btn ${result === "Loss" ? "bg-danger" : result === "Win" ? "bg-success" : "btn-dark"} btn-sm custom_padding apni-class`}
          >
            Picked
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  } else {
    return <td className="table-column">-</td>;
  } } else {
    return <td className="table-column">-</td>;
  }
};

export const getParlayAwayBeters = (uniqueUser: any, obj: any, allEv: any, result: any) => {
  if(!uniqueUser.showPrediction) {
  if (obj?.events?.length > 0) {
    const a = allEv?.find((l: any) => l.away_team_id === obj.away_team_id);
    const b = a?.events?.filter(
      (j: any) => j.userId === uniqueUser.userId && j.pickedTeam === 2
    );
    const c = b?.length > 0 && b[0];
    return (
      <td className="table-column">
        {c ? (
          <button
            type="button"
            className={`btn ${result === "Loss" ? "bg-danger" : result === "Win" ? "bg-success" : "btn-dark"} btn-sm custom_padding apni-class`}
           >
            Picked
          </button>
        ) : (
          "-"
        )}
      </td>
    );
  }else {
    return <td className="table-column">-</td>;
  }} else {
    return <td className="table-column">-</td>;
  }
};

//parlay total of (risk / win)

export const getParlayTotalRisk = (el: any, List: any) => {
  const a = List?.map((item: any) => {
    const x = item?.risks?.find((ob: any) => ob.riskId === el.riskId);
    return x
  });  

  let risk = a?.find((el :any) => el?.risk)?.risk;
  risk = new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(risk)
  return risk ? risk : 0
};

export const getParlayTotalWin = (el: any, List: any) => {
  const a = List?.map((item: any) => {
    const x = item?.risks?.find((ob: any) => ob.riskId === el.riskId);
    return x
  });
  let win = a?.find((el :any) => el?.win)?.win 
  win = new Intl.NumberFormat().format(win)
  return win ? win : 0
}

// parlay me home and away

export const getParlayWin = (parlayevents : any, risk : number, setTotalWin: any) =>{
  const hasProblity = parlayevents?.filter((el : any) => el.problity !== "")
  var productIs : any = []
   hasProblity?.forEach((item : any) =>{
    const a = item?.problity  / 100
    productIs.push({problity : a})
  })

 const multiplyOfProduct = productIs?.reduce(
    (accumulator: any, current: any) =>
      Number(accumulator) * Number(current?.problity),
    1
  );
  
  const finalProduct = multiplyOfProduct && (multiplyOfProduct*100);

  const y = ((100 - finalProduct) * 100)/ finalProduct;

  const finalWin = (risk && y && productIs?.length > 0) ?  (risk * y)/100 : 0;
  const currentWin = finalWin?.toFixed(2)
  
  setTotalWin(currentWin)

 return new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(finalWin)
}

export const problity = (obj : any, pickedTeam:number) => {
  const moneyLine = pickedTeam === 1 ? obj?.moneyline_home : obj?.moneyline_away;  
  const p = moneyLine ? (moneyLine > 0) ? (100 / (moneyLine + 100)) : 1 + (100/((moneyLine) - 100)) : 0;
  const prob = p*100
  return prob.toFixed(2) 
}

const handleParlayEvents = (obj : any, pickedTeam : number, parlayevents:any, setParlayEvents:any, riskId:any, moneyLine:any) => {  
  const list = [
    ...parlayevents.filter((k: any) => k.scheduleId !== obj.id),
    { scheduleId: obj.id, pickedTeam, riskId, problity : problity(obj, pickedTeam), moneyLine, away_team: obj.away_team, home_team: obj.home_team },
  ];
  setParlayEvents([...list])
}

const handleToggleParlay = (obj : any, pickedTeam : number, parlayevents:any, setParlayEvents:any, riskId:any, moneyLine:any) =>{  
  const list = [
    ...parlayevents.filter((k: any) => k.scheduleId !== obj.id),
    { scheduleId: obj.id, pickedTeam: "", riskId, problity : "", moneyLine, away_team: obj.away_team, home_team: obj.home_team},
  ];
  setParlayEvents([...list])
}

export const getParlayHomeMe = (uniqueMe: any, obj: any, itsMe: any,parlayevents:any, setParlayEvents:any, parlayLock:boolean, isPublicProfileUser?:boolean) => {  
  // console.log("getParlayHomeMe")
  const checkRiskId = obj?.events?.filter((item : any) => item.userId === uniqueMe?.userId);
  const riskId = checkRiskId ? checkRiskId[0]?.riskId : '';
  const g = parlayevents?.find((el : any) => (el.scheduleId == obj.id)&& el.pickedTeam == 1)
  const isDisabled = parlayevents?.find((el : any) => (el.scheduleId === obj.id)) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) >0

  return (
    <td className="table-column">
      {
        (!g && parlayLock) 
        ? 
        "-"
        :
        ( !isPublicProfileUser || !uniqueMe?.showPrediction  ?
            (g && !parlayLock) || (g&&parlayLock) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0
            ? 
            <button type="button" 
              className={`opacity-1 btn ${g && getResult(obj?.result?.winner_home) == "Win" ? "bg-success" : (g && getResult(obj?.result?.winner_home) == "Loss") ? "btn-orange" :(g)?"btn-dark": "btn-secondary"} btn-sm custom_padding`}
              onClick={(g)? () => handleToggleParlay(obj, 1, parlayevents, setParlayEvents, riskId, obj.moneyline_home) : () => handleParlayEvents(obj, 1, parlayevents, setParlayEvents, riskId, obj.moneyline_home)}
              disabled={(((isDisabled?.pickedTeam) === 2) || parlayLock) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) < 0}
            >
              { g && !parlayLock ? "Selected" : (g&&parlayLock ? "Picked" : "Pick")}
            </button>
            :
            "-"
            :"-"
        )
        
      }
    </td>
  );
  
};

export const getParlayAwayMe = (uniqueMe: any, obj: any, itsMe: any,parlayevents:any, setParlayEvents:any, parlayLock: boolean, isPublicProfileUser?:boolean) => {
  const checkRiskId = obj?.events?.filter((item : any) => item.userId === uniqueMe?.userId);
  const riskId = checkRiskId ? checkRiskId[0]?.riskId : '';
  const g = parlayevents?.find((el : any) => (el.scheduleId == obj.id)&& el.pickedTeam == 2)
  const isDisabled = parlayevents?.find((el : any) => (el.scheduleId === obj.id)) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0
 
  return (
    <td className="table-column">
      {
        (!g && parlayLock) 
        ? 
        "-"
        :
        (!isPublicProfileUser || !uniqueMe?.showPrediction  ?
          (g && !parlayLock) || (g&&parlayLock) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) > 0
          ? 
          <button type="button" 
            className={`opacity-1 btn ${g && getResult(obj?.result?.winner_away) == "Win" ? "bg-success" : (g && getResult(obj?.result?.winner_away) == "Loss") ? "btn-orange" :(g)?"btn-dark": "btn-secondary"} btn-sm custom_padding`}
            onClick={(g)? () => handleToggleParlay(obj, 2, parlayevents, setParlayEvents, riskId, obj.moneyline_away) : () => handleParlayEvents(obj, 2, parlayevents, setParlayEvents, riskId, obj.moneyline_away)}
            disabled={(((isDisabled?.pickedTeam) === 1) || parlayLock) || getDiffrenceOfTimeInMinutes(obj.eventCardTime) < 0}
          >
           { g && !parlayLock ? "Selected" : (g&&parlayLock ? "Picked" : "Pick")}
          </button>
          :
          "-"
          :"-"
        )
        
      }
    </td>
  )
}

export const getBlankParlayHtml = (obj : any, pickedTeam : number,parlayevents:any, setParlayEvents:any, parlayLock: boolean, isPublicProfileUser: boolean, moneyLine:any) => {
  // console.log("getBlankParlayHtml", obj)
  const riskId = ""
  const d = parlayevents?.some((el : any) => (el.scheduleId === obj.id) && el.pickedTeam === pickedTeam )
  const isDisabled = parlayevents?.find((el : any) => (el.scheduleId === obj.id))

  return <td className="table-column">
    {(!d && parlayLock) || isPublicProfileUser? 
    "-":
    <button type="button" 
    className={`opacity-1 btn ${d ? "bg-secondary" : "btn-orange"} btn-sm custom_padding`}
    onClick={d ? () => handleToggleParlay(obj, pickedTeam, parlayevents, setParlayEvents, riskId, moneyLine) : () => handleParlayEvents(obj, pickedTeam,parlayevents,setParlayEvents, riskId, moneyLine)}
      disabled={(isDisabled?.pickedTeam !== "" && (isDisabled?.pickedTeam) !== pickedTeam) || parlayLock}   
    >
       {d ? "Selected" : "Pick"}
    </button>}
  </td>
}

// export const getBlankParlayHtmls = (obj : any, pickedTeam : number,parlayevents:any, setParlayEvents:any, parlayLock: boolean, isPublicProfileUser: boolean, moneyLine:any) => {
//     const riskId = ""
//     const d = parlayevents?.some((el : any) => (el.scheduleId === obj.id) && el.pickedTeam === pickedTeam )
//     const isDisabled = parlayevents?.find((el : any) => (el.scheduleId === obj.id))
  
//     return <td className="table-column">
//       {(!d && parlayLock) || isPublicProfileUser? 
//       "-":
//       <button type="button" 
//       className={`opacity-1 btn ${d ? "bg-secondary" : "btn-orange"} btn-sm custom_padding`}
//       onClick={d ? () => handleToggleParlay(obj, pickedTeam, parlayevents, setParlayEvents, riskId, moneyLine) : () => handleParlayEvents(obj, pickedTeam,parlayevents,setParlayEvents, riskId, moneyLine)}
//         disabled={(isDisabled?.pickedTeam !== "" && (isDisabled?.pickedTeam) !== pickedTeam) || parlayLock}   
//       >
//          {d ? "Selected" : "Pick"}
//       </button>}
//     </td>
//   }