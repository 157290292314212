import { 
    CHAT_GET_ALL_MESSAGE_SUCCESS, CHAT_NEW_MESSAGE_SUCCESS,
} from "../type.action"

const initState = {
    clubChatMessages: [],
    lastSeenMessage:[]
};

const reducers = (state = initState, { type, values, error }: any) => {
    switch(type){
        case CHAT_GET_ALL_MESSAGE_SUCCESS: return {
            ...state,
            clubChatMessages: values?.messages,
            lastSeenMessage:values?.lastSeenMessage
        }
        case CHAT_NEW_MESSAGE_SUCCESS:{
            const updatedClubChatMessages = [...state.clubChatMessages, ...values].filter((v,i,a)=>a.findIndex(t=>(t.id===v.id))===i)
            return {
                ...state,
                clubChatMessages: updatedClubChatMessages
            }
        }
        default: return state
    }
}

export default reducers;