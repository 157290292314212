
import Layout from '../../../../Common/Layout';
import { Link } from "react-router-dom";
import { joinClub } from "../../../../Store/action/UserInfo.action";
import { connect } from "react-redux";
import React, { useEffect, useState } from 'react';
import Spinner from "../../../../Common/Spinner/Spinner";
import Alert from '../../../../Common/Alert/Alert';

const ClubDetails = (props?: any) => {
    const { $joinClub, _joinClub } : IJoinClubProps = props;
    const [LoadingIs, setLoader] = useState<boolean>(false);
    const [alert, setAlert] = useState<IAlertIs>({
        activeIs : false,
        statusIs : "",
        msgIs : ""
    })

    useEffect(()=>{
       const code = props?.match?.params?.code;
       const token = localStorage.getItem("token")
        if(code && token){
          setLoader(true)
          $joinClub(props?.match?.params)
        }else{
            props.history.push("/login") 
        }
    },[])

    const getAlert = (response: string, status: string) =>{
        setAlert({
          activeIs: true,
          statusIs: status,
          msgIs: response
        });
      } 

    useEffect(()=>{
      const joinData = _joinClub.joinClubIs;
      const joinErr = _joinClub.joinClubErrIs
      if(joinData?.status === 1 && LoadingIs){
        setLoader(false)
      }else if(joinErr && LoadingIs){
        getAlert(joinErr?.message, "alert-danger")        
      }
    },[props._joinClub])

    useEffect(() =>{
        if (alert.activeIs) {
          setTimeout(() => {
              setAlert({ activeIs: false,  statusIs : '', msgIs: ''});   
              setLoader(false)
              props.history.push("/login")         
          }, 2000);
        }
      },[alert])


    return (
        <Layout options="fill_Header">
            <div className="main-content">
                <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
                    <div className="container">
                        <div className="row">                            
                            <div className="w-lg-500px text-center bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
                            {alert.activeIs &&
                                <Alert statusIs={`alert ${alert.statusIs}`} message={alert.msgIs}/>
                                }
                                {LoadingIs ?  <Spinner color="text-black spinner-border-sm " /> :
                                <React.Fragment>                               
                                <form
                                    className="form w-100"
                                    noValidate
                                    id="kt_sign_in_form"
                                    action="#"
                                >
                                    <div className="text-center mb-10">
                                    <h1 className="text-dark text-capitalize mb-5 text-font">Thank you!</h1>
                                            <p className="text-gray-400 fw-bold fs-4">You have successfully joined the club.</p>
                                    </div>
                                    <div className="text-center">
                                        <Link to="/clublist"
                                            type="submit"
                                            id="kt_sign_in_submit"
                                            className="btn btn-lg btn-orange mb-5 px-20"
                                        >
                                            <span className="indicator-label fw-bolder">Go to your club</span>
                                        </Link>
                                    </div>
                                </form>
                                </React.Fragment>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
const mapState = ({ joinClub }: any) => {
    return {
        _joinClub: joinClub
    };
};
const mapDispatch = (dispatchEvent?: any) => ({
    $joinClub: (values?:IJoinClub) => dispatchEvent(joinClub(values))
});
export default connect(
    mapState,
    mapDispatch
)(ClubDetails);
