import {
  SHEDULE_BY_YEAR_SUCCESS,
  TEAM_DATA_SUCCESS_BY_DATE,
  JOIN_EVENT_SUCCESS,
  CLUB_BY_ID_SUCCESS,
  PREVIOUS_SHEDULE_BY_YEAR_SUCCESS,
  PREVOIUS_TEAM_DATA_SUCCESS,
  CLUB_MEMBER_LIST_SUCCESS,
  REMOVE_CLUB_MEMBER_SUCCESS,
  LEAVE_CLUB_SUCCESS,
  EVENT_PARLAY_TEAM_BY_DATE_SUCCESS,
  JOIN_PARLAY_EVENT_SUCCESS,
  SUMMARY_DATA_SUCCESSS,
  PROFILE_BETS_SUCCESS,
  USER_BET_BY_DATE_SUCCESS,
  USER_SUMMARY_BY_DATE_SUCCESS,
  USER_PARLAY_BY_DATE_SUCCESS,
  USER_PREVIOUS_BET_BY_DATE_SUCCESS,
  USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS,
  GET_INVITE_CODE_SUCCESS,
  CLUB_PRE_SUMMARY_SUCCESS,
  PROFILE_PRE_SUMMARY_SUCCESS,
  STATS_EVENTS_SCORE_SUCCESS,
  ONLY_GET_USER_STAT_SUCCESS,
  PUBLIC_STATS_EVENTS_SCORE_SUCCESS,
  CLUB_STANDINGS_SUCCESS,
  TEAM_LIST,

} from "../type.action";

const initState = {
  sheduleIs: "",
  teamDataIs: "",
  joinEventData: "",
  clubBYId: "",
  previousSchedule: "",
  previousTeamData: "",
  clubMemberListIs: "",
  removeMemberIs: "",
  leaveClubIs: "",
  parlayListIs: "",
  joinParlayIs : '',
  summaryIs : "",
  profileBetsIs : "",
  userEventIs:"",
  userSummaryIs: "",
  userParlayIs : "",
  userPreParlayIs: "",
  clubPreviousParlayIs : "",
  inviteCodeIs: "",
  clubPreSummary : "",
  ProfilePreSummary: "",
  scoreDataIs : "",
  publicScoreDataIs : "",
  onlyScoreDataIs:"",
  standingsDataIs : "",
  prelimsCard:""
};

const reducers = (state = initState, { type, values, error }: any) => {
  switch (type) {
    case SHEDULE_BY_YEAR_SUCCESS:
      return {
        ...state,
        sheduleIs: values,
      };
      case TEAM_LIST:
      return {
        ...state,
        prelimsCard: values,
      };
    case TEAM_DATA_SUCCESS_BY_DATE:
      return {
        ...state,
        teamDataIs: values,
      };
    case JOIN_EVENT_SUCCESS:
      return {
        ...state,
        joinEventData: values,
      };
    case CLUB_BY_ID_SUCCESS:
      return {
        ...state,
        clubBYId: values,
      };
    case PREVIOUS_SHEDULE_BY_YEAR_SUCCESS:
      return {
        ...state,
        previousSchedule: values,
      };
    case PREVOIUS_TEAM_DATA_SUCCESS:
      return {
        ...state,
        previousTeamData: values,
      };
    case CLUB_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        clubMemberListIs: values,
      };
    case REMOVE_CLUB_MEMBER_SUCCESS:
      return {
        ...state,
        removeMemberIs: values,
      };
    case LEAVE_CLUB_SUCCESS:
      return {
        ...state,
        leaveClubIs: values,
      };
    case EVENT_PARLAY_TEAM_BY_DATE_SUCCESS:
      return {
        ...state,
        parlayListIs: values,
      };
    case JOIN_PARLAY_EVENT_SUCCESS:
      return{
        ...state,
        joinParlayIs : values
      } 
    case SUMMARY_DATA_SUCCESSS:
      return{
        ...state,
        summaryIs : values 
      }
    case PROFILE_BETS_SUCCESS:
      return{
        ...state,
        profileBetsIs : values
      } 
    case USER_BET_BY_DATE_SUCCESS:
      return{
        ...state,
        userEventIs : values
      }
    case USER_PARLAY_BY_DATE_SUCCESS:
      return{
        ...state,
        userParlayIs : values
      }   
    case USER_SUMMARY_BY_DATE_SUCCESS:
      return{
        ...state,
        userSummaryIs : values
      } 
    case USER_PREVIOUS_BET_BY_DATE_SUCCESS:
      return{
        ...state,
        userPreBetIs : values
      } 
    case USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS:
      return{
        ...state,
        userPreParlayIs : values
      }   
    case CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS:
      return{
        ...state,
        clubPreviousParlayIs : values
      } 
    case GET_INVITE_CODE_SUCCESS:
      return{
        ...state,
        inviteCodeIs : values
      } 
    case CLUB_PRE_SUMMARY_SUCCESS:
      return{
        ...state,
        clubPreSummary:  values
      } 
    case PROFILE_PRE_SUMMARY_SUCCESS:
      return{
        ...state,
        ProfilePreSummary : values
      }  
    case STATS_EVENTS_SCORE_SUCCESS:
      return{
        ...state,
        scoreDataIs : values
      }
    case ONLY_GET_USER_STAT_SUCCESS:
      return{
        ...state,
        onlyScoreDataIs : values
      }
     
    
    case PUBLIC_STATS_EVENTS_SCORE_SUCCESS:
      return{
        ...state,
        publicScoreDataIs : values
      }
    case CLUB_STANDINGS_SUCCESS:
      return{
        ...state,
        standingsDataIs : values
      }         
    default:
      return state;
  }
};

export default reducers;
