import EventBetslip from './ProfileEventBetslip/UserEventBetslip/EventBetslip';
import PreviousEvents from './ProfilePreviousEvents/PreviousBetslip/PreviousEvents';
import ProfileCard from './ProfileCard/ProfileCard';
import MyStats from './MyStats/MyStats';
import LastEvent from './LastEvents/LastEvent';
import LastFiveEvent from './LastEvents/LastFiveEvents';
import MyClubs from './MyClubs/MyClubs';
import MyBets from './MyBets/MyBets';
import Layout from '../../../Common/Layout';
import { connect } from "react-redux";
import { statsEventsScore , onlyGetUserStat} from "../../../Store/action/Events.action";
import { useEffect, useState } from 'react';
import MyBio from './MyBio/MyBio';

const MyProfile = (props ?: any) => {
    const[statsData , setStats] = useState<any>()
    const[onlyStat , setOnlyStats] = useState<any>()
    const[eventsData ,setEvents] = useState<any>([])
    const[last5EventDataNew, setLast5Data] = useState({})
    const[user, setUser] = useState<any>({})
    const[LoadingIs, setLoading] = useState<boolean>(false)

    let newDate = new Date().getFullYear()

    const [quarter,setQuarter] = useState<any>({quarter:0,newYear:newDate})
    const [myStateLoader,setMyStateLoader] = useState<any>(false)
    
    useEffect(() => {
      setLoading(true)
      setMyStateLoader(true)
      props.$statsEventsScore(quarter)
      props.$onlyGetUserStat()
    }, [])

    const getResponse = (data : any,onlyData:any) => {
       setLoading(false)
       setMyStateLoader(false)
      
       setOnlyStats(onlyData?.myStats)
       setStats(data?.myStats)
       setEvents(onlyData?.last_five_events_data)
       setLast5Data(onlyData?.last_five_event_data_new || {})
    }

    useEffect(() => {
      const onlyData = props._onlyGetUserStat.onlyScoreDataIs?.data
      
      const data = props._statsEventsScore.scoreDataIs?.data;
      
      if(data){
        getResponse(data,onlyData)
      }
    }, [props._statsEventsScore,props._onlyGetUserStat])
   
useEffect(()=>{
  props.$statsEventsScore(quarter);
  // props.$onlyGetUserStat();
},[quarter])
   
    return (
        <Layout options="fill_Header" uservisible="visible">
            <div className="main-content">
                <div className="container pt-15">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <ProfileCard statsData = {onlyStat} setMyProfileUser={setUser}/>
                            <MyBio bio={user?.bio} LoadingIs = {LoadingIs} />
                            <MyStats screenName={user?.screenName} myStateLoader={myStateLoader} setMyStateLoader={setMyStateLoader} statsData = {statsData}  quarter={quarter} setQuarter={setQuarter}/>
                            <LastEvent LoadingIs = {LoadingIs} eventsData = {eventsData}/>
                            <LastFiveEvent LoadingIs = {LoadingIs} eventsData = {eventsData} last5EventDataNew={last5EventDataNew} />
                            <MyClubs />
                            <MyBets />
                        </div>
                        <div className="col-sm-12 col-lg-8 table_wrapper">
                            <EventBetslip />
                            <PreviousEvents />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
// export default MyProfile
const mapState = ({ statsEventsScore,onlyGetUserStat }: any) => {
    return {
      _statsEventsScore: statsEventsScore,
      _onlyGetUserStat: onlyGetUserStat
    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $statsEventsScore: (values?: IQuarter) => dispatchEvent(statsEventsScore(values)),
    $onlyGetUserStat: () => dispatchEvent(onlyGetUserStat()),
  });
  export default connect(
    mapState,
    mapDispatch
  )(MyProfile);
