

const Alert = (props?: any) =>{
  return (
    <div className={`${props.statusIs} alertBox text-center`} role="alert">
      {props.message}
    </div>
  );
}

export default Alert;
