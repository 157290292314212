import React, { useEffect, useState } from "react";
import Facebook from "../../../assets/images/Icons/facebook.svg";
import Instagram from "../../../assets/images/Icons/instagram.svg";
import Twitter from "../../../assets/images/Icons/twitter.svg";
import Linkedin from "../../../assets/images/Icons/linkedin.png";
import Header from "../../../Common/Header";
import HTP1 from "../../../assets/images/plays/HTP-1.png";
import HTP2 from "../../../assets/images/plays/HTP-2.png";
import HTP3 from "../../../assets/images/plays/HTP-3.png";
import HTP4 from "../../../assets/images/plays/HTP-4.png";
import HTP5 from "../../../assets/images/plays/HTP-5.png";
import HTP6 from "../../../assets/images/plays/HTP-6.png";
import HTP7 from "../../../assets/images/plays/HTP-7.png";
import HTP8 from "../../../assets/images/plays/HTP-8.png";
import HTP9 from "../../../assets/images/plays/HTP-9.png";
import HTP10 from "../../../assets/images/plays/HTP-10.jpg";
import HTP11 from "../../../assets/images/plays/HTP-11.jpg";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Tiktok from "../../../assets/images/Icons/tiktok.png";
// import myVideo from "../../../assets/video/Resources for Research on MMA BetClub.mp4";
// import myVideoPrivate from "../../../assets/video/Private Clubs on MMA BetClub.mp4";
// import myVideoMaking from "../../../assets/video/Making MMA BetClub Predictions.mp4";

const PlayVideo = (props) => {
  const [userHasClub, setUserHasClub] = useState(false);
  const [emailExist, setEmailExist] = useState();

  useEffect(() => {
    const email = props?._userProfile?.userDataIs?.data?.email;
    setEmailExist(email);
    const hasClub = props?._userProfile?.userDataIs?.data?.hasClub;
    setUserHasClub(hasClub);
  }, [props?._userProfile]);
  const { userProfile } = props?.userProfile || {};
  return (
    <React.Fragment>
      <section>
        {/* Header Component */}
        <Header options="fill_Header" />

        {/* How To Play  */}
        <div className="main-content">
          <div className="how_to_play_banner">
            <h3 className="fs-4hx text-white">How To Play</h3>
          </div>
          <div className="container py-15">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  {/*begin::Body*/}
                  <div className="card-body p-lg-15">
                    {/*begin::Layout*/}
                    <div className="d-flex flex-column flex-lg-row">
                      {/*begin::Content*/}
                      <div className="flex-lg-row-fluid">
                        {/* Disclaimer */}
                        <div className="text-center mb-20">
                          <h2 className="fs-2hx text-dark mb-5">
                            New to MMA BetClub or need a refresher?
                          </h2>
                          <h3>
                            The instructions below will help you get going.
                          </h3>
                          <h3>
                            Not familiar with MMA betting/American odds? Check
                            out{" "}
                            <a
                              href="https://www.mmaodds.com/mma-betting/"
                              target="_blank"
                            >
                              this link
                            </a>{" "}
                            for more information before getting started.
                          </h3>
                          <h3>
                            Think you have a gambling problem?{" "}
                            <a
                              href="https://www.ncpgambling.org/programs-resources/resources/"
                              target="_blank"
                            >
                              Click here{" "}
                            </a>{" "}
                            for resources to help.
                          </h3>
                        </div>

                        <div className="row mb-20">
                          <div className="col-sm-12 col-lg-4 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                            <div className="mob-w-100 mb-5 mx-auto fs-2 text-dark fw-bold">
                              <h3>
                                <span className="number_steps">1.</span>Making
                                MMA BetClub Predictions
                              </h3>
                              <p className="fs-4">
                                Learn how to enter your Bets and Parlays
                              </p>
                            </div>
                            <iframe
                              width="300"
                              className="video_custom3"
                              // height="600"
                              src="https://www.youtube.com/embed/kwt6hLA5UQ4"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
                              allowfullscreen
                            ></iframe>
                          </div>

                          <div className="col-sm-12 col-lg-4 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                            <div className="mob-w-100 mb-5 mx-auto fs-2 text-dark fw-bold">
                              <h3>
                                <span className="number_steps">2.</span>
                                Resources for Research on MMA BetClub
                              </h3>
                              <p className="fs-4">
                                Tools to help you make your predictions
                              </p>
                            </div>
                           
                            <iframe
                              width="300"
                              className="video_custom3"
                              // height="600"
                              src="https://www.youtube.com/embed/w58qvqTQE_s"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </div>

                          <div className="col-sm-12 col-lg-4 ipad-pro-pe-15 ipad-pe-0 mb-10 mb-lg-0 text-center text-lg-start">
                            <div className="mob-w-100 mb-5 mx-auto fs-2 text-dark fw-bold">
                              <h3>
                                <span className="number_steps">3.</span>Private
                                Clubs on MMA BetClub
                              </h3>
                              <p className="fs-4">
                                Manage your League or Contest on the site
                              </p>
                            </div>
                            {/* <video
                              width="300"
                              className="video_custom3"
                              controls
                            >
                              <source src={myVideoMaking} type="video/mp4" />
                            </video> */}
                            <iframe
                              width="300"
                              // height="600"
                              className="video_custom3"
                              src="https://www.youtube.com/embed/g1GmwOpfmYg"
                              title="YouTube video player"
                              frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowfullscreen
                            ></iframe>
                          </div>
                        </div>

                        {emailExist ? (
                          <div className="text-center">
                            <Link
                              to="/myprofile"
                              className="btn btn-orange m-3 text-capitalize fw-bolder"
                            >
                              Go to My Profile
                            </Link>
                            {!userHasClub ? (
                              <Link
                                to="/"
                                className="btn btn-orange m-3 text-capitalize fw-bolder"
                              >
                                Create your Club
                              </Link>
                            ) : (
                              <Link
                                to="/"
                                className="btn btn-orange m-3 text-capitalize fw-bolder"
                              >
                                Go To My Clubs
                              </Link>
                            )}
                            <Link
                              to="/leaderboard"
                              className="btn btn-orange m-3 text-capitalize fw-bolder"
                            >
                              Go To Leaderboards
                            </Link>
                          </div>
                        ) : (
                          <div className="text-center">
                            <Link
                              to="/registration"
                              className="btn btn-lg btn-orange m-3 px-19"
                            >
                              <span className="indicator-label fw-bolder">
                                Sign Up
                              </span>
                            </Link>
                            <Link
                              to="/login"
                              type="submit"
                              className="btn btn-lg btn-orange m-3 px-20"
                            >
                              <span className="indicator-label fw-bolder">
                                Log In
                              </span>
                            </Link>
                          </div>
                        )}

                        {/* Disclaimer */}
                        <div className="text-center mb-15">
                          {/* <h3 className="fs-2hx text-dark mb-5">DISCLAIMER</h3> */}
                          <div className="w-75 mob-w-100 mx-auto fs-4 text-dark fw-bold">
                            DISCLAIMER :- MMA BetClub is a Fantasy MMA/fight
                            prediction site, and is NOT A GAMBLING SITE. No
                            money is paid out or collected for “bets” that are
                            made on the site.
                          </div>
                        </div>
                      </div>
                      {/*end::Content*/}
                    </div>
                    {/*end::Layout*/}
                    {/*begin::Card*/}
                    <div className="card mb-4 bg-light text-center">
                      <div className="card-body py-12">
                        <a
                          href="https://www.facebook.com/MMABetClub"
                          target="_blank"
                          rel="noreferrer"
                          className="mx-4"
                        >
                          <img src={Facebook} className="h-30px my-2" alt="" />
                        </a>
                        <a
                          href="https://www.instagram.com/mma_betclub/"
                          target="_blank"
                          rel="noreferrer"
                          className="mx-4"
                        >
                          <img src={Instagram} className="h-30px my-2" alt="" />
                        </a>
                        <a
                          href="https://twitter.com/MMA_Betclub"
                          target="_blank"
                          rel="noreferrer"
                          className="mx-4"
                        >
                          <img src={Twitter} className="h-30px my-2" alt="" />
                        </a>
                        <a
                          href="https://www.linkedin.com/company/mma-betclub"
                          target="_blank"
                          rel="noreferrer"
                          className="mx-4"
                        >
                          <img src={Linkedin} className="h-30px my-2" alt="" />
                        </a>
                        <a
                          href="https://www.tiktok.com/@big_joe_a"
                          target="_blank"
                          className="mx-4"
                        >
                          <img
                            src={Tiktok}
                            className="h-30px my-2"
                            alt="MMA Betclub Tiktok"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
const mapState = ({ userProfile }) => ({ _userProfile: userProfile });

export default connect(mapState, {})(PlayVideo);
