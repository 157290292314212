const LeftRightScroll = (slideRef:any) => {
  
  const slider=(direction:any)=>{
    if(direction==="left"){
      slideRef.slideRef.current.scrollTo({ left: slideRef.slideRef.current.scrollLeft - 100, behavior: 'smooth' })
    }else if(direction==="right"){  
      slideRef.slideRef.current.scrollTo({ left: slideRef.slideRef.current.scrollLeft + 100, behavior: 'smooth' })
    }
  }

  return (
    <div className="mt-1">
      <span  style={{ position: "sticky"}} className="d-flex text-dark fw-bolder d-flex justify-content-end align-items-center">
        <i onClick={()=>slider("left")} style={{fontSize:"2rem"}} className="cursorPointer left_right_buttons mx-1 h1 bi bi-arrow-left-circle-fill fa-lg" />
        <i onClick={()=>slider("right")} style={{fontSize:"2rem"}} className="cursorPointer left_right_buttons mx-1 h1 bi bi-arrow-right-circle-fill fa-lg mx-2" />
      </span>
    </div>
  )
}

export default LeftRightScroll