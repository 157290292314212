import Layout from "../../../../Common/Layout";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { connect } from "react-redux";
import { changePwd } from "../../../../Store/action/UserInfo.action";
import {
  initialValues_changePwd,
  validationSchema_changePwd,
} from "./Validation";
import PasswordStrengthBar from "../../../../Common/password-strength-bar";
import FormError from "../../../../Common/FormError/FormError";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";
import { useEffect, useState } from "react";

const ChangePassword = ({ $changePwd, _changePwd }: IChangePwdProps) => {
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [showPwdIs, setShowPwd] = useState<boolean>(false);
  const [showConfPwdIs, setShowConfPwd] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  const onSubmit = (values?: IChangePwd, actions?: any) => {
    setLoader(true);
    const data = { password: values?.newPassword };
    $changePwd(data);
    actions.resetForm();
  };

  const handleShowPwd = (status: string) => {
    if (status === "password") {
      setShowPwd(!showPwdIs);
    } else if (status === "newPassword") {
      setShowConfPwd(!showConfPwdIs);
    }
  };

  const getAlert = (response: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: response,
    });
  };

  useEffect(() => {
    const changePwdData = _changePwd.changePwdIs;
    const pwdErr = _changePwd.changePwdErrIs;
    if (changePwdData?.status === 1 && LoadingIs) {
      getAlert(changePwdData?.message, "alert-success");
      setLoader(false);
    } else if (pwdErr && LoadingIs) {
      getAlert(changePwdData?.message, "alert-danger");
      setLoader(false);
    }
  }, [_changePwd]);

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 1000);
    }
  }, [alert]);

  return (
    <Layout options="fill_Header" uservisible="visible">
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto dark-card-bg">
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={initialValues_changePwd}
                  validationSchema={validationSchema_changePwd}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      <div className="text-center mb-10">
                        <h1 className="text-white mb-3">Change password</h1>
                      </div>
                      {/* <div className="fv-row mb-10">
                    <label className="form-label fs-6 fw-bolder text-dark">
                      Current Password
                    </label>
                    <div className="position-relative mb-3">
                    <Field
                      className={`form-control form-control-lg form-control-solid ${(formik.touched.password && formik.errors.password) && `border border-danger`}`}
                      type={`${showPwdIs ? "text" : "password"}`}
                      name="password"
                      id="password"
                      autoComplete="off"
                      onChange={(e:any)=>e.target.value.length < 41 ? formik.handleChange(e) : null}
                    />                    
                    <span
                      className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                      data-kt-password-meter-control="visibility"
                    >
                        <i className={`bi ${showPwdIs ? `bi-eye-slash` : `bi-eye`} fs-2`} onClick={() => handleShowPwd("password")}/>
                    </span>
                    </div>
                      <ErrorMessage
                            name="password"
                            render={msg => <FormError classes="d-flex mb-3" message={msg}></FormError>}
                            />         
                  </div>                   */}
                      <div
                        className="mb-10 fv-row"
                        data-kt-password-meter="true"
                      >
                        <div className="mb-1">
                          <label className="form-label fw-bolder text-white fs-6">
                            New Password
                          </label>
                          <div className="position-relative mb-3">
                            <Field
                              className={`modern-login-form-input form-control form-control-lg form-control-solid ${
                                formik.touched.newPassword &&
                                formik.touched.newPassword &&
                                `border border-danger`
                              }`}
                              type={`${showConfPwdIs ? `text` : `password`}`}
                              placeholder=""
                              name="newPassword"
                              id="newPassword"
                              autoComplete="off"
                              onChange={(e: any) =>
                                e.target.value.length < 41
                                  ? formik.handleChange(e)
                                  : null
                              }
                            />
                            <span
                              className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                              data-kt-password-meter-control="visibility"
                            >
                              <i
                                className={`bi ${
                                  showConfPwdIs ? `bi-eye-slash` : `bi-eye`
                                } fs-2`}
                                onClick={() => handleShowPwd("newPassword")}
                              />
                            </span>
                          </div>
                          <ErrorMessage
                            name="newPassword"
                            render={(msg) => (
                              <FormError
                                classes="d-flex mb-3"
                                message={msg}
                              ></FormError>
                            )}
                          />
                          <PasswordStrengthBar
                            password={formik.values.newPassword}
                          />
                        </div>
                        <div className="text-muted">
                          Use 8 or more characters with a mix of letters,
                          numbers &amp; symbols.
                        </div>
                      </div>
                      <div className="fv-row mb-10">
                        <label className="form-label fs-6 fw-bolder text-white">
                          Confirm New Password
                        </label>
                        <div className="position-relative mb-3">
                          <Field
                            className={`modern-login-form-input form-control form-control-lg form-control-solid ${
                              formik.touched.confirmNewPwd &&
                              formik.errors.confirmNewPwd &&
                              `border border-danger`
                            }`}
                            type={`${showPwdIs ? "text" : "password"}`}
                            name="confirmNewPwd"
                            id="confirmNewPwd"
                            autoComplete="off"
                            onChange={(e: any) =>
                              e.target.value.length < 41
                                ? formik.handleChange(e)
                                : null
                            }
                          />
                          <span
                            className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility"
                          >
                            <i
                              className={`bi ${
                                showPwdIs ? `bi-eye-slash` : `bi-eye`
                              } fs-2`}
                              onClick={() => handleShowPwd("password")}
                            />
                          </span>
                        </div>
                        <ErrorMessage
                          name="confirmNewPwd"
                          render={(msg) => (
                            <FormError
                              classes="d-flex mb-3"
                              message={msg}
                            ></FormError>
                          )}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Update`
                            )}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
const mapState = ({ changePwd }: any) => {
  return {
    _changePwd: changePwd,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $changePwd: (values: IChangePwdArgu) => dispatchEvent(changePwd(values)),
});
export default connect(mapState, mapDispatch)(ChangePassword);
