import React, { useEffect, useState } from "react";
import Spinner from "../../../../Common/Spinner/Spinner";
import { getFiveEventsTotal, getFiveEventsBetWin, getFiveEventsParlayWin, getRoiAverage } from "../../Club/AllEventCalculation/Calculation";

const LastFiveEvent = (props ?: any) => {

    const { eventsData, LoadingIs } = props;
    const [ noDataFound, setNoDataFound ] = useState(false)

    useEffect(() => {
        setTimeout(() => setNoDataFound(true), 5000)
    }, [])

    return (
        <div className="card mb-10 dark-card-bg">
            <div className="card-header dark-card-bg">
                <div className="card-title flex-column m-0">
                    <h3 className="fw-bolder mb-0 text-orange">Last 5 Events</h3>
                </div>
            </div>
            <div className="card-body d-flex flex-column">
                {LoadingIs 
                ?
                ( noDataFound ? "No Data Found" : <Spinner color="text-black spinner-border-sm " />)
                : 
                <React.Fragment>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Total Points
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">
                        {
                            !isNaN(props?.last5EventDataNew?.last5TotalPoints) && props?.last5EventDataNew?.last5TotalPoints !== null
                            ?
                            props?.last5EventDataNew?.last5TotalPoints
                            :
                            "-"
                        }
                    </div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Bet Win %
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{!isNaN(props?.last5EventDataNew?.last5BetWinPercent) ? props?.last5EventDataNew?.last5BetWinPercent + "%" : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Parlay Win %
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{!isNaN(props?.last5EventDataNew?.last5ParleyWinPercent) ? props?.last5EventDataNew?.last5ParleyWinPercent + "%" : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span className={`fs-6 fw-bold text-white text-hover-muted`}  >
                            Margin
                        </span>
                    </div>
                    <div className={`badge ms-auto  ${ !isNaN(props?.last5EventDataNew?.last5EventMargin) ? (props?.last5EventDataNew?.last5EventMargin > 0 ? "badge-success" : props?.last5EventDataNew?.last5EventMargin < 0 ? "badge-orange" : "badge-light") : "badge-light" }`}>{ !isNaN(props?.last5EventDataNew?.last5EventMargin) ? "$"+(new Intl.NumberFormat().format(props?.last5EventDataNew?.last5EventMargin)) : "-" }</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            ROI
                        </span>
                    </div>
                    {
                        !isNaN(props?.last5EventDataNew?.last5AverageROI) && props?.last5EventDataNew?.last5AverageROI !== null 
                        ? 
                        <div className={`badge ${props?.last5EventDataNew?.last5AverageROI > 0 ? `badge-success` : props?.last5EventDataNew?.last5AverageROI < 0 ? "badge-orange" : "badge-light"} ms-auto`}>
                        { props?.last5EventDataNew?.last5AverageROI + "%" }
                        </div> 
                        :
                        <div className={`badge badge-light ms-auto`}>-</div>
                    }
                </div>
                </React.Fragment>}
            </div>
        </div>
    )
}
export default LastFiveEvent
