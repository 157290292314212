const Spinner = (props?:any) =>{
    return (
     <div style={{minHeight:100}} className="d-flex justify-content-center align-items-center">
        <div
        className={`spinner-border  ${props.color}`}
        role="status"
        style={{ margin: "", height:25,width:25 }}
      >
        <span className="sr-only">Loading...</span>
      </div>
     </div>
    );
  }
  
  export default Spinner;
  export const ButtonSpinner = (props?:any) =>{
    return (
        <div
        className={`spinner-border  ${props.color}`}
        role="status"
        style={{ margin: ""}}
      >
        <span className="sr-only">Loading...</span>
      </div>
    );
  }