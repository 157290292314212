import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../../../Common/Header";
import { connect } from "react-redux";
import { inviteFrnds } from "../../../../Store/action/UserInfo.action";
import {BaseUrl} from "../../../../Utils/BaseUrl"
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FieldArray,
  useFormik,
  FormikValues,
} from "formik";
import Red from "../../../../assets/images/logos/Red.png";
import {
  initialValues_inviteFrnd,
  validationSchema_inviteFrnd,
} from "./Validation";
import FormError from "../../../../Common/FormError/FormError";
import { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import Alert from "../../../../Common/Alert/Alert";
import axios from "axios";
import { array } from "yup/lib/locale";
import { ValueContainerProps } from "react-select";

const InviteFriends = ({ $inviteFrnds, _inviteFrnds }: IinviteFrndProps) => {
  const history = useHistory<any>();
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [alert, setAlert] = useState<IAlertIs>({
    activeIs: false,
    statusIs: "",
    msgIs: "",
  });

  const [ExistIs, setExist] = useState<any[]>([]);

  const onSubmitWebsite = (values: IinviteFrnd, actions: any) => {
    const data = values.emails;

    if (values.emails) {
      axios
      .post(`${BaseUrl}/user/inviteUserExist`, {
          email: data,
        })
        .then((res) => {
          if (res.status === 200) {
            if (!res?.data?.anyFoundUser) {
              if (history?.location?.state?.invitationType) {
                values = {
                  ...values,
                  invitationType: history?.location?.state?.invitationType,
                };
              }
              $inviteFrnds(values);
              setLoader(true);
              setTimeout(() => {
                actions.resetForm();
              }, 3000);
            } else {
              setExist(res.data.users);
            }
          }
          // $inviteFrnds(values);
          // setLoader(true);
          // setTimeout(() => {
          //   actions.resetForm();
          // }, 3000);
          // }
        });
    }
  };

  const onSubmitClub = (values: IinviteFrnd, actions: any) => {
    const clubId = history?.location?.state?.clubId
    axios.post(`${BaseUrl}/user/getInviteClubUserExist`, {...values, clubId}, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`
      }
    }).then((res) => {
      if (res.status === 200) {
        if (!res?.data?.anyFoundUser) {
          if (history?.location?.state?.invitationType) {
            values = {
              ...values,
              invitationType: history?.location?.state?.invitationType,
            };
          }
          $inviteFrnds(values);
          setLoader(true);
          setTimeout(() => {
            actions.resetForm();
          }, 3000);
        } else {
          setExist(res.data.users);
        }
      }
    })
  };

  const getAlert = (message: string, status: string) => {
    setAlert({
      activeIs: true,
      statusIs: status,
      msgIs: message,
    });
  };

  useEffect(() => {
    const data = _inviteFrnds.inviteFrndIs;
    const dataErr = _inviteFrnds.inviteFrndErrIs;
    if (data?.status === 1 && LoadingIs) {
      getAlert(data.message, "alert-success");
      setLoader(false);
    } else if (dataErr && LoadingIs) {
      getAlert(dataErr?.message, "alert-danger");
      setLoader(false);
    }
  }, [_inviteFrnds]);

  useEffect(() => {
    if (alert.activeIs) {
      setTimeout(() => {
        setAlert({ activeIs: false, statusIs: "", msgIs: "" });
      }, 3000);
    }
  }, [alert]);

  return (
    <React.Fragment>
      <Header options="fill_Header" uservisible="visible" />
      <div className="main-content">
        <div className="account_pages d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
          {/* <div className="logo-section text-center mb-5">
                <img className="rounded-circle" style={{width:"80%"}}
                  src={history?.location?.state?.invitationType===1?history?.location?.state?.logo:Red}
                  alt="..."
                />
              </div> */}



          <div className="container">
            <div className="row">
              <div className="w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto">
              <div className="logo-section text-center mb-0" style={{ backgroundColor: "#fff" }}>
              <img style={{ width: "80%" }} src={Red} alt="..." />
            </div>
                {alert.activeIs && (
                  <Alert
                    statusIs={`alert ${alert.statusIs}`}
                    message={alert.msgIs}
                  />
                )}
                <Formik
                  initialValues={initialValues_inviteFrnd}
                  validationSchema={validationSchema_inviteFrnd}
                  onSubmit={
                    history?.location?.state?.invitationType === 1
                      ? onSubmitClub
                      : onSubmitWebsite
                  }
                  validateOnMount
                >
                  {(formik) => (
                    <Form
                      className="form w-100"
                      noValidate
                      id="kt_sign_in_form"
                      action="#"
                    >
                      {history?.location?.state?.invitationType === 1 ? (
                        <div className="text-center mb-10">
                          <h1 className="text-dark mb-3">
                            Invite Friends to Join Your Club
                          </h1>
                          <p className="text-gray-400 fw-bold fs-4">
                            Want to add friends to your Club? Invite them below!
                          </p>
                        </div>
                      ) : (
                        <div className="text-center mb-10">
                          <h1 className="text-dark mb-3">
                            Invite Friends to Sign Up
                          </h1>
                          <p className="text-gray-400 fw-bold fs-4">
                            Think your friends would love MMA BetClub...
                          </p>
                        </div>
                      )}
                      <div className="fv-row mb-10">
                        <FieldArray
                          name="emails"
                          render={(arrayHelpers) => (
                            <React.Fragment>
                              <label className="form-label fs-6 fw-bolder text-dark">
                                Email Address
                              </label>
                              {formik.values.emails.map((obj, index) => (
                                <>
                                  <div className="fv-row mb-5" key={index}>
                                    <Field
                                      className={`form-control form-control-lg form-control-solid 
                                                            ${
                                                              formik.touched
                                                                .emails
                                                                ?.length &&
                                                              formik.touched
                                                                .emails[
                                                                index
                                                              ] &&
                                                              formik.errors
                                                                .emails
                                                                ?.length &&
                                                              formik.errors
                                                                .emails[index]
                                                                ? `border border-danger`
                                                                : ""
                                                            }
                                                            `}
                                      type="email"
                                      placeholder="Email Id"
                                      name={`emails.${index}.emailId`}
                                      id={`emails.${index}.emailId`}
                                      autoComplete="off"
                                    />
                                    {ExistIs?.find(
                                      (e: any) =>
                                        e.email === obj.emailId &&
                                        e.found === true
                                    ) ? (
                                      <p className="text-danger">
                                        {
                                          history?.location?.state?.invitationType === 1
                                          ?
                                          "This user already a member of this club."
                                          :
                                          "User already exists. You can directly invite existing users to your Club."
                                        }
                                        
                                      </p>
                                    ) : (
                                      ""
                                    )}

                                    <ErrorMessage
                                      name={`emails.${index}.emailId`}
                                      render={(msg) => (
                                        <FormError message={msg}></FormError>
                                      )}
                                    />
                                  </div>

                                  {formik.values.emails.length - 1 >= 0 
                                  
                                    ? (
                                    <div className="fv-row mb-5 text-end">
                                      {index < 14 &&
                                        formik.values.emails.length - 1 ===
                                          index && (
                                          <label
                                            className="form-label fs-6 fw-bolder text-dark cursor-pointer"
                                            onClick={() =>
                                              arrayHelpers.push({ emailId: "" })
                                            }
                                          >
                                            <i className="bi bi-plus-circle fs-3 me-1 text-dark"></i>{" "}
                                            Add more
                                          </label>
                                        )}

                                      {formik.values.emails.length > 1 &&
                                      index > 0 ? (
                                        <label
                                          htmlFor="removelast"
                                          className="form-label fs-6 fw-bolder text-dark cursor-pointer mx-4"
                                          onClick={() =>
                                            arrayHelpers.remove(index)
                                          }
                                        >
                                          <i
                                            id="removelast"
                                            className="bi bi-trash fs-3 me-1 text-dark "
                                          ></i>{" "}
                                          Remove
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </>
                              ))}
                            </React.Fragment>
                          )}
                        />
                      </div>
                      <div className="text-center">
                        <button
                          type="submit"
                          disabled={!formik.isValid}
                          id="kt_sign_in_submit"
                          className="btn btn-lg btn-orange w-100 mb-5"
                        >
                          <span className="indicator-label">
                            {LoadingIs ? (
                              <ButtonSpinner color="text-light  spinner-border-sm " />
                            ) : (
                              `Send Invitation Code`
                            )}
                          </span>
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapState = ({ inviteFrnds }: any) => {
  return {
    _inviteFrnds: inviteFrnds,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $inviteFrnds: (values?: IinviteFrnd) => dispatchEvent(inviteFrnds(values)),
});
export default connect(mapState, mapDispatch)(InviteFriends);
