import { useEffect, useState } from "react"

const PasswordStrengthBar = ({ password }: IPasswordStrengthBar) => {
    const [strength, setStrength] = useState(0)
    useEffect(() => {

        const lengthCheck = password.length > 7;
        const hasNumber = /\d/.test(password);
        const hasString = /[a-z]/i.test(password);
        const hacSepcialChar = /[ `! @#$%^&*()_+\-=\[\]{};':"\\|,. <>\/?~]/.test(password);
        const strength =
            (lengthCheck ? 1 : 0) +
            (hasNumber ? 1 : 0) +
            (hasString ? 1 : 0) +
            (hacSepcialChar ? 1 : 0)

        setStrength(strength)
    }, [password])
    return (
        <div
            className="d-flex align-items-center mb-3" 
        >
            <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${strength > 0 ? 'active' : ''}`} />
            <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${strength > 1 ? 'active' : ''}`} />
            <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2 ${strength > 2 ? 'active' : ''}`} />
            <div className={`flex-grow-1 bg-secondary bg-active-success rounded h-5px ${strength > 3 ? 'active' : ''}`} />
        </div>
    )
}

export default PasswordStrengthBar