import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { clubStandings } from "../../../../Store/action/Events.action";
import StandingTable from "./StandingTable";
import { customStyles } from "../AllEventCalculation/Calculation";
import Select from "react-select";
import Spinner from "../../../../Common/Spinner/Spinner";
import LeftRightScroll from "../LeftRightScroll";
import axios from "axios";
import { BaseUrl } from "../../../../Utils/BaseUrl";
import { useParams } from "react-router-dom";

const Standings = (props?: any) => {
  const clubId = props?.match?.params?.id;
  const [standingList, setStandingList] = useState<any>([]);
  const [LoadingIs, setLoadingIs] = useState<boolean>(false);
  const [sortingIs, setSorting] = useState<boolean>(false);
  const [activeSortName, setActiveSort] = useState<string>("totalPoints");
  const [selectedOption, setSelectedOption] = useState<any>();
  const [eventDate, seteventDate] = useState<any>([]);
  var newDate = new Date().getFullYear();
  const [quarter, setQuarter] = useState<any>({ quarter: 0, newYear: newDate });
  const slideRef = React.useRef() as React.MutableRefObject<HTMLTableElement>;
  const { screenName } = useParams<any>();
  const [active, setActive] = useState(null);

  const options = [
    { value: "2022", label: "2022" },
    //{ value: '2021', label: '2021' },
  ];
  useEffect(() => {
    const token = localStorage.getItem("token");

    axios
      .get(`${BaseUrl}/user/getMyStatQuarter?screenName=${screenName}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // console.log(response.data)
        let newData = response.data.data?.event_quarter;
        // console.log(newData)
        setQuarter({ quarter: 0, newYear: newData[0]?.event_year})
        // newData.map((item: any) => {
        //   if (quarter.newYear !== item.event_year) {
        //     setQuarter({ quarter: 0, newYear: quarter.newYear});
        //   }
        // });
        seteventDate(response.data.data?.event_quarter);
      });
  }, []);
  useEffect(() => {
    if (clubId) {
      setLoadingIs(true);
      setSelectedOption(options[0]);
      const data = { clubId, year: options[0].value, quarter };
      props.$clubStandings(data);
    }
  }, [quarter]);

  const handleChange = (data: any) => {
    setLoadingIs(true);
    const dataObj = { clubId, year: data.value, quarter };
    props.$clubStandings(dataObj);
    setSelectedOption(data);
  };

  useEffect(() => {
    const standingsIs = props._clubStandings.standingsDataIs;
    if (standingsIs?.status === 1) {
      setLoadingIs(false);
      setStandingList(standingsIs?.data);
    }
  }, [props._clubStandings]);

  return (
    <div className="accordion mb-15 shadow-xs" id="kt_accordion_1">
      <div className="accordion-item">
        <h2 className="accordion-header" id="kt_accordion_1_header_1">
          <button className="accordion-button accordion-button-cu bg-white flex-wrap text-dark fw-bolder fs-2">
            Standings
            {/* <div className="dropdown" style={{marginLeft: "20px"}} >
                          <button type="button" className="btn dropdown-toggle btn-quater" data-toggle="dropdown">
                          {quarter.quarter >= 1 ? `Q${quarter.quarter} ${quarter.newYear}` :"All time"}
                          </button>
                          <div className="dropdown-menu">
                          

                            {eventDate?.reverse()?.map((el:any)=>{
                              return <>
                              
                              <div className="dropdown-item"  style={{fontWeight:"bold",color:"#ffffff",background: "#181c32"}} onClick={()=>{setQuarter({quarter:0,newYear:el?.event_year})}} >{el?.event_year} (All)</div>

                              {el?.event_quarter?.map((x:any)=>{
                                  return<> <div className="dropdown-item" onClick={()=>{setQuarter({quarter:x,newYear:el?.event_year}) }}>Q{x} {el?.event_year}</div>
                                  </>
                              })}
                              
                              </>
                            })}
                          
                            <div className="dropdown-item" style={{fontWeight:600}} onClick={()=>{setQuarter({quarter:0,newYear:0})}}>All Time</div>
                          </div>
                </div> */}
            <div className="dropdown right_side" style={{ marginLeft: "20px" }}>
              <button type="button" className="btn dropdown-toggle btn-quater" data-toggle="dropdown" style={{ minWidth: 130 }}>
                {quarter && quarter.quarter >= 1
                  ? `Q${quarter.quarter} ${quarter.newYear}`
                  : quarter.newYear >= 2022
                  ? `${quarter.newYear} ${quarter?.newYear == 2023 ? "(YTD)" : "(All)"}`
                  : "All time"}
              </button>
              <div className="dropdown-menu custm_drop custm_scrollbar">
                <div
                  className="dropdown-item vv"
                  style={{ fontWeight: 600, width: "10rem" }}
                  onClick={() => {
                    setQuarter({ quarter: 0, newYear: 0 });
                  }}
                >
                  All Time
                </div>
                {eventDate?.map((el: any) => {
                  return (
                    <>
                      <div
                        className={`dropdown-item vv ${active == el && "active"}`}
                        style={{ fontWeight: "bold", width: "10rem" }}
                        onClick={() => {
                          setQuarter({ quarter: 0, newYear: el?.event_year });
                          setActive(el);
                        }}
                      >
                        {el?.event_year} {el?.event_year == 2023 ? "(YTD)" : "(All)"}
                      </div>

                      {el?.event_quarter?.map((x: any) => {
                        return (
                          <>
                            {" "}
                            <div
                              className={`dropdown-item vv ${active == x && "active"}`}
                              onClick={() => {
                                setQuarter({ quarter: x, newYear: el?.event_year });
                                setActive(x);
                              }}
                            >
                              Q{x} {el?.event_year}
                            </div>
                          </>
                        );
                      })}
                    </>
                  );
                })}
              </div>
            </div>
            {/* <span style={{right: "30px"}} className="right_side text-dark fw-bolder fs-5 d-flex justify-content-center align-items-center">
              
                <span style={{minWidth:250}}  className="table_input_wrapper text-muted cu-react-select-size-small ms-3">
                  <Select options={options} onChange={handleChange} value={selectedOption} styles={customStyles} menuPortalTarget={document.body} />
                </span>
                
              </span> */}
            <span className="accordion-button" data-bs-toggle="collapse" data-bs-target="#kt_accordion_1_body_1" aria-expanded="true" aria-controls="kt_accordion_1_body_1"></span>
          </button>
        </h2>

        <div id="kt_accordion_1_body_1" className="accordion-collapse collapse show" aria-labelledby="kt_accordion_1_header_1" data-bs-parent="#kt_accordion_1">
          <div className="accordion-body pt-0">
            <div id="kt_subscriptions_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
              <div className="table-responsive table_wrapper" ref={slideRef}>
                {standingList?.length > 0 ? (
                  <StandingTable
                    LoadingIs={LoadingIs}
                    standingList={standingList}
                    setStandingList={setStandingList}
                    sortingIs={sortingIs}
                    setSorting={setSorting}
                    activeSortName={activeSortName}
                    setActiveSort={setActiveSort}
                  />
                ) : (
                  <Spinner color="text-black spinner-border-sm " />
                )}
              </div>
            </div>
          </div>
          <div className="mb-3">
            <LeftRightScroll slideRef={slideRef} />
          </div>
        </div>
      </div>
    </div>
  );
};
// export default Standings
const mapState = ({ clubStandings }: any) => {
  return {
    _clubStandings: clubStandings,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $clubStandings: (values?: IclubForAllbetPayload) => dispatchEvent(clubStandings(values)),
});
export default connect(mapState, mapDispatch)(withRouter(Standings));
