import { Link, useHistory } from "react-router-dom";
import Modal from "react-modal";
// import DummyUser from '../../../../assets/images/avatars/150-1.jpg';
import EmptyImage from "../../../../assets/images/avatars/blank.png";
import Layout from "../../../../Common/Layout";
import { connect } from "react-redux";
import { BaseUrl } from "../../../../Utils/BaseUrl";
import {
  getClubDetails,
  joiningClubDetails,
  joinClub,
} from "../../../../Store/action/UserInfo.action";
import { useEffect, useState } from "react";
import Spinner, { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import axios from "axios";
import ClubDetails from "../ClubDetails/ClubDetails";
import { forEach } from "lodash";
import { getApi } from "../../../../Store/api-interface/api-interface";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseConfig } from "../../../../firebaseConfig";
import ReactGA from 'react-ga'
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "500px",
    width: "100%",
  },
};

const MemberList = (props?: any) => {
  const _chatmessages = props;
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const {
    $getClubDetails,
    _getClubDetails,
    $joinClub,
    _joinClub,
    $joiningClubDetails,
    _joiningClubDetails,
    _userProfile,
  }: IMemberListProps = props;

  const userProfile: any = _userProfile.userDataIs.data;
  const [userId, setUserId] = useState();
  const userInvitations = _userProfile?.getClubInvitationIs || [];
  const codeIs = props?.match?.params?.code ? props?.match?.params?.code : "";
  const [LoadingIs, setLoader] = useState<boolean>(false);
  const [clubLoader, setClubLoader] = useState<boolean>(false);
  const [ModalIs, setModal] = useState<boolean>(false);
  const [cancelModaIs, setCancelModalIs] = useState<boolean>(false);
  const [joinLoader, setJoinLoader] = useState<boolean>(false);
  const [JoiningClubIs, setClubDetails] = useState<any>([]);
  const [confirmClubData, setConfirmClubData] = useState<any>({});
  const [inviteLoader, setInviteLoader] = useState<boolean>(false);
  const [messageList, setMessageList] = useState<any>([]);
  const [joinModal, setJoinModal] = useState<boolean>(false)
  const [inviteCode, setInviteCode] = useState<any>()
  const [message, setMessage] = useState<String>()
  const [error, setError] = useState<string>("")
  const [ListIs, setList] = useState<IListObj[] | []>([
    {
      clubId: 0,
      isOwner: false,
      name: "",
      totalMember: "",
      clubPic: "",
      lastMessageSeen: true
    },
  ]);

  const joinClub = (obj:any) => {
    setJoinModal(true)
    ReactGA.event({
      category: 'ClubList',
      action: 'JoinclubWithCode',
     
    });
  }
  const handleJoinClubInvitation = (obj:any) => {
    setJoinLoader(true);
    setInviteLoader(true)
    $joinClub({ code: inviteCode });
  }
  const getConfirmation = (obj: any) => {
    setModal(true);
    setConfirmClubData(obj);
  }; 

  const getCancellation = (obj: any) => {
    setCancelModalIs(true);
    setConfirmClubData(obj);
  };

  const handleCancel = () => {
    setInviteLoader(true);
    axios
      .post(`${BaseUrl}/user/removeInvite`, confirmClubData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((data) => {
        setModal(false);
        setCancelModalIs(false);
        setConfirmClubData({});
        setInviteLoader(false);
        $getClubDetails();
      });
  };

  useEffect(() => {
    const error = props._joinClub.joinClubIs;
    let isError = false
    if(error?.status == 0){
      isError = true
    }
   if(isError){
    setError(error.message)
    setTimeout(() => {
      setJoinLoader(false);
      setModal(false)
      setError("")
     },1500)
   }
  },[props._joinClub.joinClubIs])

  const handleJoinClub = () => {
    if (confirmClubData) {
      setJoinLoader(true);
      $joinClub({ code: confirmClubData.clubInviteCode });
    }
  };

  const closeModal = () => {
    setJoinModal(false)
    setModal(false)
  };

  useEffect(() => {
    $getClubDetails();
    setLoader(true);
  }, []);

  useEffect(() => {
    setUserId(userProfile?.id);
  }, [ListIs]);

  const getClubResponse = (data: IListObj[]) => {
    setList(data);
  };

  useEffect(() => {
    const memberListIs = _getClubDetails.getClubDetailsIs;
    const invitedEmailIs = props.match.params?.email;
    if (memberListIs?.status === 1 && LoadingIs) {
      if (userProfile?.email) {
        if (
          userProfile?.email === invitedEmailIs ||
          invitedEmailIs === undefined
        ) {
          setTimeout(() => {
            getClubResponse(memberListIs?.data);
            setLoader(false);
          }, 1000);
        } else if (userProfile?.email !== invitedEmailIs) {
          localStorage.removeItem("token");
          props?.histrory?.push("/login");
          window.location.reload();
        }
      }
    }
  }, [_getClubDetails, _userProfile]);

  useEffect(() => {
    if (codeIs) {
      $joiningClubDetails({ inviteCode: codeIs });
      setClubLoader(true);
      props?.history?.push("/clublist");
    }
  }, []);

  const getJoiningDetails = (data: IresClubDta[]) => {
    setClubDetails(data);
  };

  useEffect(() => {
    const data = _joiningClubDetails.joiningClubDetailsIs;
    if (data?.status === 1) {
      setClubLoader(false);
      setInviteLoader(false)
      getJoiningDetails(data?.data);
    } else {
      setInviteLoader(false)
    }
  }, [_joiningClubDetails]);

  useEffect(() => {
    const joinData = _joinClub.joinClubIs;
    if(joinData?.status === 0){
      setMessage(joinData.message)
      setTimeout(() => setMessage(""), 2000)
      setJoinLoader(true);
    }
    // const joinErr = props._joinClub.joinClubErrIs
    if (joinData?.status === 1 && joinLoader) {
      setTimeout(() => {
        $getClubDetails();
        setLoader(true);
      }, 1000);
      setJoinLoader(false);
      setModal(false);
      props?.history?.go(0);
    }
  }, [_joinClub]);

  const history = useHistory();

  return (
    <Layout options="fill_Header" uservisible="visible">
      {/* joining club details  */}
      <div className="main-content">
        <div className="container">
          <div className="row">
           <div className="col-sm-12 d-flex justify-content-end text-center pt-20">  
            <span className="btn btn-orange fw-bolder" onClick = {joinClub}>
                    Join a Club with code
            </span>&nbsp;&nbsp;
            {
              userProfile && !userProfile?.hasClub 
              ?
              <Link to="/" className="btn btn-orange text-capitalize fw-bolder" 
              onClick={() => {
                // logEvent(analytics, "CreateYourClube ClubList_page", {
                //   email: userProfile.email,
                // });
                ReactGA.event({
                  category: 'ClubList',
                  action: 'CreateYourClube',
                  transport:userProfile.email
                });
              }}
              > Create your Club </Link>
              :
              ""
            }
            </div>
            {userInvitations?.length > 0 && (
              <div className="col-sm-12 pt-15">
                <div className="card card-xl-stretch mb-5 mb-xl-8 shadow-xs">
                  <div className="card-header border-1 border-secondary pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bolder fs-3 mb-1">
                        Join Club Request
                      </span>
                    </h3>
                    {/* <div className="card-toolbar">
                      <span className="fw-bolder fs-3">Members</span>
                    </div> */}
                  </div>
                  <div className="card-body px-8 py-0">
                    <div className="table-responsive custom_table_member">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        {clubLoader ? (
                          <Spinner color="text-black spinner-border-sm " />
                        ) : (
                          <tbody>
                            {userInvitations?.map((obj: any, index: number) => (
                              <tr className="bg-table-row-1" key={index}>
                                <td className="w-350px" colSpan={5}>
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-35px me-5"> 
                                      <img
                                        src={
                                          obj?.clubPic
                                            ? ImageUrl + obj?.clubPic
                                            : EmptyImage
                                        }
                                        alt="..."
                                      />
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                      <Link
                                        to="#!"
                                        className="text-white fw-bolder fs-6"
                                      >
                                        {obj?.clubName}
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                                <td></td>
                                <td className="d-flex justify-content-end">
                                  <span className="text-white fw-bold d-flex fs-6 m-2">
                                    <div
                                      className="badge badge-primary ms-auto club-confirm btn btn-success"
                                      onClick={() => getConfirmation(obj)}
                                    >
                                      Confirm
                                    </div>
                                  </span>
                                  <span className="text-white fw-bold d-flex fs-6 m-2">
                                    <div
                                      className="badge badge-primary ms-auto club-confirm btn btn-orange"
                                      onClick={() => getCancellation(obj)}
                                    >
                                      Decline
                                    </div>
                                  </span>
                                </td>
                                {/* <td className="text-end">
                                  <label className="text-white fw-bold d-block fs-6">
                                    {obj.totalMember}
                                  </label>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="main-content pt-0">
        <div className="container club-page">
          <div className="row">
            <div className="col-sm-12 pt-15">
              <div className="card card-xl-stretch mb-5 mb-xl-8 shadow-xs dark-card-bg ">
                <div className="card-header border-1 border-secondary pt-5 dark-card-bg">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1 text-white">Name</span>
                  </h3>
                  <div className="card-toolbar">
                    <span className="fw-bolder fs-3 text-white">Members</span>
                  </div>
                </div>
                <div className="card-body px-8 py-0">
                  <div className="table-responsive custom_table_member">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      {LoadingIs ? (
                        <Spinner color="text-black spinner-border-sm " />
                      ) : (
                        <tbody>
                          {/* {console.log("list", ListIs)} */}
                          {ListIs.length > 0 ? (
                            ListIs.map((obj: IListObj, index: number) => (
                              <tr className="bg-table-row-1" key={index}>
                                <td className="w-350px">
                                  <div className="d-flex align-items-center">
                                    <div className="symbol symbol-35px me-5">
                                      <img
                                        src={
                                          obj.clubPic
                                            ? ImageUrl + obj?.clubPic
                                            : EmptyImage
                                        }
                                        alt="..."
                                      />
                                    </div>

                                    <div className={`d-flex justify-content-end`} >
                                      <Link to={`/clubdetails/${obj.clubId}`} className="text-white fw-bolder fs-6" >
                                        {obj.name}
                                        <sup>
                                          {
                                            obj?.lastMessageSeen === false 
                                            ? 
                                            <i className="mx-1 bi bi-chat-left-dots-fill" style={{color: "#F63715"}} data-toggle="tooltip" data-placement="bottom" title="New messages in this club." />
                                            : 
                                            ""
                                          }
                                        </sup>
                                      </Link>
                                    </div>
                                  </div>
                                </td>

                                <td>
                                  <span className="text-white fw-bold d-block fs-6">
                                    {obj.isOwner ? `Owner` : `Member`}
                                  </span>
                                </td>
                                <td className="text-end">
                                  <label className="text-white fw-bold d-block fs-6">
                                    {obj.totalMember}
                                  </label>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td>No record Found</td>
                            </tr>
                          )}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      <Modal isOpen={ModalIs} style={customStyles} onRequestClose={closeModal} ariaHideApp={false} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Do you want to join this club?</h3>
          <div>
            {error && (
              <div className="alert alert-danger mt-5 text-center" role="alert">
                {error}
              </div>
            )}
            </div>

          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange m-2 w-100 mb-5"
              onClick={handleJoinClub}
            >
              <span className="indicator-label fw-bolder">
                {joinLoader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center btn-light m-2 btn-lg w-100 mb-5"
              onClick={() => setModal(false)}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
      {/* Cancel Modal */}
      <Modal
        isOpen={cancelModaIs}
        style={customStyles}
        contentLabel="Example Modal"
        onRequestClose={closeModal}
      >
        <div>
          <h3 className="text-center p-5">
            Do you want to cancel this request?
          </h3>
          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange w-100 m-2 mb-5"
              onClick={handleCancel}
              disabled={inviteLoader}
            >
              <span className="indicator-label fw-bolder">
                {inviteLoader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center btn-light m-2 btn-lg w-100 mb-5"
              onClick={() => setCancelModalIs(false)}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>

      {/* Join Club Modal */}
      <Modal isOpen={joinModal} onRequestClose={closeModal} style={customStyles} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Enter Club Invite Code </h3>
          {message ? <h6 className="text-center d-flex text-danger align-items-center justify-content-center">{message}</h6> : ""}
          <input type="text" className="form-control" id="exampleInputEmail2" placeholder="Invitation Code" value = {inviteCode}  onChange={(e)=>setInviteCode(e.target.value)}/>
          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange m-5 w-100 mb-5"
              disabled={inviteLoader}
              onClick={handleJoinClubInvitation}
            >
              <span className="indicator-label fw-bolder">
                {inviteLoader ? (
                  <ButtonSpinner color="text-black spinner-border-sm" />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center btn-light m-5 btn-lg w-100 mb-5"
              onClick={() => setJoinModal(false)}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};
const mapState = ({
  getClubDetails,
  userProfile,
  joiningClubDetails,
  joinClub,
  joinClubSuccess
}: any) => {
  return {
    _getClubDetails: getClubDetails,
    _userProfile: userProfile,
    _joiningClubDetails: joiningClubDetails,
    _joinClub: joinClub,
    _joinClubSuccess: joinClubSuccess
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $getClubDetails: () => dispatchEvent(getClubDetails()),
  $joiningClubDetails: (values?: IJoinArg) =>
    dispatchEvent(joiningClubDetails(values)),
  $joinClub: (values?: IJoinClub) => dispatchEvent(joinClub(values)),
});
export default connect(mapState, mapDispatch)(MemberList);
