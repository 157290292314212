import React, { useEffect, useState } from "react";
import {
  getUser,
  getAllEv,
  getUniqueBeters,
  getMe,
  getitsMe,
  getUniqueMe,
  stringWithValidLength,
  getResult,
  getLastName,
  getResultNew
} from "../../AllEventCalculation/Calculation";
import {
  getParlayHomeBeters,
  getParlayAwayBeters,
  getParlayTotalRisk,
  getParlayTotalWin
} from "../../../Club/EventBetsDetails/EventsParlays/ParlayCalculation/ParlayCalculation";
import { getPreParlayHomeMe, getPreParlayAwayMe } from "../../../MyProfile/ProfilePreviousEvents/PreviousBets/CalulationPreUser"
import LeftRightScroll from "../../LeftRightScroll";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../../../Utils/BaseUrl";
import { externalRedirect } from "../../../../../Utils/helperFunctions";

const PreviousParlay = (props ?:any) => {
   const { parlayList, risk, setrisk, parlayevents, setParlayEvents, parlayLock, isAlertActive } = props;
  const [users, setUsers] = useState<any>(null);
  const [allEv, setAllEv] = useState<any>(null);
  const [uniqueBeters, setUniqueBeters] = useState<any>(null);
  const [meData, setMeData] = useState<any>(null);
  const [itsMe, setItsMe] = useState<any>(null);
  const [uniqueMe, setUniqueMe] = useState<any>(null);
  const slideRef= React.useRef() as React.MutableRefObject<HTMLTableElement>  
  const [myData, setMyData] = useState<any>([])

  useEffect(() => {
    const data = props?.summaryData
    let myDataIs = []
    const myDatas = data && data.find((el:any) => el?.isMe == true)
    myDataIs.push(myDatas)
    setMyData(myDataIs)
  }, [parlayList])


  useEffect(() => {
    setUsers(getUser(parlayList));
    setMeData(getMe(parlayList));
  }, [parlayList]);

  useEffect(() => {
    setAllEv(getAllEv(users));
  }, [users]);

  useEffect(() => {
    setUniqueBeters(getUniqueBeters(allEv));
  }, [allEv]);

  useEffect(() => {
    setItsMe(getitsMe(meData));
  }, [meData]);

  useEffect(() => {
    setUniqueMe(getUniqueMe(itsMe));
  }, [itsMe]);

  
   return(
     <>
      <div ref={slideRef} className="table-responsive h-500">
      <table
        className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
        id="kt_subscriptions_table_3"
        role="grid"
      >
        <thead className="sticky-top" style={{background:"white"}}>
          <tr
            className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-table-row-2"
            role="row"
          >
            <th
              className="min-w-250px cu-12 sorting text-start ps-4"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Customer: activate to sort column ascending"
              style={{ width: 250, position: "sticky", left: 0, background: "white", zIndex: 1}}
            >
              Fighters
            </th>
            {/* <th
              className="min-w-70px sorting position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Status: activate to sort column descending"
              style={{ width: 70, position: "sticky", left: 200, background: "white", zIndex: 1}}
              // aria-sort="ascending"
            >
              Moneyline
            </th>
            <th
              className="min-w-70px position_static"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Billing: activate to sort column ascending"
              style={{ width: 70, position: "sticky", left: 295, background: "white", zIndex: 1 }}
            >
              Result
            </th> */}
            <th
              className="min-w-125px sorting"
              tabIndex={0}
              aria-controls="kt_subscriptions_table"
              rowSpan={1}
              colSpan={1}
              aria-label="Product: activate to sort column ascending"
              style={{width:150}}
            >
              <Link to = {`/myprofile`}  className="text-muted">
                Me
              </Link>
            </th>
            {uniqueBeters?.length > 0 &&
              uniqueBeters?.map((obj: any, index: any) => (
                <th
                  className="min-w-100px sorting"
                  tabIndex={0}
                  aria-controls="kt_subscriptions_table"
                  rowSpan={1}
                  colSpan={1}
                  aria-label="Created Date: activate to sort column ascending"
                  style={{ minWidth:150 }}
                >
                  {
                    obj?.screenName
                    ?
                    <Link to = {`/player/${obj?.screenName}`}  className="text-muted">
                      {obj?.screenName ? stringWithValidLength(obj?.screenName ,20, 15) : "-"}
                    </Link>
                    :
                    obj?.screenName ? stringWithValidLength(obj?.screenName ,20, 15) : "-"
                  }
                </th>
              ))}
          </tr>
          {/*end::Table row*/}
        </thead>
        <tbody className="text-gray-600 fw-bold cu-table-01">
          {parlayList?.length > 0 ? (
            parlayList.map((obj: any, index: any) => (
              <>                
                <tr className="bg-table-row-transparent" key={index}>
                  <td 
                  // role={obj?.awayTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1 }} className=" text-gray-800 text-start ps-4 scroll_sticky_white table-column">
                  <span className="image-avatar-wrapper" onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {!obj?.awayTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_away < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_away > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.away_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.awayTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageAway) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                      {stringWithValidLength(obj?.away_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_away)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.awayTeamUrl && externalRedirect(obj?.awayTeamUrl)}>
                        {getLastName(obj?.away_team)}
                        </span>
                        <span>{obj?.moneyline_away ? " ("+obj?.moneyline_away+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_away)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageAway) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageAway)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className=" scroll_sticky_white_third table-column">{obj.moneyline_away ? obj.moneyline_away : "-"}</td>
                  <td className="min-w-50px scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_away)}</td> */}

                  {/* ME */}
                  {
                    uniqueMe?.length > 0 
                    ? 
                    uniqueMe.map((ob: any, ind: number) => getPreParlayAwayMe( ob, obj, itsMe, parlayevents, setParlayEvents, parlayLock, obj?.result ? getResult(obj?.result?.winner_away) : '')) 
                    :
                    <td className="table-column">-</td>
                  }
                  {/* AWAY BETERS */}
                  {uniqueBeters?.map((Ob: any) =>
                    getParlayAwayBeters(Ob, obj, allEv, obj?.result ? getResult(obj?.result?.winner_away) : '')
                  )}
                </tr>
                <tr className="bg-table-row-2">
                  <td
                  // role={obj?.homeTeamUrl && "button"} 
                  style={{ position: "sticky", left: 0, background: "white", zIndex: 1 }}  className="text-gray-800 text-start ps-4 scroll_sticky_white table-column">
                  <span className="image-avatar-wrapper" onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {!obj?.homeTeamPic?
                      <span className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                        <span
                          className={`symbol-label ${
                            obj.moneyline_home < 0
                              ? "bg-light-success text-success"
                              : obj.moneyline_home > 0
                              ? "bg-light-danger text-danger"
                              : "bg-light text-dark"
                          } 40px`}
                        >
                           {obj?.home_team.charAt(0)}
                        </span>
                      </span>
                      :
                      <div className=" d-sm-inline-block symbol symbol-30px symbol-circle me-3">
                      <img src={ImageUrl+obj?.homeTeamPic} style={{width:"30px",verticalAlign: !isNaN(obj?.userPicksPercentage?.pickPercentageHome) ? "sub":"middle"}} className="rounded-circle" alt="" />
                      </div>}
                    </span>
                    {/* for desktop */}
                    <span className="d-none d-sm-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                      {stringWithValidLength(obj?.home_team, 20, 15)}
                      </span>
                      <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                      <span>{getResultNew(obj?.result?.winner_home)}</span>
                      {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                    {/* for mobile */}
                    <span className="d-sm-none d-inline-block">
                    <span onClick={()=>obj?.homeTeamUrl && externalRedirect(obj?.homeTeamUrl)} >
                        {getLastName(obj?.home_team)}
                        </span>
                        <span>{obj?.moneyline_home ? " ("+obj?.moneyline_home+") " : "-"}</span>
                        <span>{getResultNew(obj?.result?.winner_home)}</span>
                        {
                          !isNaN(obj?.userPicksPercentage?.pickPercentageHome) 
                          ? 
                          <div style = {{fontSize:'10px'}}>{(obj?.userPicksPercentage?.pickPercentageHome)?.toFixed(2)+"%"+" "+"Picked"}</div> 
                          : 
                          ""
                        }
                    </span>
                  </td>
                  {/* <td className="scroll_sticky_white_third table-column">{obj.moneyline_home ? obj.moneyline_home : "-"}</td>
                  <td className=" min-w-50px scroll_sticky_white_fourth table-column">{getResult(obj?.result?.winner_home)}</td> */}

                  {/* ME */}
                  {
                    uniqueMe?.length > 0 ? (
                      uniqueMe.map((ob: any, ind: number) =>
                        getPreParlayHomeMe(
                          ob,
                          obj,
                          itsMe,
                          parlayevents,
                          setParlayEvents,
                          parlayLock,
                          obj?.result ? getResult(obj?.result?.winner_home) :""
                        )
                      )
                    ) : (
                      <td className="table-column">-</td>
                    )
                  }

                  {/* HOME BETERS   */}
                  {uniqueBeters?.map((Ob: any) =>
                    getParlayHomeBeters(Ob, obj, allEv, obj?.result ? getResult(obj?.result?.winner_home) :"")
                  )}
                </tr>
              </>
            ))
          ) : (
            <tr className="bg-table-row-transparent">
              <td className="text-gray-800 text-start ps-4">
                Data Not found...
              </td>
            </tr>
          )}

          {/* Total Number Row  */}
          {parlayList?.length > 0 && (
            <tr className="total_number">
              <td style={{ position: "sticky", left: 0, background: "white", zIndex: 1}} className="ps-5 text-start scroll_sticky_black">
                 Parlay Bet Amount and ROI
              </td>
              {/* <td className="scroll_sticky_black_fourth"></td>
              <td className="scroll_sticky_black_fourth"></td> */}
              <td>
                {myData && myData.map((ele:any) => {
                  return <div className="d-flex align-items-center justify-content-between">
                  <div  className={`w-50 ${ele == undefined ? '': ele?.parleyRisk > 0 ? "" : "r Lose"}`}>
                    {uniqueMe?.length > 0 
                    ? 
                    (uniqueMe.map((ob: any) => getParlayTotalRisk(ob, parlayList) ) ) ? uniqueMe.map((ob: any) => getParlayTotalRisk(ob, parlayList) ) : "$ 0.00"
                    : 
                    "$0.00"}
                  </div>
                  <div className="w-50 ms-5">
                  {
                    uniqueMe?.length > 0
                    ? 
                    uniqueMe?.map((ob: any) =>{
                      // getParlayTotalWin(ob, parlayList)
                      // --------getting summary value in parlays total---------
                      const parlayRisk = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parleyRisk
                        const parlayWinAmount = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parlayWin
                      return <div className={`w-100 ${parlayRisk > 0 ? "w Win" : ""} btn-sm p-1`}>
                        {!isNaN(parlayRisk) ? "$"+(parlayWinAmount).toFixed(2) : "$ 0.00"}
                      </div>
                    })
                    : 
                    "$0.00"
                  }
                  </div>
                </div>
                })}
              </td>
              {uniqueBeters?.length > 0 &&
                uniqueBeters?.map((ob: any, index: any) => {
                  const parlayRisk = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parleyRisk
                  const parlayWinAmount = props?.summaryData?.find((el: any) => el.userId === ob.userId)?.parlayWin
                  return <td key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={`w-100 ${parlayRisk > 0 ? "" : "r Lose"} btn-sm p-1`}>
                         {(getParlayTotalRisk(ob, parlayList)) ? getParlayTotalRisk(ob, parlayList) : "$ 0.00"}
                      </div>
                      <div className="w-100">
                         {/* {getParlayTotalWin(ob, parlayList)} */}
                         {/* --------getting summary value in parlays total--------- */}
                         <div className={`w-100 ${parlayRisk > 0 ? "w Win" : ""} btn-sm p-1`}>
                           {!isNaN(parlayRisk) ? new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(parlayWinAmount) : "$ 0.00"}
                           </div>
                      </div>
                    </div>
                  </td>
                })}
            </tr>
          )}
        </tbody>
        {/*end::Table body*/}
      </table>
    </div>
      <LeftRightScroll slideRef={slideRef}/>
    </>
   )
}

export default PreviousParlay;