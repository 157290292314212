import * as yup from "yup";

export const initialValues_createPwd ={
    password : '',
    confirmPassword : ""
}

export const validationSchema_createPwd = yup.object().shape({
    password: yup
    .string()
    .min(8, "Minimum 8 Characters Needed")
    .matches(
      /^.*[!@#$%^&*()_+\-=\]{};':"\\|,.<>?].*$/,
      "Need at least one special character"
    )
    .matches(/^(?=.{0,100}$)\D*\d/, "Need at least a number")
    .matches(/[a-z]/i, "Need at least a character") 
    .required("Required"),
      confirmPassword:yup.string().oneOf([yup.ref('password')], 'Both password need to be the same').required('Required'),
})