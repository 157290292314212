import { 
    ALLTIME_GAME_PLANNER_SUCCESS,
    ALLTIME_POUND_FOR_POUND_SUCCESS,
    ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS,
    EVENT_GAME_PLANNER_SUCCESS,
    EVENT_POUND_FOR_POUND_SUCCESS,
    EVENT_CORRECT_BETS_SUCCESS
} from "../type.action"

const initState = {
    allTimeGamePlanner: [],
    allTimePoundForPound: [],
    allTimeMakingChampionshipRun: []
};

const reducers = (state = initState, { type, values, error }: any) => {
    switch(type){
        case ALLTIME_GAME_PLANNER_SUCCESS: return {
            ...state,
            allTimeGamePlanner: values
        }
        case ALLTIME_POUND_FOR_POUND_SUCCESS: return {
            ...state,
            allTimePoundForPound: values
        }
        case ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS : return {
            ...state,
            allTimeMakingChampionshipRun: values
        }
        case EVENT_GAME_PLANNER_SUCCESS: return {
            ...state,
            eventGamePlanner: values
        }
        case EVENT_POUND_FOR_POUND_SUCCESS: return {
            ...state,
            eventPoundForPound: values
        }
        case EVENT_CORRECT_BETS_SUCCESS: return {
            ...state,
            eventCorrectBets: values
        }
        default: return state
    }
}

export default reducers;