import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { loginData } from "../Store/action/Login.action";
import { userProfile } from "../Store/action/UserInfo.action";

const PublicRoute = ({component, ...rest}: any) => {

    const token = localStorage.getItem("token")
    const routeComponent = (props: any) => (
        // (token && rest?._loginData?.isAuthenticated)        
        (token && props.location.pathname === "/")
            ? 
              <Redirect to={{pathname: '/createyourclub'}}/>
            : 
              React.createElement(component, props)
            
    );  
    return <Route {...rest} render={routeComponent}/>;
};
const mapState = ({ loginData, userProfile }: any) => {
    return {
      _loginData: loginData,
      _userProfile: userProfile
    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $loginData: (values?: ILogin) => dispatchEvent(loginData(values)),
    $userProfile: () => dispatchEvent(userProfile()),
  });
  export default connect(
    mapState,
    mapDispatch
  )(PublicRoute);