import { NavLink, Link, useHistory, Router, useLocation } from "react-router-dom";
import TextSliding from "./TextSliding";
import Logo from "../assets/images/logos/Red-white-black.png";
import AvatarImage from "../assets/images/avatars/blank.png";
import { connect } from "react-redux";
import debounce from "lodash.debounce";
import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  userProfile,
  getCountry,
  logout,
} from "../Store/action/UserInfo.action";
import windowSize from "react-window-size";
import { ImageUrl } from "../Utils/BaseUrl";
import { stringNameLength } from "../Component/Authenticated/Club/AllEventCalculation/Calculation";
import { postApi } from "../Store/api-interface/api-interface";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import  {firebaseConfig}  from "../firebaseConfig"
import ReactGA from "react-ga"
const Header = (props: any) => {
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const { options, _userProfile }: Iheader = props;
  const [userDropdown, setUserDropdown] = useState<boolean>(false);
  const [mobileDrawer, setMobileDrawer] = useState<boolean>(false);
  const [searchBox, setSearchBox]=useState<any>({
    searchLoader:false,
    searchData:""
  })
  const [searchShow, setSearchShow]=useState<boolean>(false);
  const searchRef=useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const isAuthenticated = localStorage.getItem("token");
  const user = _userProfile?.userDataIs?.data;
  const userInvitations = _userProfile?.userDataIs?.data?.hasClubInviations;
  const location = useLocation<any>()
  const newMessageOnAnyClub =
    _userProfile?.userDataIs?.data?.newMessageOnAnyClub;
  const history = useHistory();

  const handleLogout = () => {
    props.$logout();
    ReactGA.pageview("/invitefriends")
  };

  const handleInviteMemebers = () => {
    history.push({
      state: {
        invitationType: 1,
      },
    });
    ReactGA.pageview("/invitefriends");
  };

  useEffect(() => {
    if (isAuthenticated) {
      props.$userProfile();
      props.$getCountry();
    }
  }, []);

  useEffect(() => {
    const profileStatusIs = _userProfile?.userDataIs.status;
    if (profileStatusIs === 0) {
      localStorage.removeItem("token");
      props?.history?.push("/login");
      window.location.reload();
    }
    if(_userProfile?.userDataIs?.data?.id){

      const ga = ReactGA.ga();
      ga('set', 'userId', _userProfile?.userDataIs?.data?.email);

      // ReactGA.pageview("/createyourclub");
      //  ReactGA.set({userId: _userProfile?.userDataIs?.data?.id})
    }
  }, [_userProfile]);

  document.addEventListener("click", (ev: any) => {
    if (dropdownRef && !dropdownRef.current?.contains(ev.target)) {
      setUserDropdown(false);
    }
  });
  let isMobile = props.windowWidth < 992;

const searchUsers=async(value:any)=>{
  setSearchShow(true)
  if(value){
  setSearchBox({...searchBox, searchLoader:true})
  await postApi("/user/getUserWithSearch",{keyWord:value})
  .then((data)=>{
    if(data){
    setSearchBox({searchData:data.data.data, searchLoader:false})
    }
  })
}
else{
  setSearchBox({...searchBox, searchData:""})
}
}
const debouncedChangeHandler = useCallback(debounce(searchUsers, 400), []);
document.addEventListener("click", (ev: any) => {
  if (searchRef && !searchRef.current?.contains(ev.target)) {
    setSearchShow(false)
  } 
});

  // const banner = () => (
  //   <div >
  //   <p className="banner">
  //     <a href = "/ufc-contest"> <span className="marqueeStyle">Make your predictions for UFC 276 for a chance to win $1,000 and other prizes! </span></a>
  //       {/* <span className="marqueeStyle">Make your predictions for UFC 276 for a chance to win $1,000 and other prizes! </span> */}
  //   </p>
  //      </div>
  // )
  

  return (
    <React.Fragment>
      
      {mobileDrawer && isMobile ? (
        <div
          style={{ zIndex: 109 }}
          className="drawer-overlay"
          onClick={() => setMobileDrawer(false)}
        />
      ) : (
        ""
      )}
      <section className={`header_wrapper`} >
        <div className="d-flex flex-column flex-root fixed-top">
          <div className="mb-0" id="home">
            <div>
            
              {/* { location?.pathname === '/' ? <TextSliding/> : "" } */}
              <div className={`landing-header ${ options === "fill_Header" ? "bg-dark" : "" }`} >
                <div className="container">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center header-container justify-content-between flex-equal">
                      <NavLink exact to="">
                        <img
                          alt="Logo"
                          src={Logo}
                          className="logo-default h-25px h-lg-30px"
                        />
                        <img
                          alt="Logo"
                          src={Logo}
                          className="logo-sticky h-20px h-lg-25px"
                        />
                      </NavLink>
                      
                     {isAuthenticated?
                      <div className="menu-item searchBox">                             
                        <div className="input-group">
                          <div className="form-outline">
                            <input
                              onChange={(e)=>debouncedChangeHandler(e.target.value)}
                              style={{ height: "35px"}}
                              type="search"
                              className="form-control"
                              placeholder="Search users..." 
                              autoComplete="off"
                            />
                            <div ref={searchRef} className="mt-1 search-items-list display-flex justify-content-center" style={{display:searchShow?"":"none", width:"100%",zIndex: 999999}}>
                              {searchBox?.searchLoader?
                              <div className="text-center py-7">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>:
                                searchBox?.searchData?.length>0?searchBox?.searchData?.map((obj:any)=>{
                                return(
                                  <div className="bg-light list-item" >
                                      <div>
                                        <Link to={`/player/${obj?.screenName}`}>{obj?.firstName+ " " +obj?.lastName }</Link>
                                      </div>
                                      <div>
                                        <Link to={`/player/${obj?.screenName}`} className = 'linktag'>{obj?.screenName}</Link>
                                      </div>
                                   </div>
                              )
                              }):( <div className="bg-light list-item" >No data found.</div>)}
                            </div>
                          </div>
                          
                        </div>
                      </div> 
                      :""}


                      <button
                        className={`btn btn-icon btn-active-color-primary d-flex d-lg-none ${
                          mobileDrawer ? "active" : ""
                        }`}
                        id="kt_landing_menu_toggle"
                        onClick={() => setMobileDrawer(!mobileDrawer)}
                      >
                        <span className="svg-icon svg-icon-2hx">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <rect
                                fill="#fff"
                                x={4}
                                y={5}
                                width={16}
                                height={3}
                                rx="1.5"
                              />
                              <path
                                d="M5.5,15 L18.5,15 C19.3284271,15 20,15.6715729 20,16.5 C20,17.3284271 19.3284271,18 18.5,18 L5.5,18 C4.67157288,18 4,17.3284271 4,16.5 C4,15.6715729 4.67157288,15 5.5,15 Z M5.5,10 L18.5,10 C19.3284271,10 20,10.6715729 20,11.5 C20,12.3284271 19.3284271,13 18.5,13 L5.5,13 C4.67157288,13 4,12.3284271 4,11.5 C4,10.6715729 4.67157288,10 5.5,10 Z"
                                fill="#fff"
                                opacity="1"
                              />
                            </g>
                          </svg>
                        </span>
                      </button>
                    </div>
                    <div
                      className="d-flex align-items-center"
                      id="kt_header_nav_wrapper"
                    >
                      <div
                        className={`d-lg-block p-5 p-lg-0 ${
                          isMobile ? `drawer drawer-start` : ``
                        } ${mobileDrawer ? `drawer-on` : ``}`}
                        data-kt-drawer="true"
                        data-kt-drawer-name="landing-menu"
                        data-kt-drawer-activate="{default: true, lg: false}"
                        data-kt-drawer-overlay="true"
                        data-kt-drawer-width="200px"
                        data-kt-drawer-direction="start"
                        data-kt-drawer-toggle="#kt_landing_menu_toggle"
                        data-kt-place="true"
                        data-kt-place-mode="prepend"
                        data-kt-place-parent="{default: '#kt_body', lg: '#kt_header_nav_wrapper'}"
                        style={{ width: mobileDrawer ? `200px` : `auto` }}
                      >
                        <div
                          className="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary fs-5"
                          id="kt_landing_menu"
                        >
                          {!isAuthenticated ? (
                            <React.Fragment>
                               {/* <div className="menu-item">
                                <a
                                  // exact
                                  href="/ufc-contest"
                                  
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 "
                                >
                                  <span className = "ufc-276 badge badge-danger text-light" style={{backgroundColor:"#F63715" ,fontSize:"1.1rem"}} >UFC 282 Giveaway</span>
                                  
                                </a>
                              </div> */}
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/"
                                  className={`menu-link nav-link active py-3 px-4 px-xxl-6 text-white`}
                                >
                                  Home
                                </NavLink>
                              </div>
                             

                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/aboutus"
                                  className={`menu-link nav-link py-3 px-4 px-xxl-6 text-white`}
                                  onClick={()=>{
                                    ReactGA.pageview('/aboutus')
                                  }}
                                >
                                  About Us
                                </NavLink>
                              </div>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                               {/* <div className="menu-item">
                                <a
                                  // exact
                                  href="/ufc-contest"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                >
                                  <span className = "ufc-276 badge badge-danger text-light" 
                                  style={{backgroundColor:"#F63715", fontSize:"1.1rem"}}>
                                    UFC 282 Giveaway</span>
                                </a>
                              </div> */}
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/clublist"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/clublist')
                                  }}
                                >
                                  Clubs
                                  {userInvitations ? (
                                    <sup>
                                      <i
                                        className="mx-1 text-warning bi bi-people-fill"
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="New club invitations."
                                      />
                                    </sup>
                                  ) : (
                                    ""
                                  )}
                                  {newMessageOnAnyClub ? (
                                    <sup>
                                      <i
                                        className="mx-1 bi bi-chat-left-dots-fill"
                                        style={{ color: "#F63715" }}
                                        data-toggle="tooltip"
                                        data-placement="bottom"
                                        title="New Club Messages"
                                      />
                                    </sup>
                                  ) : (
                                    ""
                                  )}
                                </NavLink>
                              </div>

                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/leaderboard"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/leaderboard')
                                  }}
                                >
                                  Leaderboard
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/aboutus"
                                  className={`menu-link nav-link py-3 px-4 px-xxl-6 text-white`}
                                  onClick={()=>{
                                    ReactGA.pageview('/aboutus')
                                  }}
                                >
                                  About Us
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/howtoplay"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/howtoplay')
                                  }}
                                >
                                  How To Play
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/invitefriends"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/invitefriends')
                                  }}
                                >
                                  Invite Friends
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/news"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/news')
                                  }}
                                >
                                  News
                                </NavLink>
                              </div>
                            </React.Fragment>
                          )}

                          {!isAuthenticated ? (
                            <React.Fragment>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/howtoplay"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/howtoplay')
                                  }}
                                >
                                  How To Play
                                </NavLink>
                              </div>
                              <div className="menu-item">
                                <NavLink
                                  exact
                                  to="/news"
                                  className="menu-link nav-link py-3 px-4 px-xxl-6 text-white"
                                  onClick={()=>{
                                    ReactGA.pageview('/news')
                                  }}
                                >
                                  News
                                </NavLink>
                              </div>
                              <div className={`mx-3 my-3 my-lg-0 mx-lg-4`}>
                                <NavLink
                                  exact
                                  to="/login"
                                  className="btn btn-light-white fw-bolder text-dark py-3"
                                  onClick={()=>{
                                    ReactGA.pageview('/login')
                                  }}
                                >
                                  Log In
                                </NavLink>
                              </div>
                            </React.Fragment>
                          ) : null}
                          {isAuthenticated ? (
                            <div
                              className={`d-flex align-items-center ms-3 ms-lg-3 mt-3 mt-lg-0`}
                              id="kt_header_user_menu_toggle"
                            >
                              <div
                                className={`cursor-pointer symbol symbol-30px symbol-md-40px ${
                                  userDropdown ? "show menu-dropdown" : ""
                                }`}
                                onClick={() => setUserDropdown(!userDropdown)}
                                ref={dropdownRef}
                              >
                                {/* <img src={user?.profilePic ? ImageUrl + user?.profilePic : AvatarImage} alt="metronic" /> */}
                                {user?.profilePic?.includes("-blob") &&
                                user?.firstName ? (
                                  <span className="symbol symbol-30px symbol-rectange ">
                                    <span
                                      className="symbol-label bg-light-secondary text-black 40px"
                                      style={{
                                        fontSize: "30px",
                                        textAlign: "center",
                                      }}
                                    >
                                      {user?.firstName?.charAt(0)}
                                    </span>
                                  </span>
                                ) : (
                                  <img
                                    src={
                                      user?.profilePic
                                        ? ImageUrl + user?.profilePic
                                        : AvatarImage
                                    }
                                    alt="metronic"
                                    
                                  />
                                )}
                              </div>
                              <div
                                className={`menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px ${
                                  userDropdown
                                    ? "show show-dropdown-header"
                                    : ""
                                }`}
                                data-kt-menu="true"
                              >
                                <div className="menu-item px-3">
                                  <div className="menu-content d-flex align-items-center px-3">
                                    <div className="symbol symbol-50px me-5">
                                      {/* <img alt="Logo" src={user?.profilePic ? ImageUrl + user?.profilePic  : AvatarImage} /> */}
                                      {user?.profilePic?.includes("-blob") &&
                                      user?.firstName ? (
                                        <span className="symbol symbol-30px symbol-rectange ">
                                          <span
                                            className="symbol-label bg-secondary text-black 40px"
                                            style={{
                                              fontSize: "30px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {user?.firstName?.charAt(0)}
                                          </span>
                                        </span>
                                      ) : (
                                        <img
                                          src={
                                            user?.profilePic
                                              ? ImageUrl + user?.profilePic
                                              : AvatarImage
                                          }
                                          alt="metronic"
                                          onClick={()=>{
                                            ReactGA.pageview('/myprofile')
                                          }}
                                        />
                                      )}
                                    </div>
                                    <div className="d-flex flex-column">
                                      <div className="fw-bolder d-flex align-items-center fs-5">
                                        {/* {user?.firstName}{" "}{user?.lastName}  */}
                                        {user?.screenName
                                          ? user?.screenName
                                          : stringNameLength(
                                              user?.firstName
                                                ? user?.firstName
                                                : "",
                                              user?.lastName
                                                ? user?.lastName
                                                : "",
                                              20,
                                              20
                                            )}
                                      </div>
                                      <span className="fw-bold text-muted text-hover-primary fs-7">
                                        {user?.email}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="separator my-2" />
                                <div className="menu-item px-5">
                                  <Link
                                    to="/myprofile"
                                    className="menu-link px-5"
                                    onClick={()=>{
                                      ReactGA.pageview('/myprofile')
                                    }}
                                  >
                                    My Profile
                                  </Link>
                                </div>
                                <div className="menu-item px-5">
                                  <Link
                                    to="/personalsettings"
                                    className="menu-link px-5"
                                    onClick={()=>{
                                      ReactGA.pageview('/personalsetting')
                                    }}
                                  >
                                    Personal Settings
                                  </Link>
                                </div>
                                <div className="menu-item px-5 my-1">
                                  <Link
                                    to="/changepassword"
                                    className="menu-link px-5"
                                    onClick={()=>{
                                      ReactGA.pageview('/changepassword')
                                    }}
                                  >
                                    Change Password
                                  </Link>
                                </div>
                                <div className="menu-item px-5 my-1">
                                  <Link
                                    to="/invitefriends"
                                    className="menu-link px-5"
                                    onClick={handleInviteMemebers}
                                    
                                  >
                                    Invite Friends
                                  </Link>
                                </div>
                                {/* <div className="menu-item px-5 my-1">
                                                                <Link to="/"
                                                                    className="menu-link px-5"
                                                                >
                                                                    Policy
                                                                </Link>
                                                            </div>
                                                            <div className="menu-item px-5 my-1">
                                                                <Link to="/"
                                                                    className="menu-link px-5"
                                                                >
                                                                    Terms and Conditions
                                                                </Link>
                                                            </div> */}
                                <div className="menu-item px-5">
                                  <Link
                                    to="/login"
                                    className="menu-link px-5"
                                    onClick={handleLogout}
                                  >
                                    Sign Out
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* {location?.pathname !== '/' ? <TextSliding/> : "" } */}
             
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};
const mapState = ({ loginData, userProfile, getCountry }: any) => {
  return {
    _loginData: loginData,
    _userProfile: userProfile,
    _getCountry: getCountry,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $userProfile: () => dispatchEvent(userProfile()),
  $getCountry: () => dispatchEvent(getCountry()),
  $logout: () => dispatchEvent(logout()),
});
export default connect(mapState, mapDispatch)(windowSize(Header));
