import {
  LOGIN_DATA_SUCCESS,
  SIGNUP_DATA_SUCCESS,
  SIGNUP_DATA_ERROR,
  LOGIN_DATA_ERROR,
  FORGOT_PWD_SUCCESS,
  FORGOT_PWD_ERROR,
  RESET_PWD_SUCCESS,
  RESET_PWD_ERROR,
} from "../type.action";

const initState = {
  loginDataIs: "",
  loginDataErrIs: "",
  signUpDataIs: "",
  signUpErrIs: "",
  isAuthenticated: false,
  forgotPwdIs: "",
  forgotPwdErrIs: "",
  resetPwdIs : '',
  resetPwdErrIs : '',
  alreadySignupIs : false
};

const reducers = (state = initState, { type, values, error }: any) => {
  switch (type) {
    case LOGIN_DATA_SUCCESS:
      return {
        ...state,
        loginDataIs: values,
        loginDataErrIs: "",
        isAuthenticated: true
      };
    case LOGIN_DATA_ERROR:
      return {
        ...state,
        loginDataErrIs: error,
        loginDataIs: "",
        isAuthenticated: false
      };
    case SIGNUP_DATA_SUCCESS:
      return {
        ...state,
        signUpDataIs: values,
        signUpErrIs: "",
        isAuthenticated: true
      };
    case SIGNUP_DATA_ERROR:
      return {
        ...state,
        signUpErrIs: error,
        signUpDataIs: "",
        isAuthenticated: false
      };
    case FORGOT_PWD_SUCCESS:
      return {
        ...state,
        forgotPwdIs: values,
        forgotPwdErrIs: ''
      };
    case FORGOT_PWD_ERROR:
      return {
        ...state,
        forgotPwdErrIs: error,
        forgotPwdIs: ''
      };
    case RESET_PWD_SUCCESS:
      return{
        ...state,
        resetPwdIs: values,
        resetPwdErrIs: ''
      } 
    case RESET_PWD_ERROR:
      return{
        ...state,
        resetPwdErrIs : error,
        resetPwdIs: ""
      }    
    default:
      return state;
  }
};

export default reducers;
