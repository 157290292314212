import React from "react";
import Header from "./Header";

const Layout = ({ options, uservisible, children }: Ilayout) => {
    return (
        <React.Fragment>
            <Header options={options} uservisible={uservisible} />
            {children}
        </React.Fragment>
    )
}
export default Layout