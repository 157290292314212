import axios from "axios";
import { BaseUrl } from "../../Utils/BaseUrl";

export const postApiWithoutToken = (path:string, data?:any) => {
  return axios.post(BaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const putApiWithoutToken = (path:string, data?:any) => {
  return axios.put(BaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const getApiWithoutToken = (path:string) => {
  return axios.get(BaseUrl + path, {
    headers: {
      "Content-Type": "application/json"
    }
  });
};

export const postApi = (path:string, data?:any) => {
  const token = localStorage.getItem("token");

  return axios.post(BaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
};

export const getApi = (path:string, data?:any) => {
  const token = localStorage.getItem("token");

  return axios.get(BaseUrl + path, {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    }
  });
};

export const putApi = (path:string, data?:any) => {
  const token = localStorage.getItem("token");

  return axios.put(BaseUrl + path, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    }
  });
};

export const fileUpload = (path:string, data?:any) => {
  const token = localStorage.getItem("token");

  return axios.post(BaseUrl + path, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`
    }
  });
};

// export const deleteApi = (path:string, data?:any) => {
//   const token = localStorage.getItem("token");

//   return axios.delete(BaseUrl + path, data, {
//     headers: {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${token}`
//     }
//   });
// };
