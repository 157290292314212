import { put, call } from "redux-saga/effects";
import { getApi, getApiWithoutToken, putApi, postApi, fileUpload } from "../api-interface/api-interface";
import {
  userProfileSuccess,
  userProfileError,
  getCountrySuccess,
  getCountryErr,
  updateUserSuccess,
  updateUserErr,
  createClubSuccess,
  createClubError,
  changePwdSuccess,
  changePwdErr,
  getClubDetailsSuccess,
  getClubInvitationSuccess,
  getClubDetailsErr,
  inviteFrndsSuccess,
  inviteFrndError,
  joinClubSuccess,
  joinClubErr,
  profilePicSuccess,
  profilePicErr,
  joiningClubDetailsSuccess,
  clubPicSuccess,
  clubPicErr
} from "../action/UserInfo.action";

// User Profile *********
export function* onUserProfile(): any {
  try {
    const response = yield call(getApi, `/user/getUserProfile`);
    if (response.status === 200) {      
      yield put(userProfileSuccess(response.data));
    }
  } catch (error : any) {
    yield put(userProfileError(error.response.data));
  }
}

// get Country ********

export function* onGetCountry(): any {
  try {
    const response = yield call(getApiWithoutToken, `/user/getCountries`);
    if (response.status === 200) {      
      yield put(getCountrySuccess(response.data));
    }
  } catch (error : any) {
    yield put(getCountryErr(error.response.data));
  }
}

//  update user ***************
export function* onUpdateUser(payload: IUserPayload): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(putApi, `/user/updateUserProfile`, data);
      if (response.status === 200) {        
        yield put(updateUserSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(updateUserErr(error.response.data));
    }
  }

  // create club ****************
  export function* onCreateClub(payload: IClubPayload): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(postApi, `/user/createClub`, data);
      if (response.status === 200) {
        yield put(createClubSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(createClubError(error.response.data));
    }
  } 

  // change Pwd ************

 export function* onChangePwd(payload: IChangePwdPayload): any {   
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(putApi, `/user/updatePassword`, data);
      if (response.status === 200) {        
        yield put(changePwdSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(changePwdErr(error.response.data));
    }
  } 

  // get club details ****************

  export function* onGetClubDetails(): any {
    try {
      const response = yield call(getApi, `/user/getMyClub`);
      if (response.status === 200) {        
        const sorted=response?.data?.data?.sort((a:any,b:any) => (a?.name?.toLowerCase() > b?.name?.toLowerCase()) ? 1 : ((b?.name?.toLowerCase()> a?.name?.toLowerCase()) ? -1 : 0))
        response.data.data = sorted
        yield put(getClubDetailsSuccess(response.data));
        yield put(getClubInvitationSuccess(response?.data?.clubInvitations))
      }
    } catch (error : any) {
      yield put(getClubDetailsErr(error.response.data));
    }
  } 

  // invite frnd *******

  export function* onInviteFrnd(payload: IPayload) : any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(postApi, `/user/sendInvite`, data);
      if (response.status === 200) {        
        yield put(inviteFrndsSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(inviteFrndError(error.response.data));
    }
  }

  // join club**************

  export function* onJoinClub(payload: IJoinclubPayload): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(postApi, `/user/joinClub`, data);
      if (response.status === 200) {
        yield put(joinClubSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(joinClubErr(error.response.data));
    }
  }

  // upload  Profile pic
  export function* onProfilePic(payload: any): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(fileUpload, `/user/updateProfilePic`, data);
      if (response.status === 200) {
        yield put(profilePicSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(profilePicErr(error.response.data));
    }
  }

  // joinning club details 

  export function* onJoiningDetails(payload: IjoiningDetailsPayload): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(getApi, `/user/getClubDetailsByInviteCode?inviteCode=${data.inviteCode}`);
      if (response.status === 200) {       
        yield put(joiningClubDetailsSuccess(response.data));
      }
      }
    } catch (error : any) {
      // yield put(profilePicErr(error.response.data));
    }
  }

  // uypload club pic
  export function* onclubPic(payload: any): any {
    let data = payload.values;
    try {
      if (data) {
      const response = yield call(fileUpload, `/user/updateClubPic`, data);
      if (response.status === 200) {
        yield put(clubPicSuccess(response.data));
      }
      }
    } catch (error : any) {
      yield put(clubPicErr(error.response.data));
    }
  }
