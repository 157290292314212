import React, { useEffect, useState } from "react";
import Spinner from "../../../../Common/Spinner/Spinner";
import Linkify from "react-linkify";
import { connect } from "react-redux";
import { userProfile } from "../../../../Store/action/UserInfo.action";

const MyBio = (props?: any) => {
  const [noDataFound, setNoDataFound] = useState(false);

  useEffect(() => {
    setTimeout(() => setNoDataFound(true), 10000);
  }, []);

  const componentDecorator = (href: any, text: any, key: any) => (
    <a href={href} key={key} target="_blank">
      {text}
    </a>
  );

  return (
    <div className="card mb-10 dark-card-bg">
      <div className="card-header dark-card-bg">
        <div className="card-title flex-column m-0">
          <h3 className="fw-bolder mb-0 text-orange">
            {props?.isPublicProfile ? "Player Bio" : "My Bio"}
          </h3>
        </div>
      </div>
      <div className="card-body d-flex flex-column" style={{wordBreak: "break-word"}}>
          {props?.LoadingIs 
            ?
            ( noDataFound ? "No Bio Available" : <Spinner color="text-white spinner-border-sm " />)
            :
            <React.Fragment>
              {
                props?.bio 
                ?
                <div className="d-flex align-items-center mb-5 text-white">
                  <Linkify componentDecorator={componentDecorator}>
                    <p className="fs-6 fw-bold">{props?.bio}</p>
                  </Linkify>
                </div>
                :
                "No Bio Available"
              }
            </React.Fragment>
          }
      </div>
    </div>
  );
};

const mapState = ({ userProfile }: any) => {
  return {
    _userProfile: userProfile,
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $userProfile: () => dispatchEvent(userProfile()),
});
export default connect(mapState, mapDispatch)(MyBio);
