import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmptyImage from "../../../../assets/images/avatars/blank.png";
import Layout from "../../../../Common/Layout";
import Spinner, { ButtonSpinner } from "../../../../Common/Spinner/Spinner";
import { ImageUrl } from "../../../../Utils/BaseUrl";
import Modal from "react-modal";
import { connect } from "react-redux";
import { clubMemberList, removeClubMember } from "../../../../Store/action/Events.action";
const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "500px",
      width: "100%",
    },
  };
const ClubMemberList = (props?: any) => {
  const [LoadingIs, setLoading] = useState<boolean>(false);
  const [MemberListIs, setMemberList] = useState<any>(null);
  const [ModalIs, setModalIs] = useState<boolean>(false)
  const [userId , setUserId] =  useState<number>(0)
  const [Loader, setLoader] = useState<boolean>(false)

  const getConfirmation = (id : number) =>{
    setModalIs(true)
    setUserId(id)
  }

  const handleCancel = () => {
    setModalIs(false)
  }

  const handleRemoveMember = () => {
    const data = { userId }
    if(data){
       props.$removeClubMember(data)
       setLoader(true)
    }    
  }
  
  useEffect(() =>{
    const removedIs = props._removeClubMember.removeMemberIs;
    if(removedIs.status === 1 && Loader){
      setLoader(false)
      handleCancel()
      getMemberList()  
    }
  },[props._removeClubMember])

  const getMemberList = () =>{
    props.$clubMemberList()
    setLoading(true)
  }

  useEffect(() =>{
    getMemberList()
  }, [])

  useEffect(() => {
    const data = props._clubMemberList.clubMemberListIs;
    if(data.status === 1 && LoadingIs){
        setMemberList(data?.data)
        setLoading(false)
    }
  }, [props._clubMemberList])



  return (
    <Layout options="fill_Header" uservisible="visible">
      <div className="main-content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 pt-15">
              <div className="card card-xl-stretch mb-5 mb-xl-8 shadow-xs">
                <div className="card-header border-1 border-secondary pt-5">
                  <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bolder fs-3 mb-1">Name</span>
                  </h3>
                  <div className="card-toolbar">
                    <span className="fw-bolder fs-3">Action</span>
                  </div>
                </div>
                <div className="card-body px-8 py-0">
                  <div className="table-responsive custom_table_member">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      {LoadingIs ? (
                        <Spinner color="text-black spinner-border-sm " />
                      ) : (
                        <tbody>
                          {MemberListIs?.length > 0
                            ? MemberListIs.map(
                                (obj: any, index: number) => {
                                 return <tr className="bg-table-row-1" key={index}>
                                    <td className="w-350px">
                                      <div className="d-flex align-items-center">
                                        <div className="symbol symbol-35px me-5">
                                          <img
                                            src={
                                              obj?.profilePic
                                                ? ImageUrl + obj?.profilePic
                                                : EmptyImage
                                            }
                                            alt="..."
                                          />
                                        </div>
                                        <div className="d-flex justify-content-start flex-column">
                                          <Link
                                            to={"#!"}
                                            className="text-dark fw-bolder fs-6"
                                          >
                                            {(obj.firstName ? obj.firstName : '') + " " + (obj?.lastName ? obj?.lastName : '')}
                                          </Link>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <span className="text-dark fw-bold d-block fs-6">
                                        {obj.isOwner ? `Owner` : `Member`}
                                      </span>
                                    </td>
                                    <td className="text-end">
                                      <label className="text-dark fw-bold d-block fs-6" style={{cursor: "pointer"}} onClick={() => getConfirmation(obj?.id)}>
                                        <i className="bi bi-trash-fill"/>
                                      </label>
                                    </td>
                                  </tr>
                                }
                              )
                            : "No record Found"}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

       {/* Confirmation Modal */}
       <Modal isOpen={ModalIs} style={customStyles} contentLabel="Example Modal">
        <div>
          <h3 className="text-center p-5">Do you want to Remove this club member?</h3>
          <div className="text-center d-flex">
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-orange w-100 mb-5 mx-2"
              onClick={handleRemoveMember}
            >
              <span className="indicator-label fw-bolder">
                {Loader ? (
                  <ButtonSpinner color="text-black spinner-border-sm " />
                ) : (
                  "Confirm"
                )}
              </span>
            </button>
            <button
              type="button"
              id="kt_sign_in_submit"
              className="btn btn-flex flex-center btn-light btn-lg w-100 mb-5 mx-2"
              onClick={handleCancel}
            >
              <span className="indicator-label fw-bolder">Cancel</span>
            </button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
};

const mapState = ({ clubMemberList, removeClubMember }: any) => {
  return {
    _clubMemberList: clubMemberList,
    _removeClubMember : removeClubMember
  };
};
const mapDispatch = (dispatchEvent?: any) => ({
  $clubMemberList: () => dispatchEvent(clubMemberList()),
  $removeClubMember: (values ?: any) => dispatchEvent(removeClubMember(values)),
});
export default connect(mapState, mapDispatch)(ClubMemberList);
