// for home me in previous profile
export const getUserPreMehome = (objIs: any, obj: any, ItsMe: any) => {
    if (obj.events.length > 0) {
      const a = ItsMe?.find((l: any) => l.home_team_id === obj.home_team_id);
      const b = a?.events?.filter(
        (j: any) => (j.userId === objIs.userId) && j.pickedTeam === 1
      );
      const c = b?.length > 0 && b[0];
      return (
        <td>
          {c?.bet ? c.bet : "-"}
        </td>
      );
    } else {
      return (
        <td>
          -
        </td>
      );
    }
  };

  // for away me in previous profile

  export const getUserPreMeAway = (objIs: any, obj: any, ItsMe: any) => {
    if (obj.events.length > 0) {
      const a = ItsMe?.find((l: any) => l.away_team_id === obj.away_team_id);
      const b = a?.events?.filter(
        (j: any) => (j.userId === objIs.userId) && j.pickedTeam === 2
      );
      const c = b?.length > 0 && b[0];
      return (
        <td>
          {c?.bet ? c.bet : "-"}
        </td>
      );
    } else {
      return (
        <td>
          -
        </td>
      );
    }
  };

  // blank html

export const getUserPreBlankHtml = () =>{
  return <td>-</td>
}  

export const getPreParlayHomeMe = (uniqueMe: any, obj: any, itsMe: any,parlayevents:any, setParlayEvents:any, parlayLock:boolean, result : any) => {     
    const g = parlayevents?.find((el : any) => (el.scheduleId === obj.id)&& el.pickedTeam === 1)
    return (
      <td className="table-column">
       { g ? <button type="button" 
        className={`btn ${result ? (result === "Loss" ? "bg-danger" : result === "Win" ? "bg-success" : "bg-secondary") : "bg-secondary"} btn-sm custom_padding apni-class`}      
        // disabled={true}
        >
          Picked
        </button> : "-"}
      </td>
    );
    
  };
  
  export const getPreParlayAwayMe = (uniqueMe: any, obj: any, itsMe: any,parlayevents:any, setParlayEvents:any, parlayLock: boolean, result : any) => {
    const g = parlayevents?.find((el : any) => (el.scheduleId === obj.id) && el.pickedTeam === 2)
    return (
      <td className="table-column">
        {g ? <button type="button" 
        className={`btn ${result ? (result === "Loss" ? "bg-danger" : result === "Win" ? "bg-success" : "bg-secondary") : "bg-secondary"} btn-sm custom_padding apni-class`}
        // disabled={true}
        >
          Picked
        </button> : '-'}
      </td>
    )
  }