import React, { useEffect, useState } from "react";
import Spinner from "../../../../Common/Spinner/Spinner";

const LastEvent = (props ?:any) => {
    const { eventsData, LoadingIs } = props;
    const eventsDataIs = eventsData && eventsData[0]
    const [ noDataFound, setNoDataFound ] = useState(false)

    useEffect(() => {
        setTimeout(() => setNoDataFound(true), 5000)
    }, [])
    const lastEventMargin = eventsDataIs?.betWin+eventsDataIs?.parleyWin
    return (
        <div className="card mb-10 dark-card-bg">
            <div className="card-header dark-card-bg">
                <div className="card-title flex-column m-0">
                    <h3 className="fw-bolder mb-0 text-orange">Last Event</h3>
                </div>
            </div>
            <div className="card-body d-flex flex-column">
                {LoadingIs 
                ?
                ( noDataFound ? "No Data Found" : <Spinner color="text-black spinner-border-sm " />)
                : 
                <React.Fragment>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span 
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Total Points
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{ isNaN(eventsDataIs?.points)  ? "-" : eventsDataIs?.points}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Bet Win %
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{ isNaN(eventsDataIs?.betWinPercent)  ? "-" : eventsDataIs?.betWinPercent?.toFixed(2) + "%" }</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            Parlay Win (Y/N)
                        </span>
                    </div>
                    <div className="badge badge-light ms-auto">{typeof (eventsDataIs?.isParleyWin) === "boolean" ? (eventsDataIs?.isParleyWin?"Y":"N") : "-"}</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span className={`fs-6 fw-bold text-white text-hover-muted`}  >
                            Margin
                        </span>
                    </div>
                    <div className={`badge ms-auto  ${ !isNaN(lastEventMargin) ? (lastEventMargin > 0 ? "badge-success" : lastEventMargin < 0 ? "badge-orange" : "badge-light") : "badge-light" }`}>{ !isNaN(lastEventMargin) ? (new Intl.NumberFormat(undefined, { style: 'currency',currency: 'USD'}).format(lastEventMargin)) : "-" }</div>
                </div>
                <div className="d-flex align-items-center mb-5">
                    <div className="fw-bold">
                        <span
                            className="fs-6 fw-bold text-white text-hover-muted"
                        >
                            ROI
                        </span>
                    </div>
                    {
                        !isNaN(eventsDataIs?.roi) 
                        ? 
                        <div className={`badge ${eventsDataIs?.roi > 0 ? `badge-success` : eventsDataIs?.roi < 0 ? "badge-orange" : "badge-light"} ms-auto`}>
                        { eventsDataIs?.roi?.toFixed(2) + "%" }
                        </div> 
                        :
                        <div className={`badge badge-light ms-auto`}>
                            {eventsDataIs?.roi  ? eventsDataIs?.roi?.toFixed(2) : "-"}
                        </div>
                    }
                </div>
                </React.Fragment>}
            </div>
        </div>
    )
}
export default LastEvent
