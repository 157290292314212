import EventBetslip from './ProfileEventBetslip/UserEventBetslip/EventBetslip';
import ProfileCard from './ProfileCard/ProfileCard';
import MyStats from './MyStats/MyStats';
import LastEvent from './LastEvents/LastEvent';
import LastFiveEvent from './LastEvents/LastFiveEvents';
import Layout from '../../../Common/Layout';
import { connect } from "react-redux";
import {  publicStatsEventsScore, statsEventsScore , onlyGetUserStat} from "../../../Store/action/Events.action";
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import PublicProfileBets from './MyBets/PublicProfileBets';
import PublicProfileClubs from './MyClubs/PublicProfileClubs';
import UserPublicProfilePreviousEvents from './ProfilePreviousEvents/PreviousBetslip/UserPublicProfilePreviousEvents';
import UserPublicEventBetslip from './ProfileEventBetslip/UserEventBetslip/UserPublicEventBetslip';
import MyBio from './MyBio/MyBio';
import { useHistory } from 'react-router-dom';

const UserPublicProfile = (props ?: any) => {
    const[statsData , setStats] = useState<any>()
    const[eventsData ,setEvents] = useState<any>([])
    const[user, setUser] = useState<any>({})
    const[bets, setBets] = useState<any>([])
    const[clubs, setClubs] = useState<any>([])
    const[last5EventDataNew, setLast5Data] = useState({})
    const[pastSchedules, setPastSchedules] = useState<any>([])
    const[latestSchedule, setLatestSchedule] = useState<any>([]) 
    const [onlyMySate,setOnlyStats] = useState<any>([])
    const[LoadingIs, setLoading] = useState<boolean>(false)
    const params = useParams<any>()
    const {screenName} = params

    let newDate = new Date().getFullYear()

    const [quarter,setQuarter] = useState<any>({quarter:0,newYear:newDate,screenName:""})
    const [myStateLoader,setMyStateLoader] = useState<any>(false)
    const loggedUserScreenName = props?._userProfile?.userDataIs?.data?.screenName
    if(loggedUserScreenName === screenName){
      props?.history?.push("/myprofile")
    }  
      const history=useHistory()

      
      useEffect(() => {
        setLoading(true)
        setMyStateLoader(true)
        setQuarter({...quarter,screenName:screenName})
        
        props.$statsEventsScore(quarter);
        props.$onlyGetUserStat()
        // props.$statsEventsScore(quarter)
        //   setQuarter({...quarter,screenName:screenName})
      }, [screenName])


    useEffect(() => {
      setLoading(true)
      props.$publicStatsEventsScore(screenName)
    }, [history.location.pathname])

    const getResponse = (data : any ,onlydata:any) => {
      // console.log(onlydata,"data data");
         
       setMyStateLoader(false)
       setLoading(false)
       setStats(data?.myStats)
       setEvents(data?.last_five_events_data)
       setUser(data?.user)
       setBets(data?.bets)
       setOnlyStats(onlydata?.myStats)
       setClubs(data?.clubs)
       setPastSchedules(data?.pastScheduleData)
       setLatestSchedule(data?.latestScheduleData)
       setLast5Data(data?.last_five_event_data_new || {})
    }
    
    useEffect(() => {
     
      let data = props._statsEventsScore.publicScoreDataIs?.data;
      const onlydata = props._statsEventsScore.scoreDataIs?.data;
      
      // const onlydata = props._onlyGetUserStat.publicScoreDataIs?.data
       if(data){
        getResponse(data, onlydata)
      }
    }, [props._statsEventsScore ,screenName , props._onlyGetUserStat])
    
    useEffect(()=>{
      // setQuarter({...quarter,screenName:screenName})
      props.$statsEventsScore(quarter);
     
    },[quarter,screenName])

    return (
        <Layout options="fill_Header" uservisible="visible">
            <div className="main-content">
                <div className="container pt-15">
                    <div className="row">
                        <div className="col-sm-12 col-lg-4">
                            <ProfileCard user = {user} statsData = {statsData} isPublicProfile={true}/>
                            <MyBio LoadingIs = {LoadingIs} bio={user?.bio} isPublicProfile={true} />
                            <MyStats LoadingIs = {LoadingIs} isPublicProfile={true} statsData = {onlyMySate} quarter={quarter} setQuarter={setQuarter}  myStateLoader={myStateLoader} setMyStateLoader={setMyStateLoader}/>
                            <LastEvent LoadingIs = {LoadingIs} eventsData = {eventsData}/>
                            <LastFiveEvent LoadingIs = {LoadingIs} eventsData = {eventsData} last5EventDataNew={last5EventDataNew} />
                            <PublicProfileClubs isPublicProfile={true} clubs={clubs}/>
                            <PublicProfileBets isPublicProfile={true} bets={bets} />
                        </div>
                        <div className="col-sm-12 col-lg-8 table_wrapper">
                            <UserPublicEventBetslip latestSchedule={latestSchedule}/>
                            <UserPublicProfilePreviousEvents pastSchedules={pastSchedules}/>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
// export default MyProfile
const mapState = ({ statsEventsScore, userProfile , onlyGetUserStat}: any) => {
    return {
      _statsEventsScore: statsEventsScore,
      // _getOnlyPublicUserMyStat:getOnlyPublicUserMyStat,
      _userProfile: userProfile,
      _onlyGetUserStat: onlyGetUserStat

    };
  };
  const mapDispatch = (dispatchEvent?: any) => ({
    $statsEventsScore: (values?: IQuarter) => dispatchEvent(statsEventsScore(values)),
    $onlyGetUserStat: () => dispatchEvent(onlyGetUserStat()),
    
    $publicStatsEventsScore: (screenName:any) => dispatchEvent(publicStatsEventsScore(screenName)),
  });
  export default connect(
    mapState,
    mapDispatch
  )(UserPublicProfile);
