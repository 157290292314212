export const LOGIN_DATA = "LOGIN_DATA";
export const LOGIN_DATA_SUCCESS = "LOGIN_DATA_SUCCESS";
export const LOGIN_DATA_ERROR = "LOGIN_DATA_ERROR";

export const SIGNUP_DATA = "SIGNUP_DATA";
export const SIGNUP_DATA_SUCCESS = "SIGNUP_DATA_SUCCESS";
export const SIGNUP_DATA_ERROR = "SIGNUP_DATA_ERROR";

export const FORGOT_PWD = "FORGOT_PWD";
export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS";
export const FORGOT_PWD_ERROR = "FORGOT_PWD_ERROR";

export const RESET_PWD = "RESET_PWD";
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS";
export const RESET_PWD_ERROR = "RESET_PWD_ERROR";

export const USER_PROFILE = "USER_PROFILE";
export const USER_PROFILE_SUCCESS = "USER_PROFILE_SUCCESS";
export const USER_PROFILE_ERROR = "USER_PROFILE_ERROR";

export const COUNTRY_DETAILS = "COUNTRY_DETAILS";
export const COUNTRY_DETAILS_SUCCESS = "COUNTRY_DETAILS_SUCCESS";
export const COUNTRY_DETAILS_ERROR = "COUNTRY_DETAILS_ERROR";

export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_ERROR = "UPDATE_USER_ERROR";

export const LOGOUT_USER = "LOGOUT_USER";

export const CREATE_CLUB = "CREATE_CLUB";
export const CREATE_CLUB_SUCCESS = "CREATE_CLUB_SUCCESS";
export const CREATE_CLUB_ERROR = "CREATE_CLUB_ERROR";

export const CHANGE_PWD = "CHANGE_PWD";
export const CHANGE_PWD_SUCCESS = "CHANGE_PWD_SUCCESS";
export const CHANGE_PWD_ERROR = "CHANGE_PWD_ERROR";

export const GET_CLUB_DETAILS = "GET_CLUB_DETAILS";
export const GET_CLUB_DETAILS_SUCCESS = "GET_CLUB_DETAILS_SUCCESS";
export const GET_CLUB_INVITATION_SUCCESS = "GET_CLUB_INVITATION_SUCCESS";
export const GET_CLUB_DETAILS_ERROR = "GET_CLUB_DETAILS_ERROR";

export const INVITE_FRND = "INVITE_FRND";
export const INVITE_FRND_SUCCESS = "INVITE_FRND_SUCCESS";
export const INVITE_FRND_ERROR = "INVITE_FRND_ERROR";

export const JOIN_CLUB = "JOIN_CLUB";
export const JOIN_CLUB_SUCCESS = "JOIN_CLUB_SUCCESS";
export const JOIN_CLUB_ERROR = "JOIN_CLUB_ERROR";

export const PROFILE_PIC = "PROFILE_PIC";
export const PROFILE_PIC_SUCCESS = "PROFILE_PIC_SUCCESS";
export const PROFILE_PIC_ERROR = "PROFILE_PIC_ERROR";

export const JOINING_CLUB_DETAILS = "JOINING_CLUB_DETAILS";
export const JOINING_CLUB_DETAILS_SUCCESS = "JOINING_CLUB_DETAILS_SUCCESS";

export const CLUB_PIC = "CLUB_PIC";
export const CLUB_PIC_SUCCESS = "CLUB_PIC_SUCCESS";
export const CLUB_PIC_ERROR = "CLUB_PIC_ERROR";

export const SHEDULE_BY_YEAR = "SHEDULE_BY_YEAR";
export const SHEDULE_BY_YEAR_SUCCESS = "SHEDULE_BY_YEAR_SUCCESS";
export const TEAM_LIST = "TEAM_LIST";

export const SHEDULE_BY_YEAR_ERROR = "SHEDULE_BY_YEAR_ERROR";

export const TEAM_DATA_BY_DATE = "TEAM_DATA_BY_YEAR";
export const TEAM_DATA_SUCCESS_BY_DATE = "TEAM_DATA_SUCCESS_BY_DATE";

export const JOIN_EVENT = "JOIN_EVENT";
export const JOIN_EVENT_SUCCESS = "JOIN_EVENT_SUCCESS";
export const JOIN_EVENT_ERROR = "JOIN_EVENT_ERROR";

export const CLUB_BY_ID = "CLUB_BY_ID";
export const CLUB_BY_ID_SUCCESS = "CLUB_BY_ID_SUCCESS";

export const PREVIOUS_SHEDULE_BY_YEAR = "PREVIOUS_SHEDULE_BY_YEAR";
export const PREVIOUS_SHEDULE_BY_YEAR_SUCCESS = "PREVIOUS_SHEDULE_BY_YEAR_SUCCESS";

export const PREVOIUS_TEAM_DATA = "PREVOIUS_TEAM_DATA";
export const PREVOIUS_TEAM_DATA_SUCCESS = "PREVOIUS_TEAM_DATA_SUCCESS";

export const CLUB_MEMBER_LIST = "CLUB_MEMBER_LIST"
export const CLUB_MEMBER_LIST_SUCCESS = "CLUB_MEMBER_LIST_SUCCESS";

export const REMOVE_CLUB_MEMBER = "REMOVE_CLUB_MEMBER"
export const REMOVE_CLUB_MEMBER_SUCCESS = "REMOVE_CLUB_MEMBER_SUCCESS"

export const LEAVE_CLUB = "LEAVE_CLUB";
export const LEAVE_CLUB_SUCCESS = "LEAVE_CLUB_SUCCESS";

export const EVENT_PARLAY_TEAM_BY_DATE = "EVENT_PARLAY_TEAM_BY_DATE";
export const EVENT_PARLAY_TEAM_BY_DATE_SUCCESS = "EVENT_PARLAY_TEAM_BY_DATE_SUCCESS";

export const JOIN_PARLAY_EVENT = "JOIN_PARLAY_EVENT";
export const JOIN_PARLAY_EVENT_SUCCESS = "JOIN_PARLAY_EVENT_SUCCESS";

export const SUMMARY_DATA = "SUMMARY_DATA";
export const SUMMARY_DATA_SUCCESSS = "SUMMARY_DATA_SUCCESSS";

export const PROFILE_BETS = "PROFILE_BETS";
export const PROFILE_BETS_SUCCESS = "PROFILE_BETS_SUCCESS";

export const USER_BET_BY_DATE = "USER_EVENT_BY_DATE";
export const USER_BET_BY_DATE_SUCCESS = "USER_EVENT_BY_DATE_SUCCESS";

export const USER_PARLAY_BY_DATE = "USER_PARLAY_BY_DATE";
export const USER_PARLAY_BY_DATE_SUCCESS = "USER_PARLAY_BY_DATE_SUCCESS";

export const USER_SUMMARY_BY_DATE = "USER_SUMMARY_BY_DATE";
export const USER_SUMMARY_BY_DATE_SUCCESS = "USER_SUMMARY_BY_DATE_SUCCESS";

export const USER_PREVIOUS_BET_BY_DATE = "USER_PREVIOUS_BET_BY_DATE";
export const USER_PREVIOUS_BET_BY_DATE_SUCCESS = "USER_PREVIOUS_BET_BY_DATE_SUCCESS";

export const USER_CLICKED_EVENT_DETAIL = "USER_CLICKED_EVENT_DETAIL";

export const USER_PREVIOUS_PARLAY_BY_DATE = "USER_PREVIOUS_PARLAY_BY_DATE";
export const USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS = "USER_PREVIOUS_PARLAY_BY_DATE_SUCCESS";

export const CLUB_PREVIOUS_PARLAY_BY_DATE  = "CLUB_PREVIOUS_PARLAY_BY_DATE";
export const CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS = "CLUB_PREVIOUS_PARLAY_BY_DATE_SUCCESS";

export const GET_INVITE_CODE = "GET_INVITE_CODE";
export const GET_INVITE_CODE_SUCCESS = "GET_INVITE_CODE_SUCCESS";

export const CLUB_PRE_SUMMARY = "CLUB_PRE_SUMMARY";
export const CLUB_PRE_SUMMARY_SUCCESS= "CLUB_PRE_SUMMARY_SUCCESS";

export const PROFILE_PRE_SUMMARY = "PROFILE_PRE_SUMMARY";
export const PROFILE_PRE_SUMMARY_SUCCESS = "PROFILE_PRE_SUMMARY_SUCCESS";

export const STATS_EVENTS_SCORE = "STATS_EVENTS_SCORE";
export const STATS_EVENTS_SCORE_SUCCESS = "STATS_EVENTS_SCORE_SUCCESS";
export const ONLY_GET_USER_STAT_SUCCESS = "ONLY_GET_USER_STAT_SUCCESS";
export const ONLY_GET_USER_STAT = "ONLY_GET_USER_STAT";

export const PUBLIC_STATS_EVENTS_SCORE = "PUBLIC_STATS_EVENTS_SCORE";
export const PUBLIC_STATS_EVENTS_SCORE_SUCCESS = "PUBLIC_STATS_EVENTS_SCORE_SUCCESS";

export const CLUB_STANDINGS = "CLUB_STANDINGS";
export const CLUB_STANDINGS_SUCCESS = "CLUB_STANDINGS_SUCCESS";

export const ALLTIME_GAME_PLANNER = "ALLTIME_GAME_PLANNER";
export const ALLTIME_GAME_PLANNER_SUCCESS = "ALLTIME_GAME_PLANNER_SUCCESS";

export const ALLTIME_POUND_FOR_POUND = "ALLTIME_POUND_FOR_POUND";
export const ALLTIME_POUND_FOR_POUND_SUCCESS = "ALLTIME_POUND_FOR_POUND_SUCCESS";

export const ALLTIME_MAKING_CHAMPIONSHIP_RUN = "ALLTIME_MAKING_CHAMPIONSHIP_RUN";
export const ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS = "ALLTIME_MAKING_CHAMPIONSHIP_RUN_SUCCESS";

export const EVENT_GAME_PLANNER = "EVENT_GAME_PLANNER";
export const EVENT_GAME_PLANNER_SUCCESS = "EVENT_GAME_PLANNER_SUCCESS";

export const EVENT_POUND_FOR_POUND = "EVENT_POUND_FOR_POUND";
export const EVENT_POUND_FOR_POUND_SUCCESS = "EVENT_POUND_FOR_POUND_SUCCESS";

export const EVENT_CORRECT_BETS = "EVENT_CORRECT_BETS";
export const EVENT_CORRECT_BETS_SUCCESS = "EVENT_CORRECT_BETS_SUCCESS";

export const CHAT_GET_ALL_MESSAGE = "CHAT_GET_ALL_MESSAGE"
export const CHAT_GET_ALL_MESSAGE_SUCCESS = "CHAT_GET_ALL_MESSAGE_SUCCESS"

export const CHAT_NEW_MESSAGE = "CHAT_NEW_MESSAGE"
export const CHAT_NEW_MESSAGE_SUCCESS = "CHAT_NEW_MESSAGE_SUCCESS"
